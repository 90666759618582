import { CircularProgress } from "@material-ui/core";
import { Padding } from "@mui/icons-material";
import { table } from "console";
import DataTable from "react-data-table-component";
// import "react-data-table-component-extensions/dist/index.css";
import { useSelector } from "react-redux";

const customStyles = {
  
  headCells: {
    style: {
      fontSize: "13px",
      justifyContent: "left", // Center-align the headers
      whiteSpace: "wrap", // Prevent text from wrapping
      overflow: "hidden",
      color: "white", // Font color for header
      borderBottom: "1px solid #ffffff33",
    },
  },
  cells: {
    style: {
      justifyContent: "left", // Center-align the headers
      whiteSpace: "nowrap", // Prevent text from wrapping in cells
      // overflow: "hidden",
      // overflowX:'hidden',
      textOverflow: "ellipsis",
      color: "white", // Font color for header
      fontSize:'16px',
      border: "none",
      // marginTop:'50px 0px',
      Padding:'30px 0px'
    },
  },
  rows: {
    style: {
      border: "none", // Remove borders from rows
      backgroundColor:'transparent'
    },
  },
};

export function CommonDataTable({
  data,
  totalRows,
  setPage,
  setRowsPerPage,
  columns,
  ...props
}) {
  const { isLoading } = useSelector((state: any) => state.loading);
  return (
    <div style={{ position: "relative", backgroundColor:'transparent !important'}}>
        <div
        style={{
          maxHeight: "600px !important", // Adjust this as needed
          // overflowY: "hidden !important",
          
        }}
        className="custom-scrollbar"
      >
      <DataTable
        columns={columns}
        data={data}
      
        style={{backgroundColor:'transparent', maxHeight:'600px', overflow:'hidden !important'}}
        // pagination
        // paginationServer
        fixedHeaderScrollHeight="600px"
        // conditionalRowStyles={conditionalRowStyles}
        onChangePage={(value) => {
          setPage(value);
        }}
        responsive={true}
        fixedHeader
        
        onChangeRowsPerPage={setRowsPerPage}
        paginationTotalRows={totalRows}
        progressPending={isLoading}
        customStyles={customStyles}
        progressComponent={
          <div               
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            height: "100%",
            background:'red',
            backgroundColor:'black',
            width:'fit-content',
            overflowX:'hidden',
        
          }}
        
>
            <CircularProgress size="40px" color="primary" />
          </div>
        }
        noDataComponent={
          <div
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                height: "100%",
                padding:'24px',
                color: "white",
                fontSize: "16px",
                backgroundColor: "transparent",
              }}
            >
            There are no records to display
            </div>
        }
        {...props}
      />
    </div>
    </div>
  );
}
