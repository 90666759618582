import React, { useEffect, useState } from "react";
import {
  Container,
  Switch,
  Divider,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Box,
  Button,
  Step,

  StepLabel,
  Stepper,
  Typography,
} from "@mui/material";
import { styled } from "@mui/material/styles";
import Header3 from "../../Layout/Header3";
import { useLocation, useNavigate } from "react-router-dom";
import StepConnector, { stepConnectorClasses } from "@mui/material/StepConnector";

import Axios from "../../../../config/axios";
import { UrlConstant } from "../../../../commom/UrlConstant";
import RangeInput from "../../InputFields/RangeInput";
import Swal from "sweetalert2";
import PreferencesForm from "./PreferencesForm";
import Successpopup from "../../SharedComponent/Successpopup";

const steps = [1, 2];
const CustomConnector = styled(StepConnector)(({ theme }) => ({
  [`&.${stepConnectorClasses.alternativeLabel}`]: {
    top: 22,
  },
  [`&.${stepConnectorClasses.active}`]: {
    [`& .${stepConnectorClasses.line}`]: {
      backgroundImage:
        'linear-gradient( 95deg,#4EDF6D 0%,#4EDF6D 50%,#4EDF6D 100%)',
    },
  },
  [`&.${stepConnectorClasses.completed}`]: {
    [`& .${stepConnectorClasses.line}`]: {
      backgroundImage:
        'linear-gradient( 95deg,#4EDF6D 0%,#4EDF6D 50%,#4EDF6D 100%)',
    },
  },
  [`& .${stepConnectorClasses.line}`]: {
    height: 8,
    border: 0,
    // borderColor: "#FFFFFF1A",
    //   borderTopWidth: 8,
    backgroundColor: '#FFFFFF1A',
    borderRadius: 1,
    ...theme.applyStyles('dark', {
      backgroundColor: theme.palette.grey[800],
    }),
  },

}));

const Stepper3 = () => {
  const location = useLocation();
  const navigation = useNavigate();
  const id = location?.state?.id

  const [activeStep, setActiveStep] = useState(1);
  const [preferenceDetails, setPreferenceDetails] = useState<any>({});
  const [preferenceType, setPreferenceType] = useState<any>("");
  const [preferenceLevel, setPreferenceLevel] = useState<any>("");
  const [fieldsData, setFieldsData] = useState<any>({});
  const [fieldsList, setFieldsList] = useState<any>([]);
  const [showPopup, setShowPopup] = useState(false); // State for controlling popup visibility
  const [msg, setmsg] = useState(''); // State for controlling popup visibility
  const [Success, setSuccess] = useState(false); // State for controlling popup visibility
  const [heading, setheading] = useState(''); // State for controlling popup visibility




  const handleChange = (e, field) => {
    const { name, value, type, checked } = e.target;
    console.log(field, type, value, "fieldfield")

    // For range inputs
    if (type === "range") {
      setPreferenceDetails((prevDetails) => ({
        ...prevDetails,
        [field]: {
          ...prevDetails[field],
          [name]: value,
        },
      }));
    }

    // For switch inputs (e.g., isActive)
    if (type === "checkbox" || type === "switch") {
      setPreferenceDetails((prevDetails) => ({
        ...prevDetails,
        [field]: {
          ...prevDetails[field],
          [name]: checked,
        },
      }));
    } else {
      setPreferenceDetails((prevDetails) => ({
        ...prevDetails,
        [field]: {
          ...prevDetails[field],
          [name]: value,
        },
      }));
    }

    // For other types of inputs
    if (type === "text" || type === "select") {
      setPreferenceDetails((prevDetails) => ({
        ...prevDetails,
        [field]: {
          ...prevDetails[field],
          [name]: value,
        },
      }));
    }

    console.log(preferenceDetails, "fieldsDatafieldsData")

  };


  const getPreferenceDetails = async (id) => {
    try {
      const response = await Axios.get(
        UrlConstant.Discovery.getPreferenceDetails.replace("{0}", id)
      );
      const resData = response.data;
      setPreferenceDetails(resData.data);
      setPreferenceType(resData.data?.type);
      setPreferenceLevel(resData.data?.level);
      console.log(resData, "======================")
    } catch (error: any) {
      console.error("ERRR : ", error);
    } finally {
    }
  };

  const getPreferenceFields = async (type) => {
    try {
      const response = await Axios.get(
        UrlConstant.Discovery.getPreferenceFields.replace("{0}", type)
      );
      const resData = response.data;
      setFieldsData(resData.data);
      console.log(resData, "======================")
    } catch (error: any) {
      console.error("ERRR : ", error);
    } finally {
    }
  };

  function filterDataByPreference(data, preferenceType) {
    if (!Array.isArray(data)) {
      throw new Error("Data must be an array");
    }
    let filteredDta

    if (preferenceLevel == "PRO") {
      filteredDta = data
    } else {
      filteredDta = data.filter(item => !item.isPro)
    }

    switch (preferenceType) {
      case "STOCK":
        return filteredDta.filter(item => item.is_stock === true);
      case "ETF":
        return filteredDta.filter(item => item.is_etf === true);
      default:
        throw new Error("Invalid preference type. Allowed values are 'STOCK' or 'ETF'.");
    }
  }

  useEffect(() => {
    if (id) {
      getPreferenceDetails(id)
    }
  }, [id])

  useEffect(() => {
    if (preferenceType) {
      getPreferenceFields(preferenceType)
    }
  }, [preferenceType])

  useEffect(() => {
    if (fieldsData.length) {
      const fields = filterDataByPreference(fieldsData, preferenceType)
      setFieldsList(fields)

    }
  }, [fieldsData])

  function validateAndPrepareData(data) {
    const result = { ...data }; // Create a copy of the data to avoid mutating the original object.

    Object.keys(result).forEach((key) => {
      const field = result[key];

      if (typeof field === "object" && field !== null && field.isActive === true) {
        // Set default min value if not provided
        if (field.min === undefined || field.min === null) {
          field.min = 0;
        }

        // Validate fields
        if (field.max === undefined || field.priority === "" || field.priority === undefined) {
          // Swal.fire(
          //   `Validation failed for ${key}: 'priority' and 'max' must not be empty.`
          // );
          setheading('Error')
          setShowPopup(true)
          setmsg(`Validation failed for ${key}: 'priority' and 'max' must not be empty.`)

        }
      }
    });

    return result;
  }




  const handleSubmit = async (values) => {

    const data = { ...values, preferenceId: id }
    try {
      const response = await Axios.post(
        UrlConstant.Discovery.updatePreferenceData, data)
      if (response.data.result) {
        navigation("/discovery")
      }
      console.log(response, "response")
    } catch (error: any) {
      console.error("ERRR : ", error);
    } finally {

    }
  };
  const closePopup = () => {
    setShowPopup(false); // Function to close the popup
  };

  return (
    <Container maxWidth={false} sx={{ height: 'calc(100vh - 125px)', position: 'relative' }} className="bg-color">


      <Box sx={{ display: "flex", height: '90%', flexDirection: "column", gap: 4, marginTop: 3 }}>
        <Stepper
          activeStep={activeStep}
          connector={<CustomConnector />}
          sx={{ width: "12%" }} // Keeping the stepper width as in your original code
        >
          {steps.map((label, index) => (
            <Step key={label} sx={{ paddingRight: "0px", paddingLeft: "0px" }}>
              <StepLabel
                sx={{
                  paddingRight: "0px",
                  paddingLeft: "0px",
                  "& .MuiStepLabel-iconContainer": {
                    paddingRight: "0px",
                    paddingLeft: "0px",
                  },
                  ".MuiStepIcon-text": { fontSize: "16px" },
                  // Step Icon customization
                  "& .MuiStepIcon-root": {
                    height: "42px",
                    width: "42px",
                    padding: "0px",
                  },
                }}
              >
                {/* {activeStep === index ? (index + 1) : ''} */}
              </StepLabel>
            </Step>
          ))}
        </Stepper>

        {activeStep === 1 && (
          <Box mt={0} sx={{ width: "100%", height: '80%', overflowY: 'auto', boxSizing: "border-box", gap: 2 }}>

            {
              fieldsList.length && <PreferencesForm fieldsList={fieldsList} fieldsData={fieldsData} preferenceDetails={preferenceDetails} handleSubmit={handleSubmit}  />
            }
          </Box>
        )}
      </Box>
      {showPopup && <Successpopup Heading={heading} Success={Success} onClose={closePopup} Message={msg} />}

    </Container>
  );
};

export default Stepper3;
