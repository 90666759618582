// import { Avatar, Container,ToggleButton,Typography,Box,FormControl } from "@mui/material";
import { BookmarkBorderOutlined } from "@mui/icons-material";
import BookmarkOutlinedIcon from '@mui/icons-material/BookmarkOutlined';
import { ExpandCircleDownOutlined } from "@mui/icons-material";
import {
  Avatar,
  Box,
  Container,
  ToggleButton,
  ToggleButtonGroup,
  Typography,
  CardContent,
  IconButton,
  CircularProgress
} from "@mui/material";
import React, { useState, useRef, useEffect } from "react";
import { Card, Row } from "react-bootstrap";
import { useLocation, useNavigate, Link } from "react-router-dom";
import LineChart from "../charts/Linechart";
import { useDispatch } from "react-redux";
import { endLoading, startLoading } from "../../../Redux/loadingSlice";
import Axios from "../../../config/axios";
import { UrlConstant } from "../../../commom/UrlConstant";
import Swal from "sweetalert2";
import { formatPrice, getCurrentUserId, getUserFromLocalStorage } from "../../../Utils/utils";
import BuySell from "../Cards/BuySell";
import axios from "axios";
import { Grid } from "@material-ui/core";
import moment from "moment";
import Successpopup from "../SharedComponent/Successpopup";
import { truncateByDomain } from "recharts/types/util/ChartUtils";
import RightSideSection from "../SharedComponent/RightSideSection";

const BAR_CHART_KEY = "0J7lfEpAefOuHSE6uSHdiW_5N0sThxfa";
const YAHOO_FINANCE_KEY = "W6WQ7T5PIt63txWaMW9yVsDCVVYbUD81i0eERo32";
const BASE_URL = "https://yfapi.net/ws/insights/v1/finance/insights";


function StockDetail() {
  const location = useLocation();
  const dispatch = useDispatch();
  const navigation = useNavigate();
  const [stockDetail, setStockDetail] = React.useState<any>({});
  const [isWatchlist, setIsWatchlist] = React.useState<any>(false);
  const [loading, setLoading] = useState(false);
  const [chartData, setChartData] = useState<any>([]);
  const [tickerData, setTickerData] = useState<any>([]);
  const [tickerDataAll, setTickerAllData] = useState<any>([]);
  const [selection, setSelection] = useState<string>("1 Month");
  const [expandedCards, setExpandedCards] = useState<{ [key: number]: boolean }>({});
  const scrollRef = useRef<HTMLDivElement>(null);
  const [insights, setInsights] = useState<any>([]);
  const [showPopup, setShowPopup] = useState(false); // State for controlling popup visibility
  const [msg, setmsg] = useState(''); // State for controlling popup visibility
  const [Success, setSuccess] = useState(false); // State for controlling popup visibility
  const [heading, setheading] = useState(''); // State for controlling popup visibility
  const [isTradingAcExist, setIsTradingAcExist] = useState(false);
  const [applicationStatus, setApplicationStatus] = useState("Create Now");
  const [isDocumentRequired, setDocumentRequired] = useState<boolean>(false);
  const [applicationId, setApplicationId] = useState("");
  const [trendValue, setTrendValue] = useState(0);
  const [trendPercentage, setTrendPercentage] = useState(0);
  const [stockDetailHistory, setStockDetailHistory] = useState<any>([]);

  const user = getUserFromLocalStorage();



  useEffect(() => {
    if (user && user.tradingblock_application_id) {
      setIsTradingAcExist(true);
    }
  }, [user]);

  const checkAccountStatus = async () => {
    try {
      const response = await Axios.get(
        UrlConstant.TradingAccount.CheckAccountStatus
      );
      const resData = response.data;
      console.log("AC Status : ", resData);
      if (resData.status === true) {
        setApplicationStatus(resData.data.status);
        setDocumentRequired(resData.data.isDocumentUploadRequired);
        setApplicationId(resData.data.applicationId);

      }
    } catch (error: any) {
      console.error("ERRR : ", error);
    }
    finally {
    }
  };

  const handleReadMore = (index: number) => {
    setExpandedCards((prevState) => ({
      ...prevState,
      [index]: !prevState[index], // Toggle the specific card's expanded state
    }));
  };
  const handleScrollLeft = () => {
    if (scrollRef.current) {
      scrollRef.current.scrollLeft += 200;
    }
  };
  const handleScrollRight = () => {
    if (scrollRef.current) {
      scrollRef.current.scrollLeft -= 200;
    }
  };

  const handleselect = (
    event: React.MouseEvent<HTMLElement>,
    newselection: string
  ) => {
    setChartData([]);
    setSelection(newselection);
  };

  const stock = location.state?.symbol;
  const icon = location.state?.icon;
  const title = location.state?.title

  function calculatePriceDetails(tickerData) {
    const lastPrice = parseFloat(tickerData?.Last || "0.00");
    const previousClose = parseFloat(tickerData?.PreviousClose || "0.00");
    const diffAmount = lastPrice - previousClose;
    const percentage = (diffAmount * 100.0) / previousClose;

    return {
      lastPrice,
      previousClose,
      diffAmount,
      percentage
    };
  }

  const getStockDetail = async () => {
    dispatch(startLoading());
    setLoading(true)
    try {
      const response = await Axios.get(
        UrlConstant.Stock.GetStockDetail.replace("{0}", stock)
      );
      const resDataTicker = await Axios.get(
        UrlConstant.Stock.GetTiker.replace("{0}", stock))

      if (resDataTicker.data) {
        setTickerData(calculatePriceDetails(resDataTicker.data?.data[0]))
        setTickerAllData(resDataTicker.data?.data[0])
      }
      const resData = response.data;
      if (resData.result === true) {
        setStockDetail(resData.data);
        setIsWatchlist(resData.data.isAddedToWatchList)
      }
    } catch (error: any) {
      console.error("ERRR : ", error);
    } finally {
      dispatch(endLoading());
      setLoading(false)
    }
  };
  const getStockDetailHistory = async () => {
    dispatch(startLoading());
    setLoading(true)
    try {
      const response = await Axios.get(
        UrlConstant.Stock.GetTickerHistory.replace("{0}", stock)
      );
   
      const resData = response.data;
      if (resData.status === true) {
        setStockDetailHistory(resData.data);
      }
    } catch (error: any) {
      console.error("ERRR : ", error);
    } finally {
      dispatch(endLoading());
      setLoading(false)
    }
  };


  const getUrlRange = (): string => {
    const now = new Date(new Date().setDate(new Date().getDate()));
    let endPoint = "";

    const formatDate = (date: Date) =>
      date.toISOString().split("T")[0]; // Format as yyyy-MM-dd

    const adjustDate = (date: Date, adjust: { days?: number; months?: number; years?: number }) => {
      if (adjust.days) date.setDate(date.getDate() + adjust.days);
      if (adjust.months) date.setMonth(date.getMonth() + adjust.months);
      if (adjust.years) date.setFullYear(date.getFullYear() + adjust.years);
      return date;
    };
    console.log(selection, adjustDate(new Date(now), { days: -1 }), "selectionselection")
    switch (selection) {
      case "1 Day":
        endPoint = `/range/15/minute/${formatDate(adjustDate(new Date(now), { days: -1 }))}/${formatDate(now)}`;
        break;
      case "5 Day":
        endPoint = `/range/1/day/${formatDate(adjustDate(new Date(now), { days: -8 }))}/${formatDate(now)}`;
        break;
      case "1 Month":
        endPoint = `/range/1/day/${formatDate(adjustDate(new Date(now), { months: -1 }))}/${formatDate(now)}`;
        break;
      case "6 Month":
        endPoint = `/range/1/week/${formatDate(adjustDate(new Date(now), { months: -6 }))}/${formatDate(now)}`;
        break;
      case "1 Year":
        endPoint = `/range/1/month/${formatDate(adjustDate(new Date(now), { years: -1 }))}/${formatDate(now)}`;
        break;
      case "5 Years":
        endPoint = `/range/1/month/${formatDate(adjustDate(new Date(now), { years: -5 }))}/${formatDate(now)}`;
        break;
      case "All":
        endPoint = `/range/1/month/1980-01-01/${formatDate(now)}`;
        break;
      default:
        endPoint = `/range/1/day/${formatDate(adjustDate(new Date(now), { days: -1 }))}/${formatDate(now)}`;
    }
    return endPoint;
  };

  const getChartData = async () => {
    dispatch(startLoading());
    console.log(getUrlRange(), "getUrlRange()")
    const fetchChartData = async (retryCount = 2) => {
      let currentDate = new Date();
      let retries = 0;

      while (retries <= retryCount) {
        // Adjust date range for the "1 Day" case
        const adjustedDate = new Date(currentDate);
        adjustedDate.setDate(currentDate.getDate() - retries);

        const startDate = adjustedDate.toISOString().split("T")[0];
        const endDate = new Date().toISOString().split("T")[0];

        const url = `https://api.polygon.io/v2/aggs/ticker/${stock}/range/15/minute/${startDate}/${endDate}?apiKey=${BAR_CHART_KEY}&sort=asc&limit=5000`;

        try {
          const response = await axios.get(url);
          console.log(response.data, "response.data");

          if (response.data.queryCount > 0) {
            const apiResponse = response.data.results;
            const chartData = apiResponse.map(item => ({
              x: new Date(item.t), // Convert Unix timestamp to Date
              y: item.c // Extract closing price "c"
            }));

            console.log(chartData, "chartData");
            setChartData(chartData);
            // 1 Month Trend: Last point - first point
            // Percentage calculation: ((Last point - first point) / first point) X 100
            const firstPoint = chartData[0].y;
            const lastPoint = chartData[chartData.length - 1].y;
            const percentage = ((lastPoint - firstPoint) / firstPoint) * 100;
            setTrendValue(lastPoint - firstPoint);
            setTrendPercentage(percentage);

            return; // Exit the loop and function when data is found
          } else {
            console.log(`No data for date range: ${startDate} - ${endDate}, retrying...`);
          }
        } catch (error) {
          console.error(`Error fetching chart data for range ${startDate} - ${endDate}:`, error);
        }

        retries++;
      }

      console.log("No data found after all retries");
      setChartData([]); // Set empty data if all retries fail
    };

    if (selection === "1 Day") {
      await fetchChartData();
    } else {

      try {
        const url = `https://api.polygon.io/v2/aggs/ticker/${stock}${getUrlRange()}?apiKey=${BAR_CHART_KEY}&sort=asc&limit=5000`;
        const response = await axios.get(url);
        console.log(response.data, "response.dataresponse.data")
        const apiResponse = response.data.results
        const chartData = apiResponse.length && apiResponse.map(item => ({
          x: new Date(item.t),  // Convert Unix timestamp to Date
          y: item.c            // Extract closing price "c"
        }));

        console.log(chartData, "chartDatachartDatachartData")
        setChartData(chartData || []);
        const firstPoint = chartData[0].y;
        const lastPoint = chartData[chartData.length - 1].y;
        const percentage = ((lastPoint - firstPoint) / firstPoint) * 100;
        setTrendValue((lastPoint - firstPoint));
        setTrendPercentage(percentage);
      } catch (error) {
        console.error("Error fetching chart data:", error);
      } finally {
        dispatch(endLoading());
      }
    }
  };


  const fetchFinanceInsights = async () => {
    try {
      const response = await Axios.get(
        UrlConstant.Insights.getInsightData.replace("{0}", stock)
      );

      const resData = response.data;
      if (resData.status === true) {
        setInsights(resData.data?.finance?.result?.reports)
      }
    } catch (error: any) {
      console.error("ERRR : ", error);
    } finally {
      dispatch(endLoading());
      setLoading(false)
    }
  };

  useEffect(() => {
    getStockDetail();
    fetchFinanceInsights();
    checkAccountStatus();
    getStockDetailHistory();
  }, [stock]);

  useEffect(() => {
    getChartData();
  }, [selection]);

  const CreateWatchList = async (isAddedToWatchList) => {
    dispatch(startLoading());

    const payLoad = {
      userId: getCurrentUserId(),
      symbol: stock,
    };

    const URL = isAddedToWatchList
      ? UrlConstant.WachList.RemoveWatchList
      : UrlConstant.WachList.CreateWatchList;

    try {
      let response;

      if (isAddedToWatchList) {
        response = await Axios.delete(URL.replace("{0}", stock));
        if (response.data.result) {
          setIsWatchlist(false)
        }
      } else {
        response = await Axios.post(URL, payLoad);
        if (response.data.result) {
          setIsWatchlist(true)
        }
      }

      const resData = response.data;
      // Swal.fire(resData.msg);
      setSuccess(true)
      setShowPopup(true)
      setmsg(resData.msg)
    } catch (error) {

      console.error("ERRR : ", error);
    } finally {
      dispatch(endLoading());
    }
  };

  const closePopup = () => {
    setShowPopup(false); // Function to close the popup
  };
  return (
    <Box className="bg-color" display="flex">
      <Container
        maxWidth={false}
        className="bg-color p-3 px-5"
        style={{
          // display: "flex",
          height: "fit-content",
          width: "65%",
        }}
      >
        <Typography
          className="text-secondary"
          sx={{ marginTop: "30px", marginBottom: "30px" }}
        >
          {" "}
          <span
            onClick={() => navigation(-1)}
            style={{ color: "#4A7AF2", cursor: "pointer" }}
          >
            {title}
          </span>
          <span> / {stock}</span>
        </Typography>

        <Container
          style={{ width: "100%", left: "0", margin: "0" }}
          className="p-0"
        >
          <Box>
            <Box
              display="flex"
              alignItems="start"
              justifyContent="space-between"
            >
              <Box className="text-white">
                <Box display="flex" alignItems="center" gap="20px" mb={2}>
                  {icon ? (
                    <Avatar
                      alt={stock}
                      src={icon}
                      sx={{
                        width: 64,
                        height: 64,
                        backgroundColor: "white",
                        objectFit: "fill",
                      }}
                      className="icon"
                    />
                  ) : (
                    <Box
                      sx={{
                        width: 64,
                        height: 64,
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                        backgroundColor: "#f0f0f0",
                        borderRadius: "50%",
                      }}
                    >
                      <Typography
                        variant="h6"
                        sx={{ fontWeight: "bold", color: "#212529" }}
                      >
                        {stock}
                      </Typography>
                    </Box>
                  )}
                  <Box>
                    <Typography mb={1} variant="h5">
                      {stockDetail?.displayName}
                    </Typography>
                    <Typography variant="body1" className="text-secondary">
                      {stockDetail?.stockExchangeCode}:{stockDetail?.symbol}
                    </Typography>
                  </Box>
                </Box>
                <Box display="flex" gap="10px">
                  <Typography variant="h5" textAlign="end">
                    ${formatPrice(tickerData?.lastPrice)}
                  </Typography>
                  <Typography variant="h5" style={{ color: tickerData.diffAmount == 0 ? "" : tickerData.diffAmount > 0 ? "#4edf6d" : "#ff2a2a" }}>

                    {tickerData.diffAmount == 0 ? "$" : tickerData.diffAmount > 0 ? "+$" : "-$"}
                    {formatPrice(Math.abs(tickerData.diffAmount))}
                    ({formatPrice(tickerData.percentage)}%)
                  </Typography>
                </Box>
              </Box>

              <ToggleButton
                className="text-secondary"
                value="watchlist"
                sx={{
                  padding: "10px",
                  borderRadius: "10px",
                  borderColor: "#FFFFFF33",
                }}
                onClick={() => CreateWatchList(isWatchlist)}
              >
                {
                  isWatchlist ? <BookmarkOutlinedIcon /> : <BookmarkBorderOutlined />
                }

                WatchList
              </ToggleButton>
            </Box>
          </Box>
          <Row className="dark-tbl no-border p-0 mt-5">
            <Card
              className="custom-card overflow-hidden bg-color w-100"
              style={{ height: "fit-content" }}
            >
              <Card.Body className="ps-12 p-2 h-100">
                <Box width="100%" display="flex" justifyContent="end">
                  <Box bgcolor={trendValue > 0 ? "#4EDF6D1A" : "#FF2A2A1A"} borderRadius={50} p={"0 10px"} >
                    <Typography variant="body1" className="text-white" p={1} >
                      {/* 1 Mont Trend : <span style={{ color: "#4edf6d" }}>Up</span> */}
                      {/* trend value grater then 0 then color and bg as per that and if 0 then white and if nagetive then as per that */}
                      {selection}{' '}{trendValue > 0 ?
                        <span style={{ color: "#4edf6d" }}>{` +${formatPrice(trendValue)} (${formatPrice(trendPercentage)}%)`}</span>
                        :
                        trendValue === 0 ?
                          <span style={{ color: "#4edf6d" }}>{trendValue}</span>
                          :
                          <span style={{ color: "#ff2a2a" }}>{` ${formatPrice(trendValue)} (${formatPrice(trendPercentage)}%)`}</span>}
                    </Typography>
                  </Box>
                </Box>
                <LineChart
                  data={chartData}
                  timeline={selection}
                  lineColor={
                    trendValue > 0 ? "#4edf6d" : trendValue === 0 ? "#FFFFFF" : "#ff2a2a"
                  }
                />
                <hr style={{ borderColor: "#FFFFFF", borderWidth: "1px" }}></hr>
                <Box mb={1}>
                  <ToggleButtonGroup
                    // className="border-white"
                    // color="primary"
                    // fullWidth
                    value={selection}
                    exclusive
                    onChange={handleselect}
                    aria-label="Platform"
                  >
                    <ToggleButton value="1 Day">1 Day</ToggleButton>
                    <ToggleButton value="5 Day">5 Day</ToggleButton>
                    <ToggleButton value="1 Month">1 Month</ToggleButton>
                    <ToggleButton value="6 Month">6 Month</ToggleButton>
                    <ToggleButton value="1 Year">1 Year</ToggleButton>
                    <ToggleButton value="5 Years">5 Years</ToggleButton>
                    <ToggleButton value="All">Max</ToggleButton>
                  </ToggleButtonGroup>
                </Box>
              </Card.Body>
            </Card>
          </Row>
        </Container>

        {/* <Container style={{ width: "70%", left: "0", margin: "0" }}>
            <TotalFunds />
          </Container> */}
        {/* --------------------------------------------------- Market State --------------------------------- */}
        <Box my={5} pt={2}>
          <Box
            display="flex"
            alignItems="center"
            justifyContent="space-between"
          >
            <Typography
              className="text-white"
              mb={2}
              style={{ fontSize: "1.5rem" }}
            >
              {" "}
              Market Stats{" "}
            </Typography>
            {/* <Link
              to="/"
              style={{
                color: "#4A7AF2",
                textDecoration: "underline !importan",
                textDecorationLine: "underline !important",
              }}
            >
              view all
            </Link> */}
          </Box>

          <Box>
            <Box display='flex' alignItems='center' gap='10px' width='100%' flexWrap='wrap'>
              {[
                { label: "Previous Close", value: formatPrice(tickerDataAll?.PreviousClose) },
                { label: "Ask", value: formatPrice(tickerDataAll?.Ask) },
                { label: "Bid", value: formatPrice(tickerDataAll?.Bid) },
                { label: "52 Week High", value: formatPrice(stockDetailHistory?.h) },
                { label: "52 Week Low", value: formatPrice(stockDetailHistory?.l) },
                { label: "Average Volume", value: stockDetail?.averageVolumeFmt },
                { label: "Market Cap", value: stockDetail?.marketCapFmt },
                { label: "PE Ratio", value: formatPrice(stockDetail?.peRatio) },
                { label: "Dividend Yield", value: stockDetail?.dividendYieldFmt && parseFloat(stockDetail.dividendYieldFmt) === 0 ? "N/A" : formatPrice(stockDetail?.dividendYieldFmt) || "N/A" }
              ].map((item, index) => (
                <Box key={index}>
                  <Card className="card"
                    style={{
                      display: 'flex',
                      flexDirection: 'column',
                      justifyContent: 'space-between',
                      minWidth: "190px",
                      height: '100%',
                    }}
                  >
                    <CardContent className="text-white">
                      <Typography gutterBottom>{item.label}</Typography>
                      <Typography mb="0px" mr={10}>
                        {!['Average Volume', 'PE Ratio', 'Dividend Yield'].includes(item.label) && '$'}{item.value ?? "0"}
                      </Typography>
                    </CardContent>
                  </Card>
                </Box>
              ))}
            </Box>
          </Box>
        </Box>
        {/* --------------------------------------------------- Insights --------------------------------- */}
        <Box my={5} pt={2}>
          <Typography
            className="text-white"
            mb={2}
            style={{ fontSize: "1.5rem" }}
          >
            {" "}
            Insights{" "}
          </Typography>
          <Box display='flex' alignItems='center' justifyContent='space-between' width='100%'>
            <IconButton sx={{ pl: "0px" }} onClick={handleScrollRight}>
              <ExpandCircleDownOutlined
                sx={{ rotate: "90deg" }}
              ></ExpandCircleDownOutlined>
            </IconButton>
            <Box
              sx={{
                "&::-webkit-scrollbar": { display: "none" }, // Hide scrollbar in Chrome, Safari, and Edge
              }}
              style={{ scrollBehavior: "smooth", overflowX: "auto" }}
              className="scroll"
              display="flex"
              gap="20px"
              width="95%"
              overflow="hidden"
              ref={scrollRef}
            >
              {insights.length
                ? insights.map((item: any, index: number) => (
                  <Card key={index} className="card" style={{ minWidth: "530px", maxWidth: "530px" }}>
                    <CardContent className="text-white">
                      <Typography gutterBottom>{item.title}</Typography>
                      <Typography variant="body2" gutterBottom className="text-secondary">
                        {moment(item.publishedOn).format("MMMM DD, YYYY")}
                      </Typography>
                      <Typography
                        variant="body2"
                        style={{
                          overflow: "hidden",
                          height: expandedCards[index] ? "fit-content" : "40px",
                          transition: "max-height 0.3s ease",
                        }}
                      >
                        {item.summary}
                      </Typography>
                      <span
                        onClick={() => handleReadMore(index)} // Pass the index of the card
                        style={{ color: "rgba(74, 122, 242, 1)", cursor: "pointer" }}
                      >
                        {expandedCards[index] ? "show less" : "read more"}
                      </span>
                    </CardContent>
                  </Card>
                ))
                : null
              }
            </Box>
            <IconButton sx={{ pl: "5px" }} onClick={handleScrollLeft}>
              <ExpandCircleDownOutlined
                sx={{ rotate: "-90deg" }}
              ></ExpandCircleDownOutlined>
            </IconButton>
          </Box>
        </Box>

        {/* --------------------------------------------------- Dividents --------------------------------- */}
        <Box my={5} pt={2}>

          <Typography
            className="text-white"
            mb={2}
            style={{ fontSize: "1.5rem" }}
          >
            {" "}
            Dividends{" "}
          </Typography>


          <Box>
            <Card className="card" style={{ width: '260px' }}>
              <CardContent className="text-white d-flex justify-content-between">
                <Typography >{stockDetail?.lastDividendDateFmt ? moment(stockDetail?.lastDividendDateFmt).format("DD-MM-YYYY") : "N/A"}</Typography>
                <Typography mb="0px">
                  {stockDetail?.lastDividendValueFmt ? formatPrice(stockDetail?.lastDividendValueFmt) : null}
                </Typography>
              </CardContent>
            </Card>
          </Box>
        </Box>
        {/* --------------------------------------------------- About --------------------------------- */}
        <Box my={5} pt={2}>

          <Typography
            className="text-white"
            mb={2}
            style={{ fontSize: "1.5rem" }}
          >
            {" "}
            About{" "}
          </Typography>


          <Box>
            <Card className="card" style={{ minWidth: '530px', maxWidth: "100%" }}>
              <CardContent className="text-white">
                <Typography gutterBottom>{stockDetail?.displayName}</Typography>
                <Typography variant="body2" gutterBottom className="text-secondary">{moment(stockDetail?.updatedAt).format("MMMM DD, YYYY")}</Typography>
                <Typography
                  variant="body2"
                // style={{ overflow: 'hidden', height: expanded ? 'fit-content' : '40px', transition: 'max-height 0.3s ease' }}
                >
                  {stockDetail?.companyDescription}
                </Typography>


              </CardContent>
            </Card>
          </Box>
        </Box>
      </Container>
      <Container style={{ width: "35%", paddingTop: "20px" }}>
        <Box width="100%">

          <Box width='440px' height='fit-content' overflow='hidden'>
            {
              isTradingAcExist &&
                isDocumentRequired === false &&
                applicationStatus.toLocaleUpperCase() === "COMPLETE" ?
                <>
                  <Typography
                    component="h1"
                    fontSize={24}
                    className="text-white"
                    mb={2}
                  >
                    Buy/Sell
                  </Typography>
                  <BuySell
                    stockDetail={stockDetail}
                    tickerData={tickerData}
                    tickerDataAll={tickerDataAll}
                    icon={icon}
                  />
                </>
                :
                <RightSideSection />
            }
          </Box>
        </Box>
      </Container>
      {loading && (
        <Box
          position="fixed"
          top={0}
          left={0}
          width="100vw"
          height="100vh"
          bgcolor="rgba(0, 0, 0, 0.5)" // Semi-transparent black background
          display="flex"
          justifyContent="center"
          alignItems="center"
          style={{
            backdropFilter: "blur(5px)", // Blur effect
            zIndex: 9999, // Ensures the loader stays above all elements
          }}
        >
          <CircularProgress color="primary" />
        </Box>
      )}
      {showPopup && <Successpopup Heading={heading} Success={Success} onClose={closePopup} Message={msg} />}

    </Box>
  );
}
export default StockDetail;
