import { CircularProgress, Container, Divider } from "@mui/material";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Step, { StepProps } from "@mui/material/Step";
import StepConnector, {
  stepConnectorClasses,
} from "@mui/material/StepConnector";
import StepLabel from "@mui/material/StepLabel";
import Stepper from "@mui/material/Stepper";
import { styled } from "@mui/material/styles";
import Typography from "@mui/material/Typography";
import * as React from "react";
import { UrlConstant } from "../../../../commom/UrlConstant";
import Axios from "../../../../config/axios";
import Header2 from "../../Layout/Header2";
import FormDynamicElement from "./FormDynamicElement";
import { useNavigate } from "react-router-dom";
import Swal from "sweetalert2";
import { useRef } from "react";
import Successpopup from "../../SharedComponent/Successpopup";
import Brokrageaccoun from "../../Popups/Brokrageaccoun";
const CustomConnector = styled(StepConnector)(({ theme }) => ({
  // [`& .MuiStepConnector-line`]: {
  // },
  [`&.${stepConnectorClasses.alternativeLabel}`]: {
    top: 22,
  },
  [`&.${stepConnectorClasses.active}`]: {
    [`& .${stepConnectorClasses.line}`]: {
      backgroundImage:
        "linear-gradient( 95deg,#4EDF6D 0%,#4EDF6D 50%,#4EDF6D 100%)",
    },
  },
  [`&.${stepConnectorClasses.completed}`]: {
    [`& .${stepConnectorClasses.line}`]: {
      backgroundImage:
        "linear-gradient( 95deg,#4EDF6D 0%,#4EDF6D 50%,#4EDF6D 100%)",
    },
  },
  [`& .${stepConnectorClasses.line}`]: {
    height: 8,
    border: 0,
    // borderColor: "#FFFFFF1A",
    //   borderTopWidth: 8,
    backgroundColor: "#FFFFFF1A",
    borderRadius: 1,
    ...theme.applyStyles("dark", {
      backgroundColor: theme.palette.grey[800],
    }),
  },
}));

function CreateApplication() {
  const scrollref = useRef<any>();
  const navigate = useNavigate();
  const [activeStep, setActiveStep] = React.useState(0);
  const [skipped, setSkipped] = React.useState(new Set<number>());
  const [steps, setSteps] = React.useState([]);
  const [allStepsData, setAllStepsData] = React.useState([]);
  const [currentStepData, setCurrentStepData] = React.useState<any>([]);
  const [currentSectionName, setCurrentSectionName] = React.useState("");
  const [selectedValues, setSelectedValues] = React.useState({});
  const [validationErrors, setValidationErrors] = React.useState({});
  const [isLoading, setLoading] = React.useState<boolean>(false);
  const [showPopup, setShowPopup] = React.useState(false); // State for controlling popup visibility
  const [msg, setmsg] = React.useState(''); // State for controlling popup visibility
  const [Success, setSuccess] = React.useState(false); // State for controlling popup visibility
  const [heading, setheading] = React.useState(''); // State for controlling popup visibility
  const [showBrokragePopup, setShowBrokragePopup] = React.useState(false);
  const [applicationId, setApplicationId] = React.useState(""); // Store application ID
  const [documentsRequired, setdocumentsRequired] = React.useState(false);

  const submitAnswer = async () => {
    setLoading(true);
    const answerPayload: any = [];

    filterChildElements().forEach((element: any) => {
      if (element.value && element.value !== "") {
        let data = element.value;
        let answer = element.value;
        if (element.type === "radio" || element.type === "checkbox") {
          const selectedOption = element.options.find(
            (e: any) => e.answer === element.value
          );
          if (selectedOption) {
            data = selectedOption.id;
          }
        }

        const ans = {
          question_id: element.id,
          data: data,
          system_identifire: element.system_identifire,
          answer: answer,
        };
        answerPayload.push(ans);
      }
    });

    const currentSec: any = allStepsData[activeStep];
    const secType = currentSec?.section_type ?? "";

    const payload = {
      section_type: secType,
      data: answerPayload,
    };
    try {
      // const response =
      await Axios.post(UrlConstant.TradingAccount.SubmitAnswer, payload);

      // const resData = response.data;
      return true;
    } catch (error: any) {
      console.log("ERRR : ", error);
      // Swal.fire(error.response.data.message[0] ?? "Something went wrong");
      setheading('Error')
      setShowPopup(true)
      setmsg(error.response.data.message[0] ?? "Something went wrong")

      return false;
    } finally {
      setLoading(false);
    }
  };
  const getSectionAnswer = async (section_type) => {
    setLoading(true);
    let answerData = [];
    try {
      const payload = {
        section_type: section_type,
      };
      const response = await Axios.post(
        UrlConstant.TradingAccount.GetSectionAnswer,
        payload
      );

      const resData = response.data;

      if (resData.result === true) {
        answerData = resData.data.data;
        // const newSelectedValues = {};
        // answerData.forEach((answer: any) => {
        //   newSelectedValues[answer.question_id] = answer.answer;
        // });

        // setSelectedValues(newSelectedValues);
        // setSelectedValues((prev) => {
        //   const updatedValues = { ...prev, [element.id]: element.value };
        //   return updatedValues;
        // });
      }
    } catch (error: any) {
      console.log("ERRR : ", error);
    } finally {
      setLoading(false);
      return answerData;
    }
  };
  const submitForm = async () => {
    setLoading(true);
    try {
      const response = await Axios.get(UrlConstant.TradingAccount.SubmitForm);

      const resData = response.data;
      if (resData.status === true) {
        console.log("RESDATA", resData);

        // First, show the popup immediately
        setShowBrokragePopup(true);

        // Then, wait a moment to update applicationId and documentsRequired
        setTimeout(() => {
          const accountId = resData?.data?.payload?.accountId;
          const documentsRequired = resData?.data?.icumentUploadRequired;

          if (accountId) {
            setApplicationId(accountId);
          } else {
            console.error("Account ID not found in response data");
          }

          if (documentsRequired !== undefined) {
            setdocumentsRequired(documentsRequired);
          } else {
            console.error("Documents Required flag not found in response data");
          }
        }, 0); // Ensures popup appears first before processing data
      }

    } catch (error: any) {
      console.log("ERRR : ", error);
      // Swal.fire(error.response.data.message[0] ?? "Something went wrong");
      setheading("Error")
      setShowPopup(true)
      setmsg(error.response.data.message[0] ?? "Something went wrong")


    } finally {
      setLoading(false);
    }
  };

  const getAccountForm = async () => {
    setLoading(true);
    try {
      const response = await Axios.post(
        UrlConstant.TradingAccount.CreateAccount
      );

      const resData = response.data;
      if (resData.status === true) {
        const data = resData.data;
        const filteredData = data
          .sort((a, b) => a.position - b.position)
          .filter((e) => e.is_display_section === 1);
        setAllStepsData(filteredData);

        const allSteps = filteredData.map((e) => ({
          section_type: e.section_type,
          section_name: e.section_name,
          section_info: e.section_info,
          position: e.position,
          is_display_section: e.is_display_section,
        }));
        setSteps(allSteps);

        if (allSteps[0]) {
          const answerList = await getSectionAnswer(allSteps[0].section_type);
          const currentStep = filteredData.find(
            (e: any) => e.section_type === allSteps[0].section_type
          );
          setCurrentSectionName(allSteps[0]?.section_name ?? "");

          await setAnswerAndQuestion(currentStep, await answerList);
        }
      }
    } catch (error: any) {
      console.log("ERRR:", error);
    } finally {
      setLoading(false);
    }
  };

  React.useEffect(() => {
    getAccountForm();
  }, []);
  const removeDuplicateQuestions = (questions) => {
    return questions.filter(
      (value, index, self) => index === self.findIndex((t) => t.id === value.id)
    );
  };
  const isStepSkipped = (step: number) => skipped.has(step);

  const handleInputChange = (element) => {
    console.log("handleInputChange  : ", element);

    setSelectedValues((prev) => {
      const updatedValues = { ...prev, [element.id]: element.value };
      return updatedValues;
    });
    const unusedId: any = [];

    // if (
    //   element.type === "radio" &&
    //   element.system_identifire === "differentMailingAddress"
    // ) {
    //   const selectedOption = element.options.filter(
    //     (e) => e.answer !== element.value
    //   );

    //   console.log("SELECTED OP : ", selectedOption);
    //   let updatedSelectedValues = { ...selectedValues };

    //   selectedOption.forEach((el) => {
    //     el.child_question_ids.forEach((id) => {
    //       console.log(id);
    //       unusedId.push(id);
    //       if (updatedSelectedValues[id]) {
    //         delete updatedSelectedValues[id];
    //       }
    //     });
    //   });
    //   setSelectedValues(updatedSelectedValues);
    //   console.log("SS-->> ", updatedSelectedValues);
    //   console.log("Unused ID : ", unusedId);
    // }

    let data: any = [];
    data = currentStepData.map((e: any) => {
      // const unusedValue = unusedId.find((e) => e === e.id);
      // if (unusedValue) {
      //   delete e["value"];
      //   return e;
      // } else if (e.id === element.id) {
      //   return { ...e, value: element.value };
      // }

      if (e.id === element.id) {
        return { ...e, value: element.value };
      }
      return e;
    });
    console.log("CURR STP DATA : ", data);
    setCurrentStepData(data);
  };

  const filterChildElements = () => {
    if (
      currentStepData[0] &&
      currentStepData[0].section_type === "beneficiaries_users"
    ) {
      let found = false;
      let addTrustedContactSelected: any;

      for (let key in selectedValues) {
        if (selectedValues[key].system_identifire === "addTrustedContact") {
          found = true;
          addTrustedContactSelected = selectedValues[key].answer;
          break;
        }
      }
      const firstKey = Object.keys(selectedValues)[0];

      const isChildVisibe = selectedValues[firstKey];
      if (
        found === true &&
        (addTrustedContactSelected === "false" ||
          addTrustedContactSelected === false)
      ) {
        return currentStepData.filter((e: any) => e.position === 1);
      } else if (
        isChildVisibe === false ||
        isChildVisibe === "false" ||
        isChildVisibe === undefined
      ) {
        return currentStepData.filter((e: any) => e.position === 1);
      }
    }

    return currentStepData.filter((element: any) => {
      if (element.parent_question_id) {
        const parentElement: any = currentStepData.find(
          (el: any) => el.id === element.parent_question_id
        );

        if (parentElement) {
          let parentSelectedValue = selectedValues[parentElement.id];

          let showChild = false;

          if (parentElement.type === "radio") {
            if (
              (parentElement.system_identifire === "addTrustedContact" ||
                parentElement.system_identifire === "beneficiaries_users") &&
              (parentSelectedValue === "false" ||
                parentSelectedValue === false ||
                parentSelectedValue === undefined)
            ) {
              showChild = false;
            } else if (
              parentElement.system_identifire === "differentMailingAddress"
            ) {
              parentSelectedValue =
                parentSelectedValue === undefined
                  ? "true"
                  : parentSelectedValue;
              showChild =
                parentSelectedValue === "false" ||
                  parentSelectedValue.answer === "false" ||
                  parentSelectedValue.answer === false
                  ? true
                  : false;
            } else {
              parentSelectedValue =
                parentSelectedValue === undefined
                  ? "false"
                  : parentSelectedValue;

              showChild =
                parentSelectedValue === "false" ||
                  parentSelectedValue.answer === "false" ||
                  parentSelectedValue.answer === false
                  ? false
                  : true;
            }
          } else if (parentElement.type === "select") {
            showChild = parentSelectedValue && parentSelectedValue !== "";
            if (
              parentElement.system_identifire === "employmentType" &&
              parentSelectedValue !== "Employed"
            ) {
              showChild = false;
            } else {
              if (
                parentElement.system_identifire
                  .toLowerCase()
                  .includes(".country") &&
                element.system_identifire.toLowerCase().includes(".state")
              ) {
                if (
                  element.type === "select" &&
                  parentSelectedValue &&
                  (parentSelectedValue === "USA" ||
                    parentSelectedValue.answer === "USA")
                ) {
                  console.log("3");

                  showChild = true;
                } else if (
                  element.type === "text" &&
                  (parentSelectedValue === undefined ||
                    parentSelectedValue === "USA" ||
                    parentSelectedValue.answer === "USA")
                ) {
                  console.log("1");
                  showChild = false;
                } else if (
                  element.type === "select" &&
                  parentSelectedValue &&
                  (parentSelectedValue !== "USA" ||
                    parentSelectedValue.answer !== "USA")
                ) {
                  console.log("2");
                  showChild = false;
                } else if (
                  element.type === "select" &&
                  parentSelectedValue &&
                  (parentSelectedValue === "USA" ||
                    parentSelectedValue.answer === "USA")
                ) {
                  console.log("3");

                  showChild = true;
                } else {
                  //   showChild = true;
                  console.log("-------->>>>>> ", parentSelectedValue);
                }
              }

              if (
                parentElement.system_identifire
                  .toLowerCase()
                  .includes(".country") &&
                element.system_identifire.toLowerCase().includes(".state")
              ) {
                const parentDetail = currentStepData.find(
                  (e) =>
                    e.id === parentElement.parent_question_id &&
                    e.system_identifire === "differentMailingAddress"
                );
                console.log("billing : ", parentDetail);
                if (
                  parentDetail &&
                  (parentDetail.value === true || parentDetail.value === "true")
                ) {
                  console.log("billing ==>>>  : ", parentDetail);

                  showChild = false;
                }

                const pDetail = currentStepData.find(
                  (e) =>
                    e.id === parentElement.parent_question_id &&
                    e.system_identifire === "beneficiaries_users"
                );
                console.log("billing : ", pDetail);
                if (
                  pDetail &&
                  (pDetail.value === false || pDetail.value === "false")
                ) {
                  console.log("billing ==>>>  : ", pDetail);

                  showChild = false;
                }
              }
              if (
                parentElement.system_identifire
                  .toLowerCase()
                  .includes(".country") &&
                element.system_identifire.toLowerCase().includes(".state")
              ) {
                const parentDetail = currentStepData.find(
                  (e) =>
                    e.id === parentElement.parent_question_id &&
                    e.system_identifire === "employmentType"
                );
                console.log("employmentType : ", parentDetail);
                if (parentDetail && parentDetail.value !== "Employed") {
                  showChild = false;
                }
              }
            }
          }
          if (showChild) {
            return true;
          }

          return false;
        }
        if (
          currentStepData[0].section_type === "beneficiaries_users" &&
          element.type === "select" &&
          element.system_identifire.includes("address.state")
        ) {
          const parentEle = currentStepData.find((e) =>
            e.system_identifire.includes("address.country")
          );
          if (parentEle && parentEle.value === "USA") {
            return true;
          } else {
            return false;
          }
        }
      }
      return true;
    });
  };

  const validateForm = (currentStepData) => {
    const errors = {};
    currentStepData = removeDuplicateQuestions(currentStepData);

    currentStepData.forEach((element) => {
      if (
        !element.isOptional &&
        (!element.value || element.value === "") &&
        element.type !== "link"
      ) {
        errors[element.id] = "This field is required.";
      }
    });

    setValidationErrors(errors);
    return Object.keys(errors).length === 0;
  };

  const getElementsToValidate = (currentStepData, selectedValues) => {
    const elementsToValidate: any = [];

    currentStepData.forEach((element) => {
      if (!element.parent_question_id && element.type !== "link") {
        elementsToValidate.push(element);
      }
    });

    const visibleChildElements = filterChildElements();
    elementsToValidate.push(...visibleChildElements);

    return elementsToValidate;
  };
  const handleNext = async () => {
    const elementsToValidate = getElementsToValidate(
      currentStepData,
      selectedValues
    );
    //  console.log(scrollref.current.nextElement)
    const nextSibling = scrollref.current?.nextElementSibling;
    if (nextSibling) {
      //  nextSibling.scrollIntoView({ behavior: "smooth" });
      console.log(nextSibling);
    }

    const isValid = validateForm(elementsToValidate);

    if (!isValid) {
      return;
    }

    const isSubmitted = await submitAnswer();

    let newSkipped = skipped;
    if (isStepSkipped(activeStep)) {
      newSkipped = new Set(newSkipped.values());
      newSkipped.delete(activeStep);
    }
    const currentSec: any = allStepsData[activeStep + 1];
    const secType = currentSec?.section_type ?? "";

    if (!isSubmitted) {
    } else if (secType === "") {
      await submitForm();
    } else {
      setCurrentSectionName(currentSec?.section_name ?? "");

      const answerList = await getSectionAnswer(secType);
      setActiveStep(activeStep + 1);

      setSkipped(newSkipped);

      const currentStep: any = allStepsData.find(
        (e: any) => e.section_type === secType
      );

      if (currentStep) {
        await setAnswerAndQuestion(currentStep, await answerList);
      }
    }
  };

  const setAnswerAndQuestion = async (currentStep, answerList) => {
    let currentStepWithAnswer: any = [];
    let preFilledValues: any = {};
    if (currentStep) {
      currentStep.questions
        .filter(
          (e) => e.is_display === 1
          //  && e.section_type === currentStep.section_type
        )
        .forEach((element: any) => {
          const answer: any = answerList.find(
            (e: any) => e.question_id === element.id
          );
          if (answer) {
            preFilledValues[element.id] = answer;
          }

          const updatedElement = {
            ...element,
            value: answer ? answer.answer : "",
            dynamichint:
              answer && answer !== null && answer.daynamic_hint
                ? answer.daynamic_hint
                : null,
          };
          currentStepWithAnswer.push(updatedElement);
        });
      setSelectedValues(preFilledValues);
      setCurrentStepData(currentStepWithAnswer);
    }
  };

  const handleBack = async () => {
    const currentSec: any = allStepsData[activeStep - 1];
    const secType = currentSec?.section_type ?? "";
    setCurrentSectionName(currentSec?.section_name ?? "");

    const answerList = await getSectionAnswer(secType);

    setActiveStep(activeStep - 1);

    const currentStep: any = allStepsData.find(
      (e: any) => e.section_type === secType
    );

    await setAnswerAndQuestion(currentStep, await answerList);
  };

  const handleReset = () => {
    setActiveStep(0);
  };
  const closePopup = () => {
    setShowPopup(false); // Function to close the popup
  };
  return (
    <Container
      maxWidth={false}
      sx={{
        height: "calc(100vh - 101px)",
        paddingLeft: "0px",
        paddingRight: "0px",
      }}
      className="bg-color"
    >
      <Box height="100%" p="30px" sx={{ width: "100%" }} className="bg-color">
        <Stepper
          sx={{
            height: "5%",
            mb: "30px",
            ".MuiStepIcon-root": {
              height: "42px",
              width: "42px",
              padding: "0px",
            },
            ".MuiStep-root": {
              padding: "0px",
            },
            ".MuiStepLabel-iconContainer": {
              paddingRight: "0px",
            },
            ".MuiStepIcon-text": { fontSize: "16px" },
          }}
          activeStep={activeStep}
          connector={<CustomConnector />}
        >
          {steps.map((step: any, index) => {
            const stepProps: Partial<StepProps> = {};
            const labelProps = {};

            if (isStepSkipped(index)) {
              stepProps.completed = false;
            }

            return (
              <Step key={step.section_name + index} {...stepProps}>
                <StepLabel {...labelProps}></StepLabel>
              </Step>
            );
          })}
        </Stepper>
        {isLoading && (
          <Box
            sx={{
              position: "absolute", // Use absolute positioning to place it on top
              top: 0,
              left: 0,
              right: 0,
              bottom: 0,
              display: "flex", // Flexbox to center the loader
              justifyContent: "center",
              alignItems: "center",
              backgroundColor: "rgba(0, 0, 0, 0.5)", // Optional: semi-transparent background to overlay the loader
            }}
          >
            <CircularProgress size="40px" color="primary" />
          </Box>
        )}

        {!isLoading && (
          <Box
            height="90%"
            position="relative"
            display="flex"
            flexDirection="column"
            justifyContent="space-between"
          >
            {activeStep === steps.length ? (
              <React.Fragment>
                <Typography sx={{ mt: 2, mb: 1 }}>
                  All steps completed - you&apos;re finished
                </Typography>
                <Box sx={{ display: "flex", flexDirection: "row", pt: 2 }}>
                  <Box sx={{ flex: "1 1 auto" }} />
                  <Button onClick={handleReset}>Reset</Button>
                </Box>
              </React.Fragment>
            ) : (
              <React.Fragment>
                <Box
                  width="100%"
                  height="80%"
                  sx={{ overflowY: "auto" }}
                >
                  <Typography
                    sx={{
                      color: "white",
                      fontSize: "16px",
                      mb: "20px",
                    }}
                  >
                    {currentSectionName}
                  </Typography>
                  <Box
                    sx={{
                      display: "flex",
                      gap: "10px",
                      alignItems: "center",
                      width: "100%",
                      flexWrap: "wrap",
                      // pr: 3,
                      // flexGrow:'1 1 1'
                    }}
                  >
                    {filterChildElements().map((detail: any) => (
                      <FormDynamicElement
                        key={detail.id}
                        elementDetail={detail}
                        currentStepData={currentStepData}
                        selectedValues={selectedValues}
                        validationErrors={validationErrors}
                        onChange={handleInputChange}
                        setSelectedValues={setSelectedValues}
                      />
                    ))}
                  </Box>
                </Box>
                <Box position="absolute" bottom="0px" width="100%">
                  <Divider
                    sx={{
                      borderColor: "#b3b3b3",
                      // mt: 4,
                      mb: "20px",
                      width: "100% !important",
                      borderWidth: 1,
                    }}
                  />
                  <Box
                    sx={{
                      display: "flex",
                      width: "100%",
                      // padding: "20px 30px",
                      alignItems: "center",
                      justifyContent: "space-between",
                    }}
                  >
                    <Button
                      disabled={activeStep === 0}
                      onClick={handleBack}
                      className="text-white"
                      sx={{
                        color: "white",
                        bgcolor: "#FFFFFF33",
                        fontSize: "16px",
                        borderRadius: "50px",
                        fontWeight: "bold",
                        padding: "13px 80px",
                      }}
                    >
                      Back
                    </Button>
                    <Button
                      sx={{
                        color: "white",
                        bgcolor: "#f2674a",
                        fontSize: "16px",
                        borderRadius: "50px",
                        fontWeight: "bold",
                        padding: "13px 80px",
                      }}
                      onClick={handleNext}
                    >
                      {activeStep === steps.length - 1 ? "Finish" : "Next"}
                    </Button>
                  </Box>
                </Box>
              </React.Fragment>
            )}
          </Box>
        )}
      </Box>
      {showPopup && <Successpopup Heading={heading} Success={Success} onClose={closePopup} Message={msg} />}
      {showBrokragePopup && (
        <Brokrageaccoun
          onClose={() =>{setShowBrokragePopup(false); navigate('/dashboard')}}
          applicationId={applicationId}
          uploaddocument={documentsRequired}
          isLoading={isLoading}
        />
      )}
    </Container>
  );
}

export default CreateApplication;