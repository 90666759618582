import React, { useState } from "react";
import {
    Box,
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    IconButton,
} from "@mui/material";
import { useNavigate } from "react-router-dom";
import TradingACNotice from "../MainComponents/TradingAccount/TradingACNotice";
import TradingACDisclosure from "../MainComponents/TradingAccount/TradingACDisclosure";

const CreateTradingAccount = ({ isDialogOpen, setDialogOpen }) => {
    const [isNoticeOpen, setNoticeOpen] = useState(true);
    const [isDisclosureOpen, setDisclosureOpen] = useState(false);
    const navigate = useNavigate();

    const handleClose = () => {
        setNoticeOpen(true);
        setDisclosureOpen(false);
        setDialogOpen(false);
    };

    const handleProceed = () => {
        setNoticeOpen(false);
        setDisclosureOpen(true);
    };

    const handleCreate = () => {
        setNoticeOpen(false);
        setDisclosureOpen(false);
        setDialogOpen(false);
        navigate("/createTradingBlockUser");
    };

    return (
        <Dialog
            open={isDialogOpen}
            onClose={handleClose}
            aria-labelledby="dialog-title"
            aria-describedby="dialog-description"
            PaperProps={{ sx: { boxShadow: "0px 0px 25px rgba(170,170,170,0.7)" } }}
        >
            {/* Close Button */}
            <Box sx={{ display: "flex", justifyContent: "end", mt: 2, mr: 3 }}>
                <IconButton onClick={handleClose}>
                    <svg width="36" height="36" viewBox="0 0 36 36" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M18 33C26.25 33 33 26.25 33 18C33 9.75 26.25 3 18 3C9.75 3 3 9.75 3 18C3 26.25 9.75 33 18 33Z" stroke="white" strokeWidth="2.25" strokeLinecap="round" strokeLinejoin="round" />
                        <path d="M13.7549 22.2449L22.2449 13.7549" stroke="white" strokeWidth="2.25" strokeLinecap="round" strokeLinejoin="round" />
                        <path d="M22.2449 22.2449L13.7549 13.7549" stroke="white" strokeWidth="2.25" strokeLinecap="round" strokeLinejoin="round" />
                    </svg>
                </IconButton>
            </Box>

            {/* Notice Content */}
            {isNoticeOpen && (
                <DialogContent sx={{ px: 5, pb: 3 }}>
                    <TradingACNotice />
                </DialogContent>
            )}
            {isNoticeOpen && (
                <DialogActions sx={{ justifyContent: "center", pb: 3 }}>
                    <Button
                        onClick={handleClose}
                        variant="outlined"
                        className="btn btn-outline-primary"
                        sx={{
                            padding: "10px 40px !important",
                            borderRadius: "10px",
                        }}
                    >
                        Cancel
                    </Button>
                    <Button
                        onClick={handleProceed}
                        variant="contained"
                        className="btn btn-primary"
                        sx={{ padding: "10px 40px !important", borderRadius: "10px" }}
                    >
                        Proceed
                    </Button>
                </DialogActions>
            )}

            {/* Disclosure Content */}
            {isDisclosureOpen && (
                <DialogContent sx={{ px: 5, pb: 3 }}>
                    <TradingACDisclosure />
                </DialogContent>
            )}
            {isDisclosureOpen && (
                <DialogActions sx={{ justifyContent: "center", pb: 3 }}>
                    <Button
                        onClick={handleCreate}
                        className="btn btn-primary"
                        sx={{ padding: "10px 40px !important", borderRadius: "10px" }}
                    >
                        Create
                    </Button>
                </DialogActions>
            )}
        </Dialog>
    );
};

export default CreateTradingAccount;
