import {
  Avatar,
  Box,
  Button,
  Card,
  CardContent,
  TextField,
  ToggleButton,
  ToggleButtonGroup,
  Typography,
  Select,
  FormControl,
  MenuItem,
  Switch,
  IconButton,
  Divider,
  CircularProgress
} from "@mui/material";
import React, { useEffect, useRef, useState } from "react";
import PriceAdjuster from "./Priceadjuster";
import LoanDetails from "./loandetails";
import LeftSide from "../Layout/LeftSide";
import { KeyboardArrowLeftOutlined } from "@mui/icons-material";
import Loaninfo from "./loaninfo";
import Repayment from "./repayment";
import { endLoading, startLoading } from "../../../Redux/loadingSlice";
import Axios from "../../../config/axios";
import { UrlConstant } from "../../../commom/UrlConstant";
import { useDispatch } from "react-redux";
import { formatPrice, getUserFromLocalStorage } from "../../../Utils/utils";
import FlashOnIcon from '@mui/icons-material/FlashOn';
import OrderSuccessPopup from "../Popups/OrderSuccessPopup";
import Swal from "sweetalert2";
import moment from "moment";
import Successpopup from "../SharedComponent/Successpopup";

function BuySell({ stockDetail, tickerData, tickerDataAll, icon }: any) {
  const dispatch = useDispatch();


  const buyButtonRef = useRef<any>(null);
  const sellButtonRef = useRef<any>(null);
  const [isBuyActive, setIsBuyActive] = useState<any>(true);
  const [isSellActive, setIsSellActive] = useState<any>(false);
  const [selection, setSelectedRatio] = React.useState<String>("");
  const [selectLimit, setSelectLimit] = React.useState<String>('Market');
  const [IsOnBoosterLoan, setOnBoosterLoan] = useState<boolean>(false);
  const [isLoaninfo, setIsLoaninfo] = useState<boolean>(false);
  const [ispayment, setIspayment] = useState<boolean>(false);
  const [tradingInfo, setTradingInfo] = useState<any>();
  const [stockQty, setStockQty] = useState<any>(0);
  const [estimatedAmount, setEstimatedAmount] = useState<any>(0);
  const [limitPrice, setLimitPrice] = useState<any>(0);
  const [boosterLoadDetails, setBoosterLoanDetails] = useState<any>();
  const [loanAmountData, setLoanAmountData] = useState<any>();
  const [loanElogibilityData, setLoanEligibilityData] = useState<any>();
  const [boosterLoading, setBoosterLoading] = useState<any>(false)
  const [loanAmount, setLoanAmount] = React.useState<number>();
  const [recalculate, setRecalculate] = React.useState<boolean>(false);
  const [acceptCondition, setacceptCondition] = React.useState<boolean>(false);
  const [paymentMethods, setPaymentMethods] = React.useState<any>([]);
  const [availbelQty, setAvailbelQty] = React.useState<any>(0);
  const [selectedPaymentMethod, setSelectedPaymentMethod] = React.useState<any>({});
  const [popupOpen, setPopupOpen] = useState(false);
  const [orderDetails, setOrderDetails] = useState<any>();
  const [showPopup, setShowPopup] = useState(false); // State for controlling popup visibility
  const [msg, setmsg] = useState(''); // State for controlling popup visibility
  const [Success, setSuccess] = useState(false); // State for controlling popup visibility
  const [heading, setheading] = useState(''); // State for controlling popup visibility
  const [loading, setLoading] = useState(false);







  const user = getUserFromLocalStorage();

  const handleselect = (
    event: React.MouseEvent<HTMLElement>,
    newselection: string
  ) => {
    setSelectedRatio(newselection);
    onChangeRatio(newselection)
  };

  const handleBuyClick = () => {
    setIsBuyActive(true); // Activate Buy button and deactivate Sell button
    setIsSellActive(false); // Activate Buy button and deactivate Sell button
  };

  const handleinfo = () => {
    setIsLoaninfo(!isLoaninfo)
  };

  const handlepayment = () => {
    setIspayment(!ispayment)
  };

  const handleSellClick = () => {
    setIsSellActive(true); // Activate Sell button and deactivate Buy button
    setIsBuyActive(false); // Activate Sell button and deactivate Buy button
  };

  const handleChange = (e) => {
    setLimitPrice(tickerData?.lastPrice)
    setSelectLimit(e.target.value);
  };
  const handleswitch = (e) => {
    if (estimatedAmount) {
      // IsOnBoosterLoan(true)
      setBoosterLoading(true)
      setOnBoosterLoan(e.target.checked);
    } else {
      alert("Please Enter valid amount/shares")
    }
  };

  const fetchTradingBalance = async () => {
    // check-trading-balance
    dispatch(startLoading());
    setLoading(true);
    if (!user?.tradingblock_application_id) return
    const data = {
      "accountId": user?.tradingblock_application_id
    }
    try {
      const response = await Axios.post(
        UrlConstant.BuySell.GetTradingBalance, data)

      setTradingInfo(response.data?.data?.Payload)
    } catch (error: any) {
      console.error("ERRR : ", error);
      // Swal.fire('Error', error.response?.data?.message || "An error occurred.", 'error')

      setheading('Error')
      setShowPopup(true)
      setmsg(error.response?.data?.message || "An error occurred.")

    } finally {
      dispatch(endLoading());
      setLoading(false);
    }
  }
  const fatchBoosterLoanData = async () => {
    // check-trading-balance
    dispatch(startLoading());
    setLoading(true);
    try {
      const response = await Axios.get(
        UrlConstant.BoosterLoan.CheckBoosterLoan.replace("{0}", stockDetail?.symbol))

      setBoosterLoanDetails(response.data?.data)
    } catch (error: any) {
      console.error("ERRR : ", error);
      // Swal.fire('Error', error.response?.data?.message || "An error occurred.", 'error')
      setheading('Error')
      setShowPopup(true)
      setmsg(error.response?.data?.message || "An error occurred.")

    } finally {
      dispatch(endLoading());
      setLoading(false);
    }
  }

  const fetchLoanEligibility = async () => {
    setBoosterLoading(true)
    dispatch(startLoading());
    setLoading(true);
    const data = {
      "symbol": stockDetail?.symbol,
      "stockAmount": +estimatedAmount
    }

    try {
      const response = await Axios.post(
        UrlConstant.BoosterLoan.CheckBoosterEligibility, data)
      setLoanEligibilityData(response.data?.data)
    } catch (error: any) {
      console.error("ERRR : ", error);
      // Swal.fire('Error', error.response?.data?.message || "An error occurred.", 'error')
      setheading('Error')
      setShowPopup(true)
      setmsg(error.response?.data?.message || "An error occurred.")

      setOnBoosterLoan(false)
    } finally {
      dispatch(endLoading());
      setBoosterLoading(false)
      setLoading(false);
    }
  }
  const fetchLoanAmount = async (amount: any) => {
    // check-trading-balance
    dispatch(startLoading());
    setLoading(true);
    const amountData = amount ?? estimatedAmount
    try {
      const response = await Axios.get(
        UrlConstant.BoosterLoan.GetLoanAmount.replace("{0}", amountData))

      setLoanAmountData(response.data?.data)
    } catch (error: any) {
      console.error("ERRR : ", error);
      // Swal.fire('Error', error.response?.data?.message || "An error occurred.", 'error')
      setheading('Error')
      setShowPopup(true)
      setmsg(error.response?.data?.message || "An error occurred.")
    } finally {
      dispatch(endLoading());
      setLoading(false);
    }
  }
  const fetchPaymentMethod = async () => {
    dispatch(startLoading());
    setLoading(true);
    try {
      const response = await Axios.get(
        UrlConstant.BoosterLoan.GetPaymentMethod)
      const list = response.data?.data
      setPaymentMethods(list)
      setSelectedPaymentMethod(list[0])
    } catch (error: any) {
      console.error("ERRR : ", error);
      // Swal.fire('Error', error.response?.data?.message || "An error occurred.", 'error')
      setheading('Error')
      setShowPopup(true)
      setmsg(error.response?.data?.message || "An error occurred.")
    } finally {
      dispatch(endLoading());
      setLoading(false);
    }
  }

  const fetchAvailbleQty = async () => {
    dispatch(startLoading());
    setLoading(true);
    const data = {
      "accountId": user?.tradingblock_application_id
    }
    try {
      const response = await Axios.post(
        UrlConstant.BuySell.AvailbleQty.replace("{symbol}", stockDetail?.symbol), data)
      if (response.data?.data) {
        setAvailbelQty(response.data?.data.quantity || 0)
      }
    } catch (error: any) {
      console.error("ERRR : ", error);
      // Swal.fire('Error', error.response?.data?.message || "An error occurred.", 'error')
      setheading('Error')
      setShowPopup(true)
      setmsg(error.response?.data?.message || "An error occurred.")
    } finally {
      dispatch(endLoading());
      setLoading(false);
    }
  }

  const onRecalculate = async () => {
    await fetchLoanAmount(loanAmount)
    setRecalculate(false);
  };

  //Buy now Action

  // const onBuyNow = async () => {
  //   dispatch(startLoading());
  //   if (IsOnBoosterLoan && !acceptCondition) {
  //     alert("accept the loan condtions")
  //     return
  //   }
  //   const data = {
  //     "symbol": stockDetail?.symbol,
  //     "quantity": Number(stockQty),
  //     "accountId": user?.tradingblock_application_id,
  //     "type": "Buy",
  //     "orderType": selectLimit,
  //     "price": Number(tickerData?.lastPrice),
  //     "loanPrice": Number(IsOnBoosterLoan ? loanAmount : 0),
  //     "loanQuantity": Number(IsOnBoosterLoan ? stockQty : 0)
  //   }

  //   try {
  //     const response = await Axios.post(
  //       UrlConstant.BuySell.PlaceBuyOrderFake, data)
  //     console.log(response, "response")
  //   } catch (error: any) {
  //     console.error("ERRR : ", error);
  //   } finally {
  //     dispatch(endLoading());
  //   }
  // }
  const onBuyNow = async (orderType: any) => {
    dispatch(startLoading());
    setLoading(true);
    const balance = tradingInfo?.BuyingPower?.AvailableFunds || 0;
    if (IsOnBoosterLoan && !acceptCondition) {
      // Swal.fire("Accept the loan condtions")
      setShowPopup(true)
      setmsg('Accept the loan condtions')
      return
    }

    if (orderType == "Sell" && Number(stockQty) > availbelQty) {
      // Swal.fire("You have exceed the maximum availble quantity")
      setShowPopup(true)
      setmsg('You have exceed the maximum availble quantity')
      return
    }
    if (orderType == "Buy" && Number(estimatedAmount) > balance) {
      // Swal.fire("You don't have enough Balance")
      setShowPopup(true)
      setmsg("You don't have enough Balance")
      return
    }
    console.log(estimatedAmount, "estimatedAmount", balance)
    let data;

    if (selectLimit == "Market") {
      data = {
        "symbol": stockDetail?.symbol,
        "quantity": Number(stockQty),
        "accountId": user?.tradingblock_application_id,
        "type": orderType,
        "orderType": selectLimit,
      }
    } else {
      data = {
        "symbol": stockDetail?.symbol,
        "quantity": Number(stockQty),
        "accountId": user?.tradingblock_application_id,
        "type": orderType,
        "orderType": selectLimit,
        "price": limitPrice,
      }
    }

    try {
      const response = await Axios.post(
        UrlConstant.BuySell.PlaceBuyOrder, data)
      setPopupOpen(true)
      const orderDetails = {
        symbol: stockDetail?.symbol,
        stockName: stockDetail?.displayName,
        orderId: response.data?.data?.Payload?.OrderId,
        dateTime: moment().format("DD/MM/YYYY hh:mm A"),
        totalQty: stockQty,
        totalAmount: estimatedAmount,
      };
      setOrderDetails(orderDetails)
      setEstimatedAmount(0)
      setStockQty(0)
      console.log(response, "response")
    } catch (error: any) {
      console.error("ERRR : ", error);
      // Swal.fire('Error', error.response?.data?.message[0] || "An error occurred.", 'error')
      setheading('Error')
      setShowPopup(true)
      setmsg(error.response?.data?.message[0] || "An error occurred.")
    } finally {
      dispatch(endLoading());
      setLoading(false);
    }
  }

  useEffect(() => {
    if (IsOnBoosterLoan) {
      setRecalculate(true)
      fetchLoanEligibility()
      setLoanAmount(estimatedAmount)
    }
  }, [estimatedAmount])

  useEffect(() => {
    if (IsOnBoosterLoan) {
      fetchLoanAmount(null)
      fetchLoanEligibility()
    }
  }, [IsOnBoosterLoan])

  useEffect(() => {
    fetchTradingBalance()
    // fatchBoosterLoanData()
    // if (estimatedAmount) {
    //   fetchLoanEligibility()
    // }

  }, [stockDetail])

  useEffect(() => {
    // fetchPaymentMethod()
  }, [])

  useEffect(() => {
    if (stockDetail?.symbol && user?.tradingblock_application_id) {
      fetchAvailbleQty()
    }
  }, [stockDetail])

  useEffect(() => {
    setOnBoosterLoan(false)
    setEstimatedAmount(0)
    setStockQty(0)
  }, [isBuyActive])

  const onChangeRatio = (newSelection) => {
    setSelectLimit("Market")
    setOnBoosterLoan(false)
    const balance = tradingInfo?.BuyingPower?.AvailableFunds || 0; // Default to 0 if undefined
    let estimatedAmt = 0;
    let qty = 0;
    console.log(newSelection, "newSelection")
    switch (newSelection) {
      case "1/4":
        estimatedAmt = balance * 0.25; // 25% of balance
        break;
      case "1/2":
        estimatedAmt = balance * 0.5; // 50% of balance
        break;
      case "3/4":
        estimatedAmt = balance * 0.75; // 75% of balance
        break;
      case "Full":
        estimatedAmt = balance; // Full balance
        break;
      default:
        console.error("Invalid selection");
        break;
    }

    // Calculate Quantity
    const lastPrice = tickerData?.lastPrice || 1; // Avoid division by 0
    qty = estimatedAmt / lastPrice;

    // Adjust based on isFractional
    if (IsOnBoosterLoan && !tickerDataAll?.isFractional) {

      qty = Math.trunc(estimatedAmt / lastPrice)  // Round to nearest whole number
      estimatedAmt = qty * lastPrice; // Recalculate Estimated Budget

    }


    // Update state
    console.log("qty", qty)
    console.log("estimatedAmt", estimatedAmt)
    setEstimatedAmount(estimatedAmt.toFixed(2));
    setStockQty(qty.toFixed(2));
  };

  const handleQtyChange = (newQty) => {
    const price = selectLimit === "Limit" ? limitPrice : tickerData?.lastPrice || 1; // Use limitPrice if "Limit", otherwise fallback to lastPrice

    setSelectedRatio(""); // Clear the ratio filter

    let qty = newQty;
    let estimatedAmt = qty * price;

    // Adjust based on isFractional
    if (IsOnBoosterLoan && !tickerDataAll?.isFractional) {
      qty = Math.round(qty); // Round to nearest whole number
      estimatedAmt = qty * price; // Recalculate Estimated Budget
    }

    setStockQty(qty); // Update Qty
    setEstimatedAmount(estimatedAmt.toFixed(2)); // Update Estimated Budget
  };

  const handleEstimatedAmountChange = (newEstimatedAmount) => {
    const price = selectLimit === "Limit" ? limitPrice : tickerData?.lastPrice || 1; // Use limitPrice if "Limit", otherwise fallback to lastPrice

    setSelectedRatio(""); // Clear the ratio filter

    let estimatedAmt = newEstimatedAmount;
    let qty = estimatedAmt / price;

    // Adjust based on isFractional
    // if (!tickerDataAll?.isFractional) {
    //   qty = Math.round(qty); // Round to nearest whole number
    //   estimatedAmt = qty * price; // Recalculate Estimated Budget
    // }

    setEstimatedAmount(estimatedAmt); // Update Estimated Budget
    setStockQty(qty.toFixed(2)); // Update Qty
  };



  useEffect(() => {
    if (selectLimit == "Limit") {
      let estimatedAmt = stockQty * limitPrice;
      setEstimatedAmount(estimatedAmt.toFixed(2)); // Update Estimated Budget
    }

  }, [limitPrice, selectLimit])
  const closePopup = () => {
    setShowPopup(false); // Function to close the popup
  };
  return (
    <div className="buy">
      <Card className="card" sx={{ width: "100%", height: '100%', paddingBottom: "0px" }}>
        <CardContent sx={{ padding: "0px !important" }}>
          {isLoaninfo && <Typography className="p-3 text-white" fontSize='20px'><IconButton onClick={handleinfo}><KeyboardArrowLeftOutlined sx={{ height: '30px', width: '30px' }} /></IconButton>Loan Information</Typography>}
          {
            ispayment ?
              <>
                <Typography className="p-3 text-white" fontSize='20px'><IconButton onClick={handlepayment}><KeyboardArrowLeftOutlined sx={{ height: '30px', width: '30px' }} /></IconButton>Re-payment Method</Typography>
              </>
              : <>
                <Box
                  // mb={3}
                  padding='30px'
                  // pb={0}
                  sx={{
                    display: 'flex',
                    flexDirection: { xs: 'column', sm: 'row' }, // Responsive layout
                    justifyContent: 'space-between',
                    alignItems: { xs: 'flex-start', sm: 'center' },
                    gap: '15px',
                  }}
                >
                  {/* Stock Details */}
                  <Box className="text-white" display="flex" alignItems="center" gap="15px">
                    <Avatar
                      alt={stockDetail?.displayName}
                      src={icon}
                      sx={{
                        width: 36,
                        height: 36,
                        backgroundColor: "white",
                        objectFit: "fill",
                      }}
                      className="icon"
                    />
                    <Box>
                      <Box display="flex">
                        <Typography
                          variant="h5"
                          mb={0.5}
                          sx={{
                            fontSize: { xs: '14px', sm: '16px' },
                            fontWeight: 600,
                            display: '-webkit-box',
                            WebkitBoxOrient: 'vertical',
                            WebkitLineClamp: 1, // Limits text to 2 lines
                            overflow: 'hidden',
                            textOverflow: 'ellipsis',
                            whiteSpace: 'normal',
                          }}
                        >
                          {stockDetail?.displayName}
                        </Typography>

                        {boosterLoadDetails?.isEligible ? <Box>
                          <svg width="10" height="18" viewBox="0 0 10 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M0 16L3.38446 11.0694H0.509992L3.70898 6.42901H0.18548L4.96068 0H9L5.56346 4.01205L8.87498 3.91541L4.76159 9.136H8.02063L0 16Z" fill="url(#paint0_linear_483_3687)" />
                            <defs>
                              <linearGradient id="paint0_linear_483_3687" x1="4.5" y1="8.20062e-08" x2="-0.358359" y2="15.8904" gradientUnits="userSpaceOnUse">
                                <stop stop-color="#EEA415" />
                                <stop offset="1" stop-color="#FFD25E" />
                              </linearGradient>
                            </defs>
                          </svg>
                        </Box>
                          : null}

                      </Box>
                      <Typography
                        variant="body2"
                        className="text-secondary"
                        sx={{
                          fontSize: '10px',
                          color: '#9E9E9E',
                        }}
                      >
                        {stockDetail?.stockExchangeCode}:{stockDetail?.symbol}
                      </Typography>
                    </Box>
                  </Box>

                  {/* Price Details */}
                  <Box>
                    <Box
                      display="flex"
                      justifyContent={{ xs: 'flex-start', sm: 'flex-end' }}
                      flexWrap="wrap"
                    // gap="10px"
                    >
                      <Typography
                        // variant="h6"
                        className="text-white"
                        sx={{
                          fontSize: { xs: '16px' },
                          // fontWeight: 700,
                          textAlign: { xs: 'left', sm: 'right' },
                        }}
                      >
                        ${tickerData?.lastPrice} &nbsp;
                        <span style={{ color: tickerData.diffAmount > 0 ? "#4edf6d" : "#ff2a2a", }}>

                          {tickerData.diffAmount == 0 ? "$" : tickerData.diffAmount > 0 ? "+$" : "-$"}
                          {formatPrice(Math.abs(tickerData.diffAmount))}
                          ({formatPrice(tickerData.percentage)}%)
                        </span>

                      </Typography>
                      <Typography
                        // variant="body2"
                        sx={{
                          fontSize: '12px',
                          // color: tickerData.diffAmount > 0 ? "#4edf6d" : "#ff2a2a",
                          color: '#4A7AF2',
                          fontWeight: 500,
                        }}
                      >
                        Wallet Balance: ${formatPrice(tradingInfo?.BuyingPower?.AvailableFunds)}
                      </Typography>
                    </Box>

                  </Box>
                </Box>
                {/* <Typography
                  variant="body2"
                  sx={{
                    color: '#4A7AF2',
                    fontSize: '12px',
                    textAlign: { xs: 'left', sm: 'right' },
                  }}
                >
                  Wallet Balance: $ {tradingInfo?.BuyingPower?.AvailableFunds}
                </Typography> */}

                {/* Separator */}
                <hr style={{ borderColor: '#FFFFFF70', margin: '0px' }} />
                {/* <Box display="flex" justifyContent="space-between" mt="10px">
                  <Typography
                    variant="body2"
                    className="text-secondary"
                    sx={{
                      fontSize: '12px',
                      color: '#9E9E9E',
                    }}
                  >
                    ASk Price: {tickerDataAll?.Ask}
                  </Typography>
                  <Typography
                    variant="body2"
                    className="text-secondary"
                    sx={{
                      fontSize: '12px',
                      color: '#9E9E9E',
                    }}
                  >
                    Bid Price: {tickerDataAll?.Bid}
                  </Typography>
                </Box> */}
              </>
          }
          {isLoaninfo ? <Loaninfo stockDetail={stockDetail} loanAmountData={loanAmountData} stockQty={stockQty} /> : ispayment ? <Repayment paymentMethods={paymentMethods} setSelectedPaymentMethod={setSelectedPaymentMethod} handlepayment={handlepayment} /> :
            <> <Box
              borderBottom="1px"
              borderColor="#FFFFFF"
              display="flex"
              width="100%"
              mt={1}
              justifyContent="space-between"
            >
              <Button
                ref={buyButtonRef}
                onClick={handleBuyClick}
                sx={{
                  border: "none",
                  width: "50%",
                  bgcolor: "transparent",
                  display: "flex",
                  justifyContent: "center",
                  borderBottom: isBuyActive ? "3px solid #4edf6d" : "none",
                }}
              >
                <Typography
                  textAlign="center"
                  variant="body1"
                  sx={{ color: isBuyActive ? "#4EDF6D" : "white" }}
                >
                  Buy
                </Typography>
              </Button>
              <Button
                ref={sellButtonRef}
                onClick={handleSellClick}
                sx={{
                  border: "none",
                  width: "50%",
                  borderBottom: isSellActive ? "3px solid #FF2A2A" : "none",
                  bgcolor: "transparent",
                  display: "flex",
                  justifyContent: "center",
                  "&.Mui-disabled": {
                    cursor: "not-allowed",
                    pointerEvents: "auto",
                  },
                }}
                disabled={!availbelQty || availbelQty == 0}
              >
                <Typography
                  textAlign="center"
                  variant="body1"
                  sx={{
                    color: isSellActive ? "#FF2A2A" : availbelQty == 0 ? "gray" : "white",

                  }}
                >
                  {" "}
                  Sell{" "}
                </Typography>
              </Button>
            </Box>
              <hr style={{ borderColor: "#FFFFFF70", margin: "0px" }}></hr>


              <>
                <Box p='20px 30px 0px 30px' mb='10px'>
                  {!isSellActive &&
                    <ToggleButtonGroup
                      // className="border-white"
                      // color="primary"
                      // fullWidth
                      value={selection}
                      exclusive
                      onChange={(event, newFilter) => {
                        if (newFilter !== null) {
                          handleselect(event, newFilter);
                        }
                      }}
                      aria-label="Platform"
                      sx={{
                        fontSize: '14px',
                        '.MuiToggleButton-root:hover': { color: isSellActive ? "#FF2A2A !important" : '', bgcolor: isSellActive ? "#FF2A2A20 !important" : '' },
                        '.MuiToggleButton-root.Mui-selected': { color: isSellActive ? "#FF2A2A !important" : '', bgcolor: isSellActive ? "#FF2A2A20 !important" : '' }
                      }}
                    >
                      <ToggleButton value="1/4" sx={{ fontSize: '14px', lineHeight: '0' }}>1/4</ToggleButton>
                      <ToggleButton value="1/2">1/2</ToggleButton>
                      <ToggleButton value="3/4">3/4</ToggleButton>
                      <ToggleButton value="Full">Full</ToggleButton>
                    </ToggleButtonGroup>
                  }
                </Box>
                {
                  selectLimit === 'Limit' && <Box className='px-4 mb-3' display='flex' alignItems='center' justifyContent='space-between'>
                    <Typography className="text-secondary">Limit Price</Typography>
                    <PriceAdjuster value={limitPrice} setValue={setLimitPrice} />

                  </Box>
                }
                <Box className='px-4' mb='10px' display='flex' alignItems='center' justifyContent='space-between'>
                  <Typography className="text-secondary">Qty.(Whole)</Typography>
                  <TextField className="w-25" id="outlined-basic"
                    sx={{
                      "& .MuiOutlinedInput-root": {
                        borderRadius: "10px !important",

                      },
                      "& .MuiOutlinedInput-input": {
                        padding: "10px",
                        height: '36px',
                        boxSizing: 'border-box',
                        textAlign: 'end !important',
                        color: `${!isBuyActive && Number(stockQty) > availbelQty ? "#FF2A2A !important" : "#4edf6d !important"}`


                      }
                    }} variant="outlined"
                    value={stockQty}
                    onChange={(e) => handleQtyChange(e.target.value)}
                  />
                </Box>
                <Box className='px-4' display='flex' alignItems='center' justifyContent='space-between'>
                  <Box display={"flex"} alignItems={"center"}>
                    <Typography className="text-secondary">Estimated</Typography>
                    <FormControl variant="standard" sx={{ m: 0, minWidth: 120 }}>
                      <Select
                        labelId="demo-simple-select-standard-label"
                        id="demo-simple-select-standard"
                        value={selectLimit}
                        className="text-white "
                        onChange={handleChange}
                        MenuProps={{
                          PaperProps: {
                            sx: {
                              bgcolor: "rgba(0,0,0,0.4)!important", // set the dropdown background color here
                              "& .MuiMenuItem-root": {
                                color: "white !important", // set the text color of menu items
                                margin: '0px'
                              },

                            },
                          },
                        }}
                        sx={{
                          width: 'fit-content',
                          m: '0px',
                          ".MuiFormControl-root": {
                            margin: '0px'
                          },
                          color: "white !important", // Set the color for the select text
                          "&:before": {
                            borderBottom: "2px dashed white", // Initial underline color
                          },
                          "&:before:hover": {
                            borderBottom: "2px dashed white", // Initial underline color
                          },
                          "&:after": {
                            borderBottom: "2px dashed white", // Underline color when focused
                          }
                        }}
                      >
                        <MenuItem value="Market" className="text-white bg-color">Market</MenuItem>
                        <MenuItem value="Limit" className="text-white bg-color">Limit</MenuItem>
                      </Select>
                    </FormControl>
                  </Box>
                  <TextField
                    className="w-25 text-success" id="filled-basic"
                    sx={{
                      "& .MuiFilledInput-root": {
                        borderRadius: "10px !important",

                        backgroundColor: 'rgba(20, 20, 20, 0.5)',
                        '&:before': {
                          border: 'none !important'
                        }

                      },
                      "& .MuiFilledInput-input": {
                        padding: "10px",
                        height: '36px',
                        boxSizing: 'border-box',
                        textAlign: 'end !important',
                        color: `${estimatedAmount <= tradingInfo?.BuyingPower?.AvailableFunds ? "#4edf6d !important" : isBuyActive ? "#FF2A2A !important" : "#4edf6d !important"}`

                      },

                    }}
                    variant="filled"
                    value={`${estimatedAmount}`}
                    // disabled={!tickerDataAll?.isFractional}
                    onChange={(e) => handleEstimatedAmountChange(e.target.value)}
                  />
                </Box>
                {/* Booster loan disabled for now */}
                {/* {!isSellActive && boosterLoadDetails?.isEligible  ? */}
                {false ?
                  <>
                    <hr style={{ borderColor: "#FFFFFF70", margin: "120px 0px 20px 0px" }}></hr>
                    <Box padding='0px 30px' display='flex' alignItems='center' justifyContent='space-between'>
                      <Box display='flex' gap='10px' alignItems='center' className='text-white'>
                        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                          <g clip-path="url(#clip0_523_1015)">
                            <circle cx="12" cy="12" r="12" fill="url(#paint0_linear_523_1015)" />
                            <path d="M7.5 19.667L10.8082 15.0446H7.9985L11.1254 10.6942H7.6813L12.3489 4.66699H16.2972L12.9381 8.42829L16.175 8.33769L12.1543 13.232H15.3399L7.5 19.667Z" fill="white" />
                          </g>
                          <defs>
                            <linearGradient id="paint0_linear_523_1015" x1="12" y1="1.23009e-07" x2="7.64634" y2="25.315" gradientUnits="userSpaceOnUse">
                              <stop stop-color="#EEA415" />
                              <stop offset="1" stop-color="#FFD25E" />
                            </linearGradient>
                            <clipPath id="clip0_523_1015">
                              <rect width="24" height="24" fill="white" />
                            </clipPath>
                          </defs>
                        </svg>

                        <Typography>Activate Booster Loan</Typography>
                        <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                          <g clip-path="url(#clip0_523_1021)">
                            <path d="M9.06904 11.2726C8.90548 11.2879 8.74126 11.251 8.59995 11.1672C8.4967 11.0612 8.4472 10.914 8.46542 10.7672C8.46923 10.6449 8.48382 10.5232 8.50904 10.4035C8.53345 10.2662 8.56498 10.1303 8.60358 9.99625L9.03267 8.51988C9.07692 8.37416 9.10617 8.22429 9.11995 8.0726C9.11995 7.90897 9.14176 7.79622 9.14176 7.73079C9.15089 7.43913 9.02636 7.15925 8.80358 6.97079C8.52954 6.76041 8.18829 6.65701 7.84358 6.67988C7.59654 6.6836 7.35142 6.72404 7.11629 6.79988C6.85933 6.87988 6.58901 6.97563 6.30539 7.08716L6.18176 7.56716C6.26539 7.53807 6.36723 7.50535 6.48358 7.46897C6.59458 7.4361 6.70964 7.41894 6.82539 7.41807C6.98776 7.40047 7.1512 7.44035 7.2872 7.53079C7.37958 7.64101 7.42292 7.78419 7.4072 7.92716C7.40679 8.04944 7.39339 8.17135 7.3672 8.29079C7.34173 8.41807 7.30901 8.5526 7.26901 8.69441L6.83629 10.178C6.80142 10.3159 6.77351 10.4555 6.75267 10.5962C6.73567 10.7167 6.72717 10.8382 6.7272 10.9598C6.72542 11.2535 6.8597 11.5314 7.09083 11.7126C7.36908 11.9262 7.7152 12.0321 8.06536 12.0108C8.31192 12.0158 8.55764 11.9802 8.79264 11.9053C8.9987 11.835 9.27386 11.7344 9.61811 11.6035L9.73448 11.1453C9.64123 11.184 9.5452 11.2156 9.4472 11.2398C9.32326 11.2681 9.19601 11.2791 9.06904 11.2726Z" fill="white" />
                            <path d="M9.5237 4.27305C9.32579 4.0913 9.06504 3.99352 8.79642 4.00033C8.52795 3.99427 8.26745 4.09196 8.06913 4.27305C7.70563 4.58649 7.66504 5.13527 7.97851 5.4988C8.00645 5.53121 8.03673 5.56149 8.06913 5.58943C8.48326 5.95983 9.10957 5.95983 9.52367 5.58943C9.88717 5.2729 9.92526 4.72165 9.60873 4.35815C9.58242 4.32786 9.55398 4.29943 9.5237 4.27305Z" fill="white" />
                            <path d="M8 0C3.58172 0 0 3.58172 0 8C0 12.4183 3.58172 16 8 16C12.4183 16 16 12.4183 16 8C16 3.58172 12.4183 0 8 0ZM8 15.2727C3.98338 15.2727 0.727281 12.0166 0.727281 8C0.727281 3.98338 3.98338 0.727281 8 0.727281C12.0166 0.727281 15.2727 3.98338 15.2727 8C15.2727 12.0166 12.0166 15.2727 8 15.2727Z" fill="white" />
                          </g>
                          <defs>
                            <clipPath id="clip0_523_1021">
                              <rect width="16" height="16" fill="white" />
                            </clipPath>
                          </defs>
                        </svg>

                      </Box>
                      <Switch checked={IsOnBoosterLoan}
                        onChange={handleswitch}
                        sx={{


                          "& .MuiSwitch-switchBase.Mui-checked": {
                            color: "#f2674a", // Thumb color when checked
                          },
                          "& .MuiSwitch-switchBase.Mui-checked + .MuiSwitch-track": {
                            bgcolor: "#f2674a", // Track color when checked
                          },
                          "& .MuiSwitch-track": {
                            bgcolor: 'rgba(255,255,255,0.4)',
                          }
                        }} />
                    </Box>
                    <Typography className="text-secondary px-4" fontSize='10px'>Invest Now, Pay Later with our Booster Loans! Minimum $100 Loan Amount.</Typography>
                  </>
                  : null
                }
                <hr style={{ borderColor: "#FFFFFF70", margin: "20px 0px" }}></hr>
                {
                  IsOnBoosterLoan && loanElogibilityData ? <Box className='px-4 '>
                    <LoanDetails
                      boosterLoading={boosterLoading}
                      loanAmountData={loanAmountData}
                      loanElogibilityData={loanElogibilityData}
                      setLoanEligibilityData={setLoanEligibilityData}
                      recalculate={recalculate}
                      onRecalculate={onRecalculate}
                      setOnBoosterLoan={setOnBoosterLoan}
                      setRecalculate={setRecalculate}
                      setLoanAmount={setLoanAmount}
                      stockQty={stockQty}
                      estimatedAmount={estimatedAmount}
                      setloaninfo={handleinfo}
                      setpayment={handlepayment}
                      onBuyNow={onBuyNow}
                      acceptCondition={acceptCondition}
                      setacceptCondition={setacceptCondition}
                      selectedPaymentMethod={selectedPaymentMethod}
                    />
                  </Box>
                    : <Box px={4} mb='20px'>
                      {isBuyActive ?
                        <Button
                          onClick={() => { onBuyNow("Buy") }}
                          //  sx={{marginTop:'20px'}}

                          variant="contained"
                          // color="primary"
                          // fullWidth
                          className="text-dark  fw-700 btn"

                          sx={{ fontSize: '16px', width: '100%', bgcolor: `${isBuyActive ? "#4edf6d !important" : "#FF2A2A !important"}`, padding: '10px !important' }}
                        >
                          {isBuyActive ? 'Buy Now' : 'Sell Now'}
                        </Button>
                        :
                        <Button
                          onClick={() => { onBuyNow("Sell") }}
                          //  sx={{marginTop:'20px'}}

                          variant="contained"
                          // color="primary"
                          // fullWidth
                          className="text-dark  fw-700 btn"
                          sx={{ fontSize: '16px', width: '100%', bgcolor: `${isBuyActive ? "#4edf6d !important" : "#FF2A2A !important"}`, padding: '10px !important' }}
                        >
                          {isBuyActive ? 'Buy Now' : 'Sell Now'}
                        </Button>
                      }


                    </Box>}

              </>

            </>
          }


        </CardContent>
      </Card>
      {loading && (
        <Box
          position="fixed"
          top={0}
          left={0}
          width="100vw"
          height="100vh"
          bgcolor="rgba(0, 0, 0, 0.5)" // Semi-transparent black background
          display="flex"
          justifyContent="center"
          alignItems="center"
          style={{
            backdropFilter: "blur(5px)", // Blur effect
            zIndex: 9999, // Ensures the loader stays above all elements
          }}
        >
          <CircularProgress color="primary" />
        </Box>
      )}
      <OrderSuccessPopup
        open={popupOpen}
        onClose={() => setPopupOpen(false)}
        orderDetails={orderDetails}
      />
      {showPopup && <Successpopup Heading={heading} Success={Success} onClose={closePopup} Message={msg} />}

    </div>

  );
}

export default BuySell;
