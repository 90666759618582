import React from 'react'
import { Dialog, DialogContent, Typography, Box, Button, IconButton } from '@mui/material'
import { CancelOutlined } from '@mui/icons-material'
import successgif from '../../../style/Gif/succes.gif'
import errorgif from '../../../style/Gif/errors.gif'

interface SuccesspopupProps {
    onClose: () => void; // Function prop to handle closing the popup
    Success: boolean
    Message: String
    Heading: String
}
const Successpopup: React.FC<SuccesspopupProps> = ({ onClose, Success, Heading, Message }) => {
    const [isDialogOpen, setDialogOpen] = React.useState<boolean>(true);

    return (
        !Success ?
            <Dialog open={true} onClose={onClose} PaperProps={{ style: { background: '#141414', border: '1px solid #FFFFFF33', zIndex: '999', width: '440px', height: 'fit-content', padding: '0px', display: 'flex', alignItems: 'center', justifyContent: 'center', borderRadius: '20px', overflow: 'hidden' } }}>
                <DialogContent
                    style={{
                        textAlign: 'center',
                        backgroundColor: '#000',
                        color: '#fff',
                        width: '100%',
                        padding: '20px',
                        overflow: 'hidden',
                        position: 'relative',
                    }}
                >
                    <IconButton sx={{ mb: '24px', display: 'flex', justifyContent: 'end', width: '100%' }} onClick={onClose}>
                        <CancelOutlined sx={{ height: '36px', width: '36px' }} />
                    </IconButton>
                    {/* <svg width="250" height="250" viewBox="0 0 250 250" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M150.701 101.646H64.4707V245.116H185.606V136.552L150.701 101.646Z" fill="#67CCD1" />
                        <path d="M97.04 132.469C97.04 121.93 98.0464 111.626 99.9673 101.647H64.4707V245.117H142.59C114.391 215.955 97.04 176.239 97.04 132.469Z" fill="#27A2CC" />
                        <path d="M158.138 222.468H124.307L115.762 216.536L110.525 205.316L111.389 199.234H158.138V222.468Z" fill="#FD7DA0" />
                        <path d="M111.388 199.234H91.9395V222.468H124.307C119.394 215.125 115.066 207.358 111.388 199.234Z" fill="#DB4B86" />
                        <path d="M150.701 136.552H185.606L150.701 101.646V136.552Z" fill="white" />
                        <path d="M85.5745 52.0667L111.767 12.0559C118.03 2.49048 132.048 2.49048 138.31 12.0559L164.503 52.0667C171.409 62.6165 163.841 76.6169 151.232 76.6169H98.8455C86.2366 76.6174 78.6678 62.6165 85.5745 52.0667Z" fill="#FD7DA0" />
                        <path d="M197.045 50.5166H194.049C191.353 50.5166 189.166 52.7026 189.166 55.3994C189.166 58.0962 191.353 60.2822 194.049 60.2822H197.045C199.741 60.2822 201.928 58.0962 201.928 55.3994C201.928 52.7026 199.742 50.5166 197.045 50.5166Z" fill="#FD6050" />
                        <path d="M183.133 33.9288C184.383 33.9288 185.632 33.4517 186.585 32.4986L189.2 29.8843C191.107 27.9776 191.107 24.8858 189.2 22.9791C187.293 21.0723 184.202 21.0718 182.295 22.9791L179.68 25.5933C177.773 27.5001 177.773 30.5919 179.68 32.4986C180.634 33.4522 181.883 33.9288 183.133 33.9288Z" fill="#FD6050" />
                        <path d="M186.585 78.3002C184.679 76.3934 181.587 76.3934 179.68 78.3002C177.773 80.2069 177.773 83.2987 179.68 85.2054L182.295 87.8197C183.248 88.7733 184.497 89.2499 185.747 89.2499C186.996 89.2499 188.246 88.7728 189.2 87.8197C191.106 85.913 191.106 82.8212 189.199 80.9144L186.585 78.3002Z" fill="#FD6050" />
                        <path d="M60.834 55.3994C60.834 52.7026 58.6479 50.5166 55.9512 50.5166H52.9551C50.2583 50.5166 48.0723 52.7026 48.0723 55.3994C48.0723 58.0962 50.2583 60.2822 52.9551 60.2822H55.9512C58.6479 60.2822 60.834 58.0962 60.834 55.3994Z" fill="#FD6050" />
                        <path d="M63.4144 78.3002L60.8002 80.9144C58.8934 82.8212 58.8934 85.913 60.8002 87.8202C61.7538 88.7738 63.0033 89.2504 64.2528 89.2504C65.5023 89.2504 66.7523 88.7738 67.7054 87.8202L70.3197 85.2059C72.2264 83.2992 72.2264 80.2074 70.3197 78.3002C68.413 76.3934 65.3212 76.3934 63.4144 78.3002Z" fill="#FD6050" />
                        <path d="M67.7059 22.9789C65.7992 21.0721 62.7074 21.0721 60.8002 22.9789C58.8934 24.8856 58.8934 27.9774 60.8002 29.8846L63.4144 32.4989C64.368 33.4525 65.6176 33.9291 66.8671 33.9291C68.1166 33.9291 69.3666 33.4525 70.3197 32.4989C72.2264 30.5922 72.2264 27.5004 70.3197 25.5931L67.7059 22.9789Z" fill="#FD6050" />
                        <path d="M189.059 133.1L154.154 98.1948C153.238 97.2793 151.996 96.7646 150.701 96.7646H64.4707C61.7739 96.7646 59.5879 98.9507 59.5879 101.647V245.117C59.5879 247.814 61.7739 250 64.4707 250H185.606C188.302 250 190.489 247.814 190.489 245.117V136.553C190.488 135.257 189.974 134.016 189.059 133.1ZM180.723 240.234H69.353V106.53H148.678L180.723 138.575V240.234Z" fill="#052A75" />
                        <path d="M98.8446 81.501H151.231C158.975 81.501 165.798 77.4336 169.48 70.6211C173.163 63.8081 172.829 55.8726 168.587 49.3936L142.394 9.38281C138.548 3.50732 132.06 0 125.037 0C118.015 0 111.527 3.50781 107.681 9.38281L81.4876 49.3931C77.2459 55.8726 76.912 63.8081 80.5951 70.6206C84.2782 77.4336 91.1004 81.501 98.8446 81.501ZM115.852 14.7314C117.918 11.5757 121.266 9.76562 125.038 9.76562C128.81 9.76562 132.159 11.5757 134.224 14.7314L160.417 54.7422C162.662 58.1714 162.839 62.3716 160.89 65.9771C158.941 69.5825 155.33 71.7354 151.231 71.7354H98.8446C94.7459 71.7354 91.1351 69.5825 89.1859 65.9771C87.2367 62.3716 87.4134 58.1714 89.6585 54.7422L115.852 14.7314Z" fill="#052A75" />
                        <path d="M158.138 174.187H91.9395C89.2427 174.187 87.0566 176.373 87.0566 179.069C87.0566 181.766 89.2427 183.952 91.9395 183.952H158.138C160.834 183.952 163.021 181.766 163.021 179.069C163.021 176.373 160.834 174.187 158.138 174.187Z" fill="white" />
                        <path d="M91.9395 163.788H158.138C160.834 163.788 163.021 161.602 163.021 158.905C163.021 156.208 160.834 154.022 158.138 154.022H91.9395C89.2427 154.022 87.0566 156.208 87.0566 158.905C87.0566 161.602 89.2427 163.788 91.9395 163.788Z" fill="white" />
                        <path d="M91.9395 143.623H118.868C121.564 143.623 123.75 141.437 123.75 138.74C123.75 136.043 121.564 133.857 118.868 133.857H91.9395C89.2427 133.857 87.0566 136.043 87.0566 138.74C87.0566 141.437 89.2427 143.623 91.9395 143.623Z" fill="white" />
                        <path d="M125.039 45.1807C127.736 45.1807 129.922 42.9946 129.922 40.2978V31.8516C129.922 29.1548 127.736 26.9688 125.039 26.9688C122.342 26.9688 120.156 29.1548 120.156 31.8516V40.2978C120.156 42.9946 122.342 45.1807 125.039 45.1807Z" fill="white" />
                        <path d="M125.039 52.0898C123.002 52.0898 121.139 53.4062 120.448 55.3198C119.758 57.231 120.359 59.4414 121.929 60.7354C123.503 62.0317 125.766 62.208 127.523 61.1743C129.273 60.144 130.221 58.0527 129.834 56.0571C129.394 53.7871 127.356 52.0898 125.039 52.0898Z" fill="white" />
                    </svg> */}
                    <img src={errorgif} height='250px' width='250px' />
                    <Typography mt='30px' className='text-white' sx={{ textWrap: 'nowrap' }} fontSize='24px' fontWeight='bold'>{Heading ? Heading : 'Error'}</Typography>
                    <Typography mb='30px' className='text-secndary'>{Message === '' ? "Something went wrong, Please try again." : Message}</Typography>
                    <Button onClick={onClose} className='btn btn-primary' sx={{ padding: '13px 168px' }}>Try Again</Button>

                </DialogContent>

            </Dialog>
            :
            <Dialog open={true} onClose={onClose} PaperProps={{ style: { background: '#141414', border: '1px solid #FFFFFF33', zIndex: '999', width: '440px', height: 'fit-content', padding: '0px', display: 'flex', alignItems: 'center', justifyContent: 'center', borderRadius: '20px', overflow: 'hidden' } }}>
                <DialogContent
                    style={{
                        textAlign: 'center',
                        backgroundColor: '#000',
                        color: '#fff',
                        width: '100%',
                        padding: '20px',
                        overflow: 'hidden',
                        position: 'relative',
                    }}
                >
                    <IconButton sx={{ mb: '24px', display: 'flex', justifyContent: 'end', width: '100%' }} onClick={onClose}>
                        <CancelOutlined sx={{ height: '36px', width: '36px' }} />
                    </IconButton>
                    <img src={successgif} height='250px' width='250px' alt="" />
                    <Typography mt='30px' className='text-white' sx={{ textWrap: 'nowrap' }} fontSize='24px' fontWeight='bold'>{Heading ? Heading : 'Success'}</Typography>
                    <Typography mb='30px' className='text-secndary'>{Message === '' ? "Something went wrong, Please try again." : Message}</Typography>
                    <Button onClick={onClose} className='btn btn-success' sx={{ color: 'black', fontWeight: 'bold', '&:hover': { color: 'black', fontWeight: 'bold' }, padding: '13px 168px' }}>Finish</Button>

                </DialogContent>

            </Dialog>
    )
}

export default Successpopup
