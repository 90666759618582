import { Box, Button, CircularProgress, Dialog, DialogContent, Typography } from '@mui/material'
import React, { useEffect, useRef, useState } from 'react'
import Cropper from 'react-easy-crop'
import Axios from '../../../config/axios';
import { UrlConstant } from '../../../commom/UrlConstant';
import Successpopup from '../SharedComponent/Successpopup';

const ImageCropPopup = ({ isOpen, onClose, user }) => {

    const fileInputRef = useRef<HTMLInputElement>(null);

    const [selectedImage, setSelectedImage] = useState<string | ArrayBuffer | null>(null);
    const [profilePic, setProfilePic] = useState<any>(null);
    const [imageSrc, setImageSrc] = useState<any>(null); // Original image source
    const [crop, setCrop] = useState({ x: 0, y: 0 }); // Crop position
    const [zoom, setZoom] = useState(1); // Zoom level
    const [croppedAreaPixels, setCroppedAreaPixels] = useState<any>(null); // Pixel coordinates of cropped area
    const [croppedImage, setCroppedImage] = useState<any>(null); // Cropped image preview
    const [uploadLoading, setUploadLoading] = useState(false);
    const [showPopup, setShowPopup] = useState(false);
    const [Success, setSuccess] = useState(false);
    const [msg, setmsg] = useState('');
    const [heading, setheading] = useState('');

    const closePopup = () => {
        setShowPopup(false);
    };


    const uploadProfilePic = async () => {
        setUploadLoading(true);
        const formData = new FormData();

        if (croppedImage !== null) {
            const mimeType = croppedImage.split(';')[0].split(':')[1]; // Get the MIME type from the base64 string
            const blob = base64ToBlob(croppedImage, mimeType);

            const file = new File([blob], "profilePic.jpg", { type: mimeType });

            formData.append("file", file);
        } else if (profilePic !== null && profilePic instanceof File) {
            formData.append("file", profilePic);
        }



        try {
            const response = await Axios.post(
                UrlConstant.Auth.UploadProfilePic,
                formData,
            );
            const resData = response.data;
            console.log(resData, "afdfasdf")
            if (resData.result === true) {
                const updatedUSer = { ...user, profile_pic: resData?.data?.profile_pic };
                localStorage.setItem("user", JSON.stringify(updatedUSer));
                onClosePopUp()
                setUploadLoading(false);
                window.location.reload()
            } else {
                setUploadLoading(false);
                setheading("ERROR")
                setShowPopup(true)
                setmsg(resData?.msg ?? "Uploading Failed")

            }
        } catch (error: any) {
            setUploadLoading(false);
            setShowPopup(true)
            setheading('Error')
            setmsg(error?.response?.data?.msg ?? "Uploading Failed")

        }
    };

    const base64ToBlob = (base64: string, mimeType: string) => {
        const byteString = atob(base64.split(",")[1]);
        const arrayBuffer = new ArrayBuffer(byteString.length);
        const uint8Array = new Uint8Array(arrayBuffer);

        for (let i = 0; i < byteString.length; i++) {
            uint8Array[i] = byteString.charCodeAt(i);
        }

        return new Blob([uint8Array], { type: mimeType });
    };

    const getCroppedImg = async (imageSrc, pixelCrop, zoom) => {
        const canvas = document.createElement("canvas");
        const ctx = canvas.getContext("2d");

        if (!ctx) throw new Error("Canvas context could not be created");

        const image = new Image();
        image.src = imageSrc;

        return new Promise((resolve, reject) => {
            image.onload = () => {
                try {
                    const scaleX = image.naturalWidth / image.width;
                    const scaleY = image.naturalHeight / image.height;

                    canvas.width = pixelCrop.width;
                    canvas.height = pixelCrop.height;

                    ctx.drawImage(
                        image,
                        pixelCrop.x * scaleX,
                        pixelCrop.y * scaleY,
                        pixelCrop.width * scaleX,
                        pixelCrop.height * scaleY,
                        0,
                        0,
                        pixelCrop.width,
                        pixelCrop.height
                    );

                    const croppedImage = canvas.toDataURL("image/jpeg");
                    resolve(croppedImage);
                } catch (error: any) {
                    reject(new Error("Crop failed: " + error.message));
                }
            };

            image.onerror = () => {
                reject(new Error("Failed to load image for cropping"));
            };
        });
    };

    const handleCrop = async () => {
        try {
            const croppedImg = await getCroppedImg(imageSrc, croppedAreaPixels, zoom);
            console.log("Croppedllllllllllimage", croppedImg);
            setCroppedImage(croppedImg);
        } catch (error: any) {
            console.error("Crop failed:", error.message);
        }
    };

    useEffect(() => {
        if (profilePic) {
            handleCrop()
        }
    }, [croppedAreaPixels])

    const handleFileSelect = (file) => {
        setProfilePic(file);
        if (file) {
            const reader = new FileReader();
            reader.onloadend = () => {
                setImageSrc(reader.result); // Set the preview image
                setSelectedImage(reader.result);
            };

            reader.readAsDataURL(file); // Read the file as a data URL
            if (fileInputRef.current) {
                fileInputRef.current.value = "";
            }
        }
    };

    const handleDrop = (event) => {
        event.preventDefault();
        const file = event.dataTransfer.files[0];
        if (file) {
            handleFileSelect(file);
        }
    };

    const handleDragOver = (event) => {
        event.preventDefault();
    };

    const handleUploadClick = () => {
        fileInputRef.current?.click();
    };

    const onCropComplete = (croppedArea, croppedAreaPixels) => {
        setCroppedAreaPixels(croppedAreaPixels);
    };

    const onClosePopUp = () => {
        onClose();
        setImageSrc(null);
        setProfilePic(null);
        setCrop({ x: 0, y: 0 });
        setZoom(1);
    }

    return (
        <>
            <Dialog open={isOpen} onClose={onClosePopUp} PaperProps={{ style: { background: '#141414', border: '1px solid #FFFFFF33', zIndex: '999', width: '440px', height: 'fit-content', padding: '0px', display: 'flex', alignItems: 'center', justifyContent: 'center', borderRadius: '20px', overflow: 'hidden' } }}>
                <DialogContent
                    style={{
                        textAlign: 'center',
                        backgroundColor: '#000',
                        color: '#fff',
                        width: '100%',
                        padding: '20px',
                        // overflow: 'hidden',
                        position: 'relative',
                    }}
                >
                    <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                        <h1 style={{ fontSize: '20px', fontWeight: 'bold', margin: '0px' }}>Crop Image</h1>
                        <span style={{ cursor: 'pointer' }} onClick={onClosePopUp}>X</span>
                    </div>
                    <Typography mt={5} mb="10px" className="text-secondary">
                        Profile Image
                    </Typography>
                    <Box
                        width="100%"
                        height="70%"
                        padding='30px'
                        mb={5}
                        sx={{
                            border: "2px dashed #FFFFFF33", // Dashed border
                            display: "flex",
                            flexDirection: "column",
                            alignItems: "center",
                            justifyContent: "center",
                            borderRadius: "16px", // Rounded corners
                            backgroundColor: "transparent",
                            color: "#FFFFFF",
                            position: "relative",
                        }}
                        onDrop={handleDrop}
                        onDragOver={handleDragOver}
                    >
                        <input
                            ref={fileInputRef}
                            type="file"
                            accept="image/png, image/jpeg"
                            style={{ display: "none" }}
                            onChange={(e) => handleFileSelect(e.target.files?.[0])}
                        />

                        {!imageSrc ? (
                            <Box
                                component="div"
                                onClick={handleUploadClick}
                                sx={{
                                    cursor: "pointer",
                                    textAlign: "center",
                                    display: "flex",
                                    flexDirection: "column",
                                    alignItems: "center",
                                    justifyContent: "center",
                                }}
                            >
                                <Typography variant="subtitle1" gutterBottom>
                                    <span>Upload</span> or Drag here
                                </Typography>
                                <Typography variant="caption" sx={{ color: "#A5A5A5" }}>
                                    .PNG or .JPG supported
                                </Typography>
                                <Typography variant="caption" sx={{ color: "#A5A5A5" }}>
                                    Max 2 MB
                                </Typography>
                            </Box>
                        ) : (
                            <Box sx={{ position: "relative", width: "100%", height: 300 }}>

                                {/* <CloseIcon onClick={handleRemoveImage} style={{ position: "absolute", top: 8, right: 8, backgroundColor: "rgba(255, 255, 255, 0.8)", zIndex: 10, cursor: "pointer" }} /> */}

                                <Cropper
                                    image={imageSrc}
                                    crop={crop}
                                    zoom={zoom}
                                    // aspect={1} // Aspect ratio 1:1
                                    onCropChange={setCrop}
                                    onZoomChange={setZoom}
                                    onCropComplete={onCropComplete} // Automatically handle cropping
                                />

                            </Box>

                        )}
                    </Box>

                    <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', gap: '10px' }}>
                        <Button
                            style={{
                                padding: '10px 20px',
                                backgroundColor: '#FF4500',
                                color: '#fff', border: 'none',
                                borderRadius: '10px',
                                cursor: 'pointer',
                                width: '100px'
                            }}
                            onClick={(e) => {
                                e.preventDefault(); // Prevent form submission
                                uploadProfilePic();
                            }}
                            disabled={uploadLoading ? true : !croppedImage && !profilePic}
                        >

                            {uploadLoading
                                ?
                                <CircularProgress size={20} style={{ color: "Highlight", marginLeft: 10 }} />
                                : "Upload"
                            }
                        </Button>
                        <Button
                            style={{
                                padding: '10px 20px',
                                backgroundColor: '#FF4500',
                                color: '#fff', border: 'none',
                                borderRadius: '10px', cursor: 'pointer'
                            }}
                            onClick={onClosePopUp}
                        >
                            Cancel
                        </Button>
                    </div>
                </DialogContent>
            </Dialog>
            {showPopup && <Successpopup Heading={heading} Success={Success} onClose={closePopup} Message={msg} />}

        </>

    )
}

export default ImageCropPopup