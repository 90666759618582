import {
  Avatar,
  Box,
  CircularProgress,
  Container,
  FormControl,
  MenuItem,
  ToggleButton,
  ToggleButtonGroup,
  Typography,
} from "@mui/material";
import Select, { SelectChangeEvent } from "@mui/material/Select";
import React, { useEffect, useState } from "react";
import { Card, Row } from "react-bootstrap";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { Constant } from "../../../commom/Constants";
import { UrlConstant } from "../../../commom/UrlConstant";
import Axios from "../../../config/axios";
import { endLoading, startLoading } from "../../../Redux/loadingSlice";
import Yourinvestment from "../Cards/Yourinvestment";
import { CommonDataTable } from "../SharedComponent/CommonDataTable";
import RightSideSection from "../SharedComponent/RightSideSection";
import { formatPrice } from "../../../Utils/utils";
function MyPicks() {
  const dispatch = useDispatch();
  const navigation = useNavigate();
  const [page, setPage] = useState(1);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [search, setSearch] = useState("");
  const [totalRows] = useState(10);

  const [loading, setLoading] = useState(false);
  const [myPicsType, setMyPicsType] = React.useState<any>("STOCK");
  const [myPicsPreference, setMyPicsPreference] = React.useState<any>("BUY");
  const [myPicsOpt, setMyPicsOpt] = React.useState<String>("Live");
  const [allMyPickData, setAllMyPickData] = React.useState<any>([]);
  const [myPickData, setMyPickData] = React.useState<any>([]);



  // ** Table Headers

  const columns = [
    {
      name: "Company",
      selector: (row) => [row.displayName],
      cell: (row) => (
        <div
          onClick={() => {
            navigateToDetail(row);
          }}
          className="stock-label"
          style={{
            display: "flex",
            alignItems: "center",
            gap: "5px",
            cursor: "pointer",
          }}
        >
          <Avatar
            className="stock-icon"
            alt={row.symbol}
            src={row.branding?.logo_url ?? row.branding?.icon_url}
            sx={{
              width: 30,
              height: 30,
              backgroundColor: "white",
              objectFit: "fill",
            }}
          />
          <span
            style={{
              whiteSpace: "nowrap",
              overflow: "hidden",
              textOverflow: "ellipsis",
            }}
          >
            {row.displayName}
          </span>
        </div>
      ),
      sortable: true,
      center: false,
      width: "350px",
      //width: "auto",
    },
    {
      name: "Market Price",
      selector: (row) => row.stockPrice,
      cell: (row) => (
        <div>
          {row.stockPrice === null ? "-" : `$${row.stockPrice?.toFixed(2)}`}
        </div>
      ),
      // width: "130px",
      width: "auto",
      sortable: true,
      center: true,
    },
    {
      name: "Change",
      selector: (row) => row.change,
      cell: (row) => (
        <div
          style={{
            color: row.change === 0 ? '' : row.change > 0 ? '#4edf6d' : 'red',
          }}
        >
          {row.change === 0 ? (
            <span style={{ color: "white" }}>N/A</span>
          ) : row.change > 0 ? (
            <span style={{ color: "green" }}>+${formatPrice(row.change)}</span>
          ) : (
            <span style={{ color: "red" }}>-${formatPrice(Math.abs(row.change))}</span>
          )}
        </div>
      ),
      sortable: true,
      width: "100px",
      center: true,
    },

    {
      name: "Percentage",
      selector: (row) => row.change_percent,
      sortable: true,
      cell: (row) => <div style={{ color: row.change == 0 ? "" : row.change_percent > 0 ? "#4edf6d" : "red" }}>
        {row.change_percent === 0 ? (
          <span style={{ color: "white" }}>N/A</span>
        ) : row.change_percent > 0 ? (
          <span style={{ color: "green" }}>+{formatPrice(row.change_percent)}%</span>
        ) : (
          <span style={{ color: "red" }}>{formatPrice(row.change_percent)}%</span>
        )}        </div>,
      width: "150px",

      center: true,
    },
  ];
  const navigateToDetail = async (data) => {
    //stockdetail
    navigation("/stockdetail", {
      state: {
        symbol: data.symbol,
        icon: data.branding?.logo_url ?? data.branding?.icon_url,
        title: "My Picks"
      },
    });
  };

  const handleChangemypicks1 = (event: SelectChangeEvent) => {
    setMyPicsType(event.target.value as string);
  };
  const handleChangeMyPicksPreference = (event: SelectChangeEvent) => {
    setMyPicsPreference(event.target.value as string);
  };
  const handleChangeMyPicksFilter = (
    event: React.MouseEvent<HTMLElement>,
    newAlignment: string
  ) => {
    setMyPicsOpt(newAlignment);
  };



  const getMyPickList = async () => {
    dispatch(startLoading());
    let URL;

    if (myPicsOpt == "Live") {
      URL = UrlConstant.MyPicks.GetMyPicksLive.replace("{0}", myPicsType)
    } else {
      URL = UrlConstant.MyPicks.GetMyPicksHistory.replace("{0}", myPicsType)
    }

    try {
      const response = await Axios.get(URL);
      const resData = response.data;
      if (resData.result === true) {
        console.log(resData, "MYYYYYYYYYYYYYYYYYYYY")
        setAllMyPickData(resData.data);
        // getSelectedData(resData.data, category, buyOrSell.toString());
      }
    } catch (error: any) {
      console.error("ERRR : ", error.response.status);
    } finally {
      dispatch(endLoading());
    }
  };

  useEffect(() => {
    getMyPickList()
  }, [myPicsOpt, myPicsType])

  useEffect(() => {
    const data = allMyPickData.length && allMyPickData.filter((item: any) => item.recommedationType === myPicsPreference)
    setMyPickData(data)
  }, [allMyPickData, myPicsPreference])


  return (
    <Box className="bg-color" display="flex">
      <Container
        maxWidth={false}
        className="bg-color p-3 px-5"
        style={{
          minHeight: "calc(100vh -  71px - 143px)",
          // height:'100vh',
          width: "70%",
        }}
      >
        <Typography
          className="text-secondary"
          sx={{ marginBottom: "20px", paddingTop: '30px' }}
        >
          {" "}
          <span
            onClick={() => navigation(-1)}
            style={{ color: "#4A7AF2", cursor: "pointer" }}
          >
            Dashboard{" "}
          </span>{" "}
          / My Picks
        </Typography>
        <Box mb={5}>
          <Box
            display="flex"
            justifyContent="space-between"
            alignItems="center"
          >
            <Box display="flex" gap={2} alignItems="center" mb={1}>
              <Typography
                className="text-white"
                mb={0}
                style={{ fontSize: "1.5rem" }}
              >
                My Picks
              </Typography>

              <FormControl>
                <Select
                  labelId="demo-simple-select-label"
                  id="demo-simple-select2"
                  value={myPicsType}
                  className="text-white"
                  sx={{
                    // color:'!important',
                    borderRadius: "9px !important",
                    padding: "0px !important",
                    '.MuiSvgIcon-root': {
                      color: 'white !important'
                    }

                  }}
                  onChange={handleChangemypicks1}
                >
                  <MenuItem value="STOCK">Stock</MenuItem>
                  <MenuItem value="ETF" selected>
                    ETF
                  </MenuItem>
                </Select>
              </FormControl>
              <FormControl>
                <Select
                  labelId="demo-simple-select-label"
                  id="demo-simple-select2"
                  value={myPicsPreference}
                  className="text-white"
                  sx={{
                    // color:'!important',
                    borderRadius: "9px !important",
                    padding: "0px !important",
                    '.MuiSvgIcon-root': {
                      color: 'white !important'
                    }
                  }}
                  onChange={handleChangeMyPicksPreference}
                >
                  <MenuItem value="BUY">Buy</MenuItem>
                  <MenuItem value="SELL" selected>
                    Sell
                  </MenuItem>
                </Select>
              </FormControl>

            </Box>
          </Box>

          <Box mb={1}>
            <ToggleButtonGroup
              // className="border-white"
              // color="primary"
              value={myPicsOpt}
              exclusive
              onChange={handleChangeMyPicksFilter}
              aria-label="Platform"
            >
              <ToggleButton value="Live">
                Live
              </ToggleButton>
              <ToggleButton value="History" >
                History
              </ToggleButton>

            </ToggleButtonGroup>
          </Box>
        </Box>
        <Container
          style={{ width: "100%", left: "0", margin: "0" }}
          className="p-0"
        >
          <Row className="dark-tbl no-border p-0">
            <Card className="custom-card overflow-hidden bg-color w-100">
              <Card.Body className="ps-12 p-2">
                <div>
                  {loading ? (
                    <Box
                      display="flex"
                      justifyContent="center"
                      alignItems="center"
                      height="200px"
                      bgcolor='transparent'
                    >
                      <CircularProgress color="primary" />
                    </Box>
                  ) : (
                    <CommonDataTable
                      setPage={setPage}
                      setRowsPerPage={setRowsPerPage}
                      data={myPickData}
                      totalRows={totalRows}
                      columns={columns}
                      setSearch={setSearch}
                    />
                  )}
                </div>
              </Card.Body>
            </Card>
          </Row>
        </Container>

        {/* <Container style={{ width: "70%", left: "0", margin: "0" }}>
          <TotalFunds />
        </Container> */}
      </Container>
      <Container style={{ width: "40%", paddingTop: "20px" }}>
        <RightSideSection />
      </Container>
    </Box>
  );
}
export default MyPicks;
