import React from "react";
import {
  Box,
  Typography,
  Switch,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Divider,
  Button,
  Tooltip
} from "@mui/material";
import { Formik, Form, Field } from "formik";
import RangeInput from "../../InputFields/RangeInput";

const PreferencesForm = ({ fieldsList, preferenceDetails, handleSubmit, fieldsData }) => {
  // Initial values for the form
  const initialValues = fieldsList.reduce((acc, item) => {
    // Start with the common fields
    const fieldData: any = {
      isActive: preferenceDetails?.[item.fieldKey]?.isActive || false,
      priority: preferenceDetails?.[item.fieldKey]?.priority,
    };

    // Conditionally add 'value' for Boolean type fields
    if (item.fieldType === "BOOLEAN") {
      fieldData.value = preferenceDetails?.[item.fieldKey]?.value;
    }

    // Conditionally add 'min' and 'max' for RANGE type fields
    if (item.fieldType === "RANGE") {
      fieldData.min = preferenceDetails?.[item.fieldKey]?.min || 0;
      fieldData.max = preferenceDetails?.[item.fieldKey]?.max;
    }

    // Assign the fieldData to the accumulator
    acc[item.fieldKey] = fieldData;
    return acc;
  }, {});


  // Custom validation function
  const validate = (values) => {
    const errors: any = {};

    fieldsList.forEach((item) => {
      const fieldErrors: any = {};

      // Only validate other fields if isActive is true
      if (values[item.fieldKey].isActive) {
        // Validate RANGE fields
        if (item.fieldType === "RANGE") {
          if (values[item.fieldKey].min > values[item.fieldKey].max) {
            fieldErrors.min = "Min value cannot be greater than Max value";
            fieldErrors.max = "Max value cannot be less than Min value";
          }

          if (values[item.fieldKey].min === undefined || values[item.fieldKey].min === "") {
            fieldErrors.min = "Min value is required";
          }

          if (values[item.fieldKey].max === undefined || values[item.fieldKey].max === "") {
            fieldErrors.max = "Max value is required";
          }
        }

        // Validate priority
        if (!values[item.fieldKey].priority) {
          fieldErrors.priority = "Priority is required";
        }

        // Validate BOOLEAN fields
        if (item.fieldType === "BOOLEAN" && !values[item.fieldKey].value) {
          fieldErrors.value = "Value is required";
        }
      }

      if (Object.keys(fieldErrors).length > 0) {
        errors[item.fieldKey] = fieldErrors;
      }
    });

    return errors;
  };


  return (
    <Formik
      initialValues={initialValues}
      validate={validate}
      onSubmit={(values) => handleSubmit(values)}
    >
      {({ values, errors, handleChange, setFieldValue }: any) => {
        return (
          <Form>
            <Box mt={0}>
              {fieldsList?.map((item) => (
                <React.Fragment key={item.fieldKey}>
                  <Box sx={{ display: "flex", flexDirection: "column", gap: 2, marginBottom: 5, width: "100%" }}>
                    <Box sx={{ display: "flex", alignItems: "center", justifyContent: "space-between", marginBottom: "10px" }}>
                      <Box sx={{ display: "flex", alignItems: "center" }}>
                        <Typography variant="h6" sx={{ fontWeight: "600", color: "white", marginRight: "5px" }}>
                          {item.fieldName}
                        </Typography>
                        <Tooltip title={item.fieldDescription || "No description available"}>
                          <i
                            className="fa-solid fa-info-circle"
                            style={{
                              color: "#FFFFFF",
                              marginLeft: "5px",
                              fontSize: "1rem",
                            }}
                          ></i>
                        </Tooltip>
                      </Box>

                      <Switch
                        sx={{
                          "& .MuiSwitch-switchBase.Mui-checked": { color: "#f2674a" },
                          "& .MuiSwitch-switchBase.Mui-checked + .MuiSwitch-track": { bgcolor: "#f2674a" },
                          "& .MuiSwitch-track": { bgcolor: "rgba(255,255,255,0.4)" },
                        }}
                        checked={values[item.fieldKey]?.isActive}
                        onChange={(e) => setFieldValue(`${item.fieldKey}.isActive`, e.target.checked)}
                        name="isActive"
                      />
                    </Box>

                    <Box sx={{ display: "flex", gap: 3, width: "100%" }}>
                      {item.fieldType === "RANGE" ? (
                        <>
                          <RangeInput
                            min={item.min}
                            max={item.max}
                            minValue={values[item.fieldKey]?.min}
                            maxValue={values[item.fieldKey]?.max}
                            steps={item.steps}
                            onRangeChange={(e) => {
                              const { name, value } = e.target;
                              setFieldValue(`${item.fieldKey}.${name}`, value);
                            }}
                            errors={errors}
                            item={item}
                          />

                        </>
                      ) : item.fieldType === "BOOLEAN" ? (
                        <FormControl fullWidth sx={{ flex: 1 }}>
                          <InputLabel shrink={false} sx={{ color: "gray !important", backgroundColor: "bg-color" }}>
                            Value <sup style={{ color: "red" }}>*</sup>
                          </InputLabel>
                          <Select
                            name="value"
                            sx={{
                              backgroundColor: "bg-color",
                              borderRadius: 1,
                              height: "52px",
                              "& .MuiSelect-icon": { color: "white" },
                              "& .MuiInputBase-root": { color: "white" },
                            }}
                            value={values[item.fieldKey]?.value ? "YES" : "NO"}
                            onChange={(e) => setFieldValue(`${item.fieldKey}.value`, e.target.value === "YES")}
                          >
                            <MenuItem value="YES">Yes</MenuItem>
                            <MenuItem value="NO">No</MenuItem>
                          </Select>
                        </FormControl>
                      ) : null}

                      <FormControl fullWidth sx={{ flex: 1 }}>
                        <InputLabel shrink={false} sx={{ color: "gray !important", backgroundColor: "bg-color" }}>
                          Priority <sup style={{ color: "red" }}>*</sup>
                        </InputLabel>
                        <Select
                          name="priority"
                          sx={{
                            backgroundColor: "bg-color",
                            borderRadius: 1,
                            height: "52px",
                            "& .MuiSelect-icon": { color: "white" },
                            "& .MuiInputBase-root": { color: "white" },
                          }}
                          value={values[item.fieldKey]?.priority}
                          onChange={(e) => setFieldValue(`${item.fieldKey}.priority`, e.target.value)}
                        >
                          <MenuItem value="LOW">Low</MenuItem>
                          <MenuItem value="MEDIUM">Medium</MenuItem>
                          <MenuItem value="HIGH">High</MenuItem>
                        </Select>
                        {errors[item.fieldKey]?.priority && (
                          <Typography
                            variant="body2"
                            sx={{
                              color: "red",
                              fontSize: "0.875rem",
                              marginTop: "5px",
                            }}
                          >
                            {errors[item.fieldKey]?.priority}
                          </Typography>
                        )}
                      </FormControl>

                    </Box>


                  </Box>

                  <Box
                    sx={{
                      height: "2px",
                      background:
                        "repeating-linear-gradient(to right, #FFFFFF1A 0px, #FFFFFF1A 20px, transparent 20px, transparent 40px)",
                      margin: "30px 0",
                    }}
                  />
                </React.Fragment>
              ))}

              <Box position='absolute' bottom='0px' width='100%' mb='20px'>
                <Divider sx={{ border: '1px solid #FFFFFF33' }}></Divider>
                <Box sx={{ display: "flex", justifyContent: "flex-end", paddingTop: "10px", paddingRight: "20px" }}>
                  <Button
                    className="btn-primary"
                    type="submit"
                    sx={{
                      borderRadius: "50px",
                      textTransform: "none",
                      fontSize: "16px",
                      padding: "13px 50px",
                      marginLeft: "auto !important",
                    }}
                  >
                    Save & Next
                  </Button>
                </Box>
              </Box>
            </Box>
          </Form>
        );
      }}
    </Formik>

  );
};

export default PreferencesForm;
