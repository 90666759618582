import { Dialog, DialogContent, Typography } from '@mui/material'
import { Box, IconButton, Divider,CircularProgress} from '@mui/material';
import React, { FC } from 'react'
import img from "../../../style/images/accountimg.png"
import { Button } from 'react-bootstrap';
import { useLocation, useNavigate, } from 'react-router-dom';

interface details {
    onClose: () => void;
    uploaddocumnet: boolean
    applicationId: string
}

const Brokrageaccoun: FC<any> = ({ onClose,applicationId, uploaddocument}) => {
    const navigate = useNavigate();
    
    return (
        <Dialog
            open={true}
            onClose={onClose}
            aria-labelledby="dialog-title"
            aria-describedby="dialog-description"
            // className="bg-color"

            PaperProps={{
                sx: {
                    // boxShadow: "0px 0px 30px rgba(170, 170, 170, 0.7)",
                    borderRadius: "20px",
                    minWidth: '700px',
                    // width:'700px',
                    border: '1px solid #FFFFFF1A'
                },
            }}
        >
            <Box
                sx={{
                    display: "flex",
                    justifyContent: "end",
                    marginTop: "20px",
                    marginRight: "30px",
                }}
            >
                <IconButton
                    onClick={onClose}
                >
                    <svg
                        width="36"
                        height="36"
                        viewBox="0 0 36 36"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                    >
                        <path
                            d="M18 33C26.25 33 33 26.25 33 18C33 9.75 26.25 3 18 3C9.75 3 3 9.75 3 18C3 26.25 9.75 33 18 33Z"
                            stroke="white"
                            stroke-width="2.25"
                            stroke-linecap="round"
                            stroke-linejoin="round"
                        />
                        <path
                            d="M13.7549 22.2449L22.2449 13.7549"
                            stroke="white"
                            stroke-width="2.25"
                            stroke-linecap="round"
                            stroke-linejoin="round"
                        />
                        <path
                            d="M22.2449 22.2449L13.7549 13.7549"
                            stroke="white"
                            stroke-width="2.25"
                            stroke-linecap="round"
                            stroke-linejoin="round"
                        />
                    </svg>
                </IconButton>
            </Box>
            <DialogContent
                sx={{ padding: "0px 40px 30px 40px", display: 'flex', flexDirection: 'column', gap: '20px', alignItems: 'center' }}
            >

                <img src={img} height='254px' width='250px' alt="img" />
                <Typography className='text-white' fontWeight='bold' fontSize='24px'>Thank you for submitting your Application.</Typography>
                <Typography className='text-blue' fontSize='24px'>Your Application ID:</Typography>
                <Typography className='text-white' fontWeight='bold' fontSize='36px'>{applicationId}</Typography>
                <Typography textAlign='center' className='text-white' fontSize='16px'>TradingBlock is currently reviewing your application. If no additional information is required, your account will be activated shortly. We will notify you if additional information is required.</Typography>
                {uploaddocument &&
                    <>
                    <hr style={{border:'1px solid white', width:'100%'}} />                
                    <Typography className='text-white' fontWeight='bold' fontSize='24px'>What’s Next?</Typography>
                    <Typography className='text-white' fontSize='16px'>Documents are required to complete your application.</Typography>
                    <Button className='btn btn-primary' 
                    onClick={()=>{navigate("/documentupload");}}
                    style={{padding:'13px 18px'}}>Upload Documents</Button>

                    </>
                }
            </DialogContent>
        </Dialog>
    )
}

export default Brokrageaccoun
