import React, { useState, useEffect } from 'react'
import { Container, Button, Box, Typography, Divider, FormControl, TextField, ToggleButtonGroup, ToggleButton, unstable_createBreakpoints, IconButton } from '@mui/material'

import UserStocks from '../Tabels/MyStocks';
import Axios from '../../../config/axios';
import { UrlConstant } from '../../../commom/UrlConstant';
import { getUserFromLocalStorage } from "../../../Utils/utils";
import { BorderColor, Logout, SearchOffOutlined, SearchOutlined } from '@mui/icons-material';
import debounce from 'lodash.debounce';
import { login, logout } from '../../../Redux/authSlice';
import MyFollowers from './MyFollowers';
import MyStocks from '../Tabels/MyStocks';
import { NavLink, useNavigate, useLocation } from 'react-router-dom';
import MyFollowing from './MyFollowing';
import { useDispatch } from "react-redux";
import EditIcon from '@mui/icons-material/Edit';
import ImageCropPopup from '../Popups/ImageCropPopup';
import ConfirmPopup from '../Popups/ConfirmPopup';


interface UserData {
  profile_pic: string;
  firstName: string;
  lastName: string;
  email: string;
  totalFollowers: number;
  totalFollowing: number;
}
interface SearchResult {
  _id: string;
  profile_pic: string;
  firstName: string;
  lastName: string;
  totalFollowers: number;
  totalFollowing: number;
  isFollow: boolean;
}
function MyProfile() {
  // let user = getUserFromLocalStorage();
  const [user, setUser] = useState(getUserFromLocalStorage());
  const userId = user?._id;
  const [userData, setUserData] = useState<UserData | null>(null);
  const [selectedbutton, setselectedbutton] = React.useState('Stock');
  const [searchResults, setSearchResults] = useState<SearchResult[]>([]);
  const [searchText, setSearchText] = useState("");
  const [followers, setfollowers] = useState(false);
  const [following, setfollowing] = useState(false);
  const [openImageCropPopup, setOpenImageCropPopup] = useState(false);
  const [openconfirm, setOpenconfirm] = useState(false);
  const navigation = useNavigate();
  const dispatch = useDispatch();
  const handleClick = (value: string) => {
    setfollowers(false)
    setfollowing(false)
    setselectedbutton(value);

  };

  const handleSearchChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const value = event.target.value;
    setSearchText(value);
    setfollowers(false);

    if (value === '') {
      setSearchResults([]);  // Clear the search results when the input is empty
    }
  };

  const handleFollow = (userId: string) => {
    Axios.post(UrlConstant.Profile.userFollow.replace("{0}", userId))
      .then(() => {
        updateFollowStatus(userId, true);  // Update follow status after follow
        window.location.reload();
      })
      .catch((error) => {
        console.error('Error following user:', error);
      });
  };

  const handleUnfollow = (userId: string) => {
    Axios.post(UrlConstant.Profile.userUnfollow.replace("{0}", userId))
      .then(() => {
        updateFollowStatus(userId, false);
        window.location.reload();
      })
      .catch((error) => {
        console.error('Error unfollowing user:', error);
      });
  };
  const updateFollowStatus = (userId: string, isFollow: boolean) => {
    setSearchResults((prevResults) =>
      prevResults.map((user) =>
        user._id === userId ? { ...user, isFollow } : user
      )
    );
  };

  useEffect(() => {
    Axios.post(UrlConstant.Profile.getProfile.replace("{0}", userId))
      .then(response => {
        setUserData(response.data.data);
      })
      .catch(error => {
        console.error('Error fetching user data:', error);
      });
  }, []);
  const debouncedSearch = debounce((searchTerm: string) => {
    if (selectedbutton === 'Searchuser' && searchTerm.length >= 3) {
      Axios.get(UrlConstant.Profile.searchUser.replace("{0}", searchTerm))
        .then(response => {
          setSearchResults(response.data.data);
        })
        .catch(error => {
          console.error('Error fetching search results:', error);
        });
    }
  }, 500);
  useEffect(() => {
    debouncedSearch(searchText);
    return () => {
      debouncedSearch.cancel();
    };
  }, [searchText]);

  const logOut = () => {
    dispatch(logout());
    navigation("/login");
  };

  const handleImageUpload = (event) => {
    const file = event.target.files[0];
    if (file) {
      // onProfilePicChange(file);
    }
  };
  // want to open modal on edit profile pic
  const onEditProfilePic = () => {
    setOpenImageCropPopup(true);
  }

  const onClosePopUp = () => {
    setUser(getUserFromLocalStorage())
    setOpenImageCropPopup(false);
  }
  const opendialog= async()=>{
    await setOpenconfirm(true);
    console.log(openconfirm)
  }
  const onClosedialog=()=>{
    setOpenconfirm(false)
  }

  return (
    <>
      <Container maxWidth={false} className="bg-color" style={{ minHeight: 'calc(100vh - 131px - 143px)', padding: '30px', display: 'flex', gap: '20px', width: '100%' }}>
        <Box height='auto' minHeight='calc(100vh - 131px - 143px)' className='card' width='30%' padding='20px'>
          <Box height='fit-content' width='100%' padding='30px' borderRadius='20px' gap='20px' className='bg-color' display='flex' flexDirection='column' alignItems='center'>
            {/* <Box component='img' src={user ? user.profile_pic : '/static/images/avatar/1.jpg'} border='1px solid white' height='100px' width='100px' borderRadius='50%' /> */}
            <Box position="relative" display="inline-block">
              <Box
                component="img"
                src={
                  user && user.profile_pic
                    ? `${user.profile_pic}?t=${new Date().getTime()}`
                    : '/static/images/avatar/1.jpg'
                }

                border="1px solid white"
                height="100px"
                width="100px"
                borderRadius="50%"
                sx={{ objectFit: 'cover' }}
              />
              <IconButton
                component="label"
                sx={{
                  position: 'absolute',
                  bottom: 0,
                  right: 0,
                  bgcolor: 'white',
                  borderRadius: '50%',
                  padding: '5px',
                  background: "#F2674A",
                  '&:hover': {
                    bgcolor: '#F2674A',
                  },
                }}
                onClick={onEditProfilePic}
              >
                {/* <input
                  type="file"
                  hidden
                  accept="image/*"
                  onChange={handleImageUpload}
                /> */}
                <EditIcon />
              </IconButton>
            </Box>
            <Box>
              <Typography className='text-white' lineHeight='1.2' textAlign='center' fontSize='24px' fontWeight='bold'> {userData ? `${userData.firstName} ${userData.lastName}` : 'Loading...'}</Typography>
              <Typography className='text-secondary' fontSize='16px'> {userData ? `${userData.email}` : 'Loading...'}</Typography>
            </Box>
            <Box display='flex' padding='20px 44px' alignItems='center' justifyContent='space-between' width='100%' bgcolor='#4A7AF21A' borderRadius='20px'>
              <Button sx={{ '&:hover': { backgroundColor: 'transparent' } }} onClick={() => { setfollowers(true); setselectedbutton(''); setSearchResults([]); }}>
                <Box>
                  <Typography sx={{ color: '#4A7AF2', fontSize: '16px', fontWeight: 'bold', textAlign: 'center' }}>{userData?.totalFollowers || 0}</Typography>
                  <Typography sx={{ color: '#4A7AF2', fontSize: '16px', fontWeight: 'bold', textTransform: 'none' }}>Followers</Typography>
                </Box>
              </Button>
              <Divider sx={{ rotate: '90deg', width: '50px', color: '#4A7AF2', border: '1px solid #4A7AF2' }}></Divider>
              <Button sx={{ '&:hover': { backgroundColor: 'transparent' } }} onClick={() => { setfollowing(true); setfollowers(false); setselectedbutton(''); setSearchResults([]); }}>

                <Box>
                  <Typography sx={{ color: '#4A7AF2', fontSize: '16px', fontWeight: 'bold', textAlign: 'center' }}>{userData?.totalFollowing || 0}</Typography>
                  <Typography sx={{ color: '#4A7AF2', fontSize: '16px', fontWeight: 'bold', textTransform: 'none' }}>Following</Typography>
                </Box>
              </Button>
            </Box>
          </Box>
          <Box mt='20px' width='100%'>
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                gap: "20px",
              }}
            >
              <Button
                onClick={() => { handleClick("Stock"); setSearchResults([]); }}

                sx={{
                  display: 'flex',
                  flexDirection: 'row',
                  alignItems: 'center',
                  borderRadius: "10px !important",
                  justifyContent: "start !important",
                  width: "100%",
                  border: selectedbutton === "Stock" ? "1px solid #F2674A" : "1px solid #ffffff33",
                  bgcolor: selectedbutton === "Stock" ? "#F2674A1A" : "#ffffff1a",
                  color: selectedbutton === "Stock" ? "white" : "white",
                  "&:hover": {
                    border: "1px solid #F2674A",
                    bgcolor: "#F2674A1A",
                  },
                }}
              >
                <svg style={{ marginRight: '10px' }} width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path fill-rule="evenodd" clip-rule="evenodd" d="M7.00001 3.75C5.20507 3.75 3.75 5.20507 3.75 7.00002V17C3.75 18.7949 5.20507 20.25 7.00001 20.25H17C18.7949 20.25 20.25 18.7949 20.25 17V7.00002C20.25 5.20507 18.7949 3.75 17 3.75H7.00001ZM2.25 7.00002C2.25 4.37665 4.37665 2.25 7.00001 2.25H17C19.6233 2.25 21.75 4.37665 21.75 7.00002V17C21.75 19.6234 19.6233 21.75 17 21.75H7.00001C4.37665 21.75 2.25 19.6234 2.25 17V7.00002Z" fill={selectedbutton === 'Stock' ? "#F2674A" : "white"} />
                  <path fill-rule="evenodd" clip-rule="evenodd" d="M14.5161 8.15265C14.8406 8.41005 14.895 8.88183 14.6376 9.20637L10.4576 14.4764C10.2151 14.7821 9.77871 14.8508 9.45399 14.6343L6.76394 12.8409L3.58197 16.9308C3.32762 17.2577 2.8564 17.3166 2.52948 17.0622C2.20256 16.8079 2.14373 16.3367 2.39808 16.0097L6.00806 11.3697C6.24932 11.0596 6.68906 10.9883 7.016 11.2062L9.71151 13.0032L13.4624 8.27421C13.7198 7.94967 14.1916 7.89525 14.5161 8.15265Z" fill={selectedbutton === 'Stock' ? "#F2674A" : "white"} />
                  <path fill-rule="evenodd" clip-rule="evenodd" d="M10.5701 8.61914C10.5701 8.2049 10.9058 7.86914 11.3201 7.86914H14.48C14.8943 7.86914 15.23 8.2049 15.23 8.61914V11.7691C15.23 12.1833 14.8943 12.5191 14.48 12.5191C14.0658 12.5191 13.73 12.1833 13.73 11.7691V9.36914H11.3201C10.9058 9.36914 10.5701 9.03332 10.5701 8.61914Z" fill={selectedbutton === 'Stock' ? "#F2674A" : "white"} />
                </svg>
                Stock
              </Button>

              <Button
                onClick={() => { handleClick("ETF"); setSearchResults([]); }}
                sx={{
                  display: 'flex',
                  flexDirection: 'row',
                  alignItems: 'center',
                  borderRadius: "10px !important",
                  justifyContent: "start !important",
                  width: "100%",
                  border: selectedbutton === "ETF" ? "1px solid #F2674A" : "1px solid #ffffff33",
                  bgcolor: selectedbutton === "ETF" ? "#F2674A1A" : "#ffffff1a",
                  color: selectedbutton === "ETF" ? "white" : "white",
                  "&:hover": {
                    border: "1px solid #F2674A",
                    bgcolor: "#F2674A1A",
                  },
                }}
              >
                <svg style={{ marginRight: '10px' }} width="24" height="25" viewBox="0 0 24 25" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M12.37 2.43695L21.37 6.03693C21.72 6.17693 22 6.59692 22 6.96692V10.2869C22 10.8369 21.55 11.2869 21 11.2869H3C2.45 11.2869 2 10.8369 2 10.2869V6.96692C2 6.59692 2.28 6.17693 2.63 6.03693L11.63 2.43695C11.83 2.35695 12.17 2.35695 12.37 2.43695Z" stroke={selectedbutton === "ETF" ? "#F2674A" : "white"}
                    stroke-width="1.5" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round" />
                  <path d="M22 22.2871H2V19.2871C2 18.7371 2.45 18.2871 3 18.2871H21C21.55 18.2871 22 18.7371 22 19.2871V22.2871Z" stroke={selectedbutton === "ETF" ? "#F2674A" : "white"}
                    stroke-width="1.5" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round" />
                  <path d="M4 18.2871V11.2871" stroke={selectedbutton === "ETF" ? "#F2674A" : "white"}
                    stroke-width="1.5" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round" />
                  <path d="M8 18.2871V11.2871" stroke={selectedbutton === "ETF" ? "#F2674A" : "white"}
                    stroke-width="1.5" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round" />
                  <path d="M12 18.2871V11.2871" stroke={selectedbutton === "ETF" ? "#F2674A" : "white"}
                    stroke-width="1.5" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round" />
                  <path d="M16 18.2871V11.2871" stroke={selectedbutton === "ETF" ? "#F2674A" : "white"}
                    stroke-width="1.5" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round" />
                  <path d="M20 18.2871V11.2871" stroke={selectedbutton === "ETF" ? "#F2674A" : "white"}
                    stroke-width="1.5" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round" />
                  <path d="M1 22.2871H23" stroke={selectedbutton === "ETF" ? "#F2674A" : "white"}
                    stroke-width="1.5" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round" />
                  <path d="M12 8.78711C12.8284 8.78711 13.5 8.11554 13.5 7.28711C13.5 6.45868 12.8284 5.78711 12 5.78711C11.1716 5.78711 10.5 6.45868 10.5 7.28711C10.5 8.11554 11.1716 8.78711 12 8.78711Z" stroke={selectedbutton === "ETF" ? "#F2674A" : "white"}
                    stroke-width="1.5" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round" />
                </svg>
                ETF
              </Button>

              <Button
                onClick={() => handleClick("Searchuser")}
                // className='card'
                sx={{
                  display: 'flex',
                  flexDirection: 'row',
                  alignItems: 'center',
                  borderRadius: "10px !important",
                  justifyContent: "start !important",
                  width: "100%",
                  border: selectedbutton === "Searchuser" ? "1px solid #F2674A" : "1px solid #ffffff33",
                  bgcolor: selectedbutton === "Searchuser" ? "#F2674A1A" : "#ffffff1a",
                  color: selectedbutton === "Searchuser" ? "white" : "white",
                  "&:hover": {
                    border: "1px solid #F2674A",
                    bgcolor: "#F2674A1A",
                  },
                }}
              >
                <svg style={{ marginRight: '10px' }} width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path fill-rule="evenodd" clip-rule="evenodd" d="M11.5 21C6.25329 21 2 16.7467 2 11.5C2 6.25329 6.25329 2 11.5 2C16.7467 2 21 6.25329 21 11.5C21 16.7467 16.7467 21 11.5 21Z" stroke={selectedbutton === "Searchuser" ? "#F2674A" : "white"}
                    stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                  <path d="M22 22L20 20" stroke={selectedbutton === "Searchuser" ? "#F2674A" : "white"}
                    stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                </svg>
                Search User
              </Button>
              <Button
              variant='outlined'
              // onClick={setshowPopup(true)}
              onClick={opendialog}
                // className='card'
                sx={{
                  gap: '10px',
                  display: 'flex',
                  flexDirection: 'row',
                  alignItems: 'center',
                  borderRadius: "50px !important",
                  width: "fit-content",
                  mx:'auto',
                  padding:'13px 39px',
                  border: selectedbutton === "Logoutuser" ? "2px solid #F2674A" : "1px solid red",
                  bgcolor: selectedbutton === "Logoutuser" ? "#F2674A1A" : "transparent",
                  color: selectedbutton === "Logoutuser" ? "white" : "red",
                  fontWeight:'bold'
                  // "&:hover": {
                  //   border: "1px solid #F2674A",
                  //   bgcolor: "#F2674A1A",
                  // },
                }}
              >
                <Logout sx={{color:'red'}}/>Log Out
              </Button>
            </Box>
          </Box>
        </Box>
        {selectedbutton !== 'Searchuser' ?
          <>
            <Box height='fit-content'  overflow='hidden'  className='card' padding='20px' width='70%'>
              {followers ?
                <>
                  <Typography fontSize='24px' className='text-white'>My Followers</Typography>
                  <Box height='100%'>
                    {/* {selectedbutton === 'Stock' ? <UserStocks /> : ''} */}
                    <MyFollowers />
                  </Box>
                </>
                : following ?
                  <>
                    <Typography fontSize='24px' className='text-white'>My Followings</Typography>
                    <Box height='100%'>
                      {/* {selectedbutton === 'Stock' ? <UserStocks /> : ''} */}
                      <MyFollowing />
                    </Box>
                  </>
                  :
                  <>
                    <Typography fontSize='24px' mb='20px' className='text-white'>{userData ? `${userData.firstName} ${userData.lastName}` : 'Loading...'} -  {selectedbutton === 'Stock' ? "Stock" : "ETF"}</Typography>
                    <Box height='fit-content' >
                      {/* {selectedbutton === 'Stock' ? <UserStocks /> : ''} */}
                      <MyStocks type={selectedbutton as 'Stock' | 'ETF'} />
                    </Box>
                  </>
              }
            </Box>
          </>
          :
          <Box width='70%'>
            <Box className='card' mb='20px' height='fit-content' padding='20px' width='100%'>
              <Box mb='20px' display='flex' alignItems='center' justifyContent='space-between'>
                <Typography fontSize='24px' className='text-white'>Search User</Typography>
                <Typography className='text-secondary'>Total Result: {searchResults.length} Users</Typography>
              </Box>
              <FormControl sx={{ width: '450px' }}>
                <TextField
                  onChange={handleSearchChange}
                  id="outlined-suffix-shrink"
                  variant="outlined"
                  slotProps={{
                    input: {
                      startAdornment: (
                        <svg style={{ marginRight: '10px' }} width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                          <rect width="24" height="24" stroke="#141414" stroke-opacity="0.1" />
                          <path fill-rule="evenodd" clip-rule="evenodd" d="M11.5 21C6.25329 21 2 16.7467 2 11.5C2 6.25329 6.25329 2 11.5 2C16.7467 2 21 6.25329 21 11.5C21 16.7467 16.7467 21 11.5 21Z" stroke="white" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                          <path d="M22 22L20 20" stroke="white" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                        </svg>
                      ),
                    },
                  }}
                  sx={{
                    '& .MuiOutlinedInput-root': {
                      borderRadius: '10px !important'
                    }
                  }}
                />
              </FormControl>
            </Box>
            {searchResults.length > 0 &&
              <Box width='100%' className='card' height='calc(100vh - 149px - 200px)'>
                <Box height='100%' padding='20px' overflow='auto' display='flex' alignItems='start' gap='20px' flexWrap='wrap'>
                  {searchResults.map((result, index) => (
                    <NavLink to='/userprofile' style={{ width: '48%' }} state={{ userId: result._id }}>
                      <Box key={index} className='card' width='100%' padding='20px' display='flex' flexDirection='row' alignItems='center' justifyContent='space-between'>
                        <Box display='flex' alignItems='center' gap='10px'>
                          <Box component='img' src={result.profile_pic || ''} border='1px solid white' height='42px' width='42px' borderRadius='50%' />
                          <Box>
                            <Typography fontSize='16px' className='text-white' fontWeight='bold'>{result.firstName} {result.lastName}</Typography>
                          </Box>
                        </Box>
                        <Button
                          // variant={`${result.isFollow && 'outlined !importnat'}`}
                          variant={result.isFollow ? 'outlined' : 'contained'}
                          onClick={() => (result.isFollow ? handleUnfollow(result._id) : handleFollow(result._id))}
                          className={`btn ${result.isFollow ? '' : 'btn-primary'}`}
                          style={{
                            border: result.isFollow ? '' : 'none',
                            padding: result.isFollow ? '7px 16px' : '7px 25px',
                            backgroundColor: result.isFollow ? '' : '#007bff',  // Change color for Follow/Unfollow
                            borderColor: result.isFollow ? '#F2674A' : '',
                            color: result.isFollow ? '#F2674A' : 'white',
                            // borderColor:'red'
                          }}
                        >
                          {result.isFollow ? 'Unfollow' : 'Follow'}  {/* Text change based on isFollow */}
                        </Button>
                      </Box>
                    </NavLink>
                  ))}
                </Box>
              </Box>
            }
          </Box>
        }
      </Container>
      <ImageCropPopup isOpen={openImageCropPopup} onClose={onClosePopUp} user={user} />
      {openconfirm ? <ConfirmPopup onClose={onClosedialog} confirm_process={logOut}/> : null}
    </>
  )
}

export default MyProfile

function updateFollowStatus(userId: string, arg1: boolean) {
  throw new Error('Function not implemented.');
}

