import { Grid } from "@material-ui/core";
import React, { useEffect, useState } from 'react'
import { Container, Typography, Box, Card, CardContent, Avatar, ToggleButton, ToggleButtonGroup, CircularProgress, FormControl, Select, MenuItem, SelectChangeEvent, RadioGroup, FormControlLabel, Radio, Divider } from "@mui/material";
import { UrlConstant } from "../../../commom/UrlConstant";
import Axios from "../../../config/axios";
import DonutChart from "../charts/DonutChart";
import { formatPrice, getUserFromLocalStorage } from "../../../Utils/utils";
import PortfolioTabel from "../Tabels/PortfolioTabel";
import Footer from "../Layout/Footer";
import LinechartMiniPortfolio from "../charts/LinechartMiniPortfolio";

function Portfolio() {

  const user = getUserFromLocalStorage();


  const [loading, setLoading] = useState<any>(false);
  const [tradingInfo, setTradingInfo] = useState<any>();
  const [investmentChart, setInvestmentChart] = useState<any>()
  const [stockChart, setStockChart] = useState<any>()
  const [stockChartLabel, setStockLabel] = useState<any>()
  const [portfolioData, setPortfolioData] = useState<any>()
  const [portfolioListData, setPortfolioListData] = useState<any>()
  const [stockChartType, setStockChartType] = React.useState<any>('stockChart');

  const [page, setPage] = useState(1);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [search, setSearch] = useState("");
  const [selectedType, setSelectedType] = useState("Stock");

  // investmentChart
  const [investmentData, setInvestmentData] = useState<any>()
  const [investmentFilter, setInvestmentFilter] = useState<any>("1W")
  const [changeInvestmentData, setChangeInvestmentData] = useState<any>()
  const [changePercentageInvestmentData, setChangePercentageInvestmentData] = useState<any>()
  const [accountValue, setAccountValue] = useState<any>()
  const tabOptions = [{ label: "1W", value: "1W" }, { label: "1M", value: "1M" }, { label: "3M", value: "3M" }, { label: "6M", value: "6M" }, { label: "1Y", value: "1Y" }, { label: "5Y", value: "5Y" }]


  const handleRadioChange = (event) => {
    setSelectedType(event.target.value);
  };

  const handleChange = (event: SelectChangeEvent) => {
    setStockChartType(event.target.value as string);
  };

  const convertToPercentage = (data: number[]) => {
    const total = data.reduce((acc, value) => acc + value, 0);
    if (total === 0) return data.map(() => 0); // Handle edge case when total is 0.

    const rawPercentages = data.map((value) => (value / total) * 100);

    const adjustedPercentages = rawPercentages.map((percentage) => (percentage > 0 && percentage < 1 ? 1 : Math.trunc(percentage)));

    return adjustedPercentages;
  };


  const fetchTradingBalance = async () => {
    // check-trading-balance
    setLoading(true)
    const data = {
      "accountId": user?.tradingblock_application_id
    }
    try {
      const response = await Axios.post(
        UrlConstant.BuySell.GetTradingBalance, data)

      setTradingInfo(response.data?.data?.Payload)
    } catch (error: any) {
      console.error("ERRR : ", error);
    } finally {
      setLoading(false)
    }
  }
  const fetchPortfolioData = async () => {
    setLoading(true)
    const data = {
      "accountId": user?.tradingblock_application_id
    }
    try {
      const response = await Axios.post(
        UrlConstant.Portfolio.GetPortfolio, data)

      if (response.data?.data?.length !== 0) {
        setPortfolioData(response.data?.data || [])
        setPortfolioListData(response.data?.data?.entries || [])
      }
    } catch (error: any) {
      console.error("ERRR : ", error);
    } finally {
      setLoading(false)
    }
  }

  const fetchInvestmentChart = async () => {
    setLoading(true)
    try {
      const response = await Axios.get(
        UrlConstant.InvestmentChart.getInvestmentChart.replace("{0}", investmentFilter))

      if (response.data?.status) {
        const chartData = response.data?.data?.responseData?.map((item) => {
          return {
            x: new Date(item.recordDate),
            y: item.accountValue
          }
        })
        setInvestmentData(chartData)
        setChangeInvestmentData(response.data?.data?.change)
        setChangePercentageInvestmentData(response.data?.data?.changePercentage)
        setAccountValue(response.data?.data?.accountValue)
      }
    } catch (error: any) {
      console.error("ERRR : ", error);
    } finally {
      setLoading(false)
    }
  }



  useEffect(() => {
    fetchTradingBalance()
    fetchPortfolioData()
  }, [])

  useEffect(() => {
    fetchInvestmentChart()
  }, [investmentFilter])

  useEffect(() => {
    const cash = tradingInfo?.Balances?.CashBalance || 0
    const stock = portfolioData?.stockChart?.stockTotal || 0
    const ETF = portfolioData?.mainChart?.chartData?.ETF || 0
    const data = [cash, stock, ETF]
    const labels = ["Cash", "Stocks", "ETFs"]
    const finalDataPer = data?.length && convertToPercentage(data)
    const finalData = labels?.map((item, i) => {
      return {
        label: item,
        value: data[i],
        percentage: finalDataPer[i]
      }
    })
    console.log(data, finalDataPer, 'finalData')
    setInvestmentChart(finalData)
  }, [tradingInfo, portfolioData])

  useEffect(() => {
    let data = portfolioData?.[stockChartType]?.chartDataTest

    const finalData = data?.map((item) => item.amount)
    const finalDataLabel = data?.map((item) => item.symbol)
    const finalDataPer = finalData?.length && convertToPercentage(finalData)
    const newData = finalDataLabel?.map((item, i) => {
      return {
        label: item,
        value: finalData[i],
        percentage: finalDataPer[i]
      }
    })
    setStockChart(newData)
    setStockLabel(finalDataLabel)
  }, [selectedType, stockChartType, portfolioData])

  useEffect(() => {
    if (selectedType == "ETF") {
      setStockChartType("etfChart")
    } else {
      setStockChartType("stockChart")
    }
  }, [selectedType])




  return <>
    <Container maxWidth={false} className="bg-color" style={{ display: 'flex', flexDirection: 'column', minHeight: 'calc(100vh - 71px - 143px)', height: 'fit-content' }}>
      <Box padding='30px' flexGrow='1'>
        {loading && (
          <Box
            position="fixed"
            top={0}
            left={0}
            width="100vw"
            height="100vh"
            bgcolor="rgba(0, 0, 0, 0.5)" // Semi-transparent black background
            display="flex"
            justifyContent="center"
            alignItems="center"
            style={{
              backdropFilter: "blur(5px)", // Blur effect
              zIndex: 9999, // Ensures the loader stays above all elements
            }}
          >
            <CircularProgress color="primary" />
          </Box>
        )}
        <Typography variant="body1" fontSize='24px' fontWeight={400} className="text-white">Portfolio Details</Typography>
        <Box display="flex" flexDirection={{ xs: "column", md: "row" }} gap="10px" my="20px" alignItems="stretch">
          {/* First Card with Chart */}
          <Box flex={1} display="flex">
            <Card className="card" style={{ width: "100%", height: "100%", padding: "12px 10px" }}>
              <CardContent className="text-white" style={{ display: "flex", flexDirection: "column", height: "100%" }}>
                <Grid container spacing={2}>
                  {/* Title */}
                  <Grid item xs={12}>
                    <Typography variant="h6" className="text-white" style={{ textAlign: "left" }}>
                      Investment Portfolio
                    </Typography>
                  </Grid>

                  {/* Chart */}
                  <Grid item xs={12} style={{ display: "flex", justifyContent: "center" }}>
                    <DonutChart
                      series={investmentChart?.length ? investmentChart : []}
                      labels={["Stocks", "Cash", "ETFs"]}
                    />
                  </Grid>
                </Grid>
              </CardContent>
            </Card>
          </Box>

          {/* Second Card with Chart */}
          <Box flex={1} display="flex">
            <Card className="card" style={{ width: "100%", height: "100%", padding: "12px 10px" }}>
              <CardContent className="text-white" style={{ display: "flex", flexDirection: "column", height: "100%" }}>
                <Grid container spacing={2} >
                  {/* Title */}
                  {/* <Grid item xs={12}> */}
                  <Box display={"flex"} alignItems={"center"} justifyContent={"space-between"} width={"100%"} >
                    <Box>
                      <Typography variant="h6" className="text-white" style={{ textAlign: "left" }}>
                        Investment
                      </Typography>
                      {/* acoount value  */}
                      <Typography variant="h6" className="text-white" style={{ textAlign: "left" }}>
                        ${formatPrice(accountValue)}
                      </Typography>

                    </Box>
                    <Box display="flex" flexDirection={"column"} alignItems="flex-end" justifyContent="end" >
                      {
                        changeInvestmentData > 0 ?
                          <span style={{ color: "#4edf6d" }}>{` + ${formatPrice(changeInvestmentData)} (${formatPrice(changePercentageInvestmentData)}%)`}</span>
                          :
                          changeInvestmentData === 0 ?
                            <span style={{ color: "#4edf6d" }}>{changeInvestmentData}</span>
                            :
                            <span style={{ color: "#ff2a2a" }}>{` ${formatPrice(changeInvestmentData)} (${formatPrice(changePercentageInvestmentData)}%)`}</span>
                      }

                      <Typography className="text-secondary" fontSize="14px" fontWeight="bold" textAlign="center">Past {investmentFilter}</Typography>

                    </Box>
                  </Box>
                  {/* </Grid> */}

                  {/* Chart */}
                  <Grid item xs={12} style={{ display: "flex", justifyContent: "center" }}>
                    <Box>
                      <LinechartMiniPortfolio height={"275px"} data={investmentData || []} lineColor={
                        changeInvestmentData > 0 ? "#4edf6d" : changeInvestmentData === 0 ? "#4edf6d" : "#ff2a2a"
                      } />
                      <Box display="flex" justifyContent="center" alignItems="center">
                        {tabOptions?.map((item, index) => (
                          //   want to make the tabs like chip and make it dynamic with bg color and text color with active
                          <Box
                            key={index}
                            p={"3px 12px"}
                            m={1} style={{ cursor: 'pointer' }}
                            onClick={() => setInvestmentFilter(item.value)}
                            sx={{
                              backgroundColor: investmentFilter === item.value ? "#F2674A" : 'transparent',
                              color: 'white',
                              border: investmentFilter === item.value ? "" : '1px solid #FFFFFF80',
                              borderRadius: '50px',
                              fontSize: '14px'
                            }}
                          >
                            {item.label}
                          </Box>
                        ))}
                      </Box>

                    </Box>
                  </Grid>
                </Grid>
              </CardContent>
            </Card>
          </Box>

          {/* Third Card with Chart */}
          <Box flex={1} display="flex">
            <Card className="card" style={{ width: "100%", height: "100%", padding: "12px 10px" }}>

              <CardContent className="text-white" style={{ display: "flex", flexDirection: "column", height: "100%" }}>
                <Grid container spacing={2}>
                  {/* Title */}
                  <Grid item xs={12}>
                    <Box display={"flex"} alignItems={"center"} justifyContent={"space-between"}>
                      <RadioGroup
                        row
                        value={selectedType}
                        onChange={handleRadioChange}
                        sx={{ display: "flex", alignItems: "center" }}
                      >
                        <FormControlLabel
                          value="Stock"
                          control={<Radio size="small" sx={{ color: "#3F8EFC" }} />}
                          label={<Typography sx={{ color: "#FFFFFF", fontSize: "14px" }}>Stock</Typography>}
                        />
                        <FormControlLabel
                          value="ETF"
                          control={<Radio size="small" sx={{ color: "#3F8EFC" }} />}
                          label={<Typography sx={{ color: "#FFFFFF", fontSize: "14px" }}>ETF</Typography>}
                        />
                      </RadioGroup>

                      {
                        selectedType == "Stock" ?
                          <FormControl >
                            <Select
                              labelId="demo-simple-select-label"
                              id="demo-simple-select"
                              value={stockChartType}
                              className="text-white"
                              sx={{
                                borderRadius: '9px !important',
                                padding: '0px !important',

                              }}
                              onChange={handleChange}
                            >
                              <MenuItem value={`stockChart`}>
                                Assets
                              </MenuItem>
                              <MenuItem value={`sectorChart`}>
                                Sector
                              </MenuItem>
                            </Select>
                          </FormControl>
                          :
                          <FormControl >
                            <Select
                              labelId="demo-simple-select-label"
                              id="demo-simple-select"
                              value={stockChartType}
                              className="text-white"
                              sx={{
                                borderRadius: '9px !important',
                                padding: '0px !important',

                              }}
                              onChange={handleChange}
                            >
                              <MenuItem value={`etfChart`}>
                                Assets
                              </MenuItem>
                              <MenuItem value={`categoryChart`}>
                                Category
                              </MenuItem>
                            </Select>
                          </FormControl>
                      }

                    </Box>
                  </Grid>

                  {/* Chart */}
                  <Grid item xs={12} style={{ display: "flex", justifyContent: "center" }}>
                    {selectedType === "Stock" ?
                      <DonutChart
                        series={stockChart?.length ? stockChart : []}
                        labels={stockChartLabel?.length ? stockChartLabel : []}
                      />
                      :
                      <DonutChart
                        series={stockChart?.length ? stockChart : []}
                        labels={stockChartLabel?.length ? stockChartLabel : []}
                      />
                    }
                  </Grid>
                </Grid>
              </CardContent>
            </Card>
          </Box>
        </Box>
        <PortfolioTabel
          portfolioListData={portfolioListData}
        />
      </Box>
    </Container>

  </>;
}

export default Portfolio;
