import "bootstrap/dist/css/bootstrap.min.css";
import React from "react";
import { Provider } from "react-redux";
import { Navigate, useLocation, Route, Routes, useNavigate } from "react-router-dom";
import { Box } from "@mui/material";
// import "./App.css";
import "../src/style/style.css";
import Biometric from "./components/auth/Biometric";
import Createpin from "./components/auth/Createpin";
import ForgotPassword from "./components/auth/ForgotPassword";
import Login from "./components/auth/Login";
import Loginwithpin from "./components/auth/Loginwithpin";
import Otp from "./components/auth/Otp";
import Reset from "./components/auth/Reset";
import Signup from "./components/auth/Signup";
import Terms from "./components/auth/Terms";
import Uploadimage from "./components/auth/Uploadimage";
import MainDashboard from "./components/common/Layout/MainDashboard";
import PrivateRoute from "./config/PrivateRoute";
import PublicRoute from "./config/PublicRoute";
import store from "./Redux/store";
import { getUserFromLocalStorage } from "./Utils/utils";
import LeftSide from "./components/common/Layout/LeftSide";

function App() {
  const navigation = useNavigate();
  const location = useLocation();
  const AuthRoutes = [
    "/signup",
    "/login",
    "/forgot",
    "/otp",
    "/createpin",
    "/reset",
    "/terms",
    "/biometric",
    "/uploadimage",
    "/loginwithpin"

  ];
  const isAuth2 = AuthRoutes.some((route) => location.pathname.startsWith(route));
  React.useEffect(() => {
    const pinVerified = sessionStorage.getItem("pinVerified");
    const user = getUserFromLocalStorage();
    if (!user?.isVerify) {
      navigation("/otp")
    } else if (
      (pinVerified === undefined || pinVerified === null) &&
      user &&
      user !== null &&
      user.IsPINCreated === true
    ) {
      navigation("/loginwithpin");
    } else if (user && user !== null && user.acceptDisclosure === false) {
      navigation("/terms", { state: user });
    } else if (user && user !== null && user.IsPINCreated === false) {
      navigation("/createpin");
    }
  }, []);

  return (
    // <Box>

    <Provider store={store}>
      {/* <Box maxWidth={{lg:'1300px',md:'100%'}} sx={{mx:'auto'}}> */}
      <Box display="flex" width="100%" height='fit-content'>
        {/* LeftSide component occupies 50% width */}
        {isAuth2 && (
          <Box
            width="50%"
            display="flex"
            alignItems="center"
          >
            <LeftSide />
          </Box>
        )}
        <Box width={isAuth2 ? "50%" : "100%"}>

          <Routes>
            {/* Public Routes */}
            <Route path="/signup" element={<PublicRoute element={Signup} />} />
            <Route path="/login" element={<PublicRoute element={Login} />} />
            <Route
              path="/forgot"
              element={<PublicRoute element={ForgotPassword} />}
            />
            {/* <Route path="/otp" element={<PublicRoute element={Otp} />} /> */}
            <Route path="/otp" element={<Otp />} />
            <Route path="/createpin" element={<Createpin />} />
            <Route path="/reset" element={<PublicRoute element={Reset} />} />
            <Route path="/terms" element={<Terms />} />

            <Route
              path="/biometric"
              element={<PublicRoute element={Biometric} />}
            />

            {/* Protected MainDashboard Routes with Pin Verification */}
            {/* <Route
          path="/createpin"
          element={<PrivateRoute element={Createpin} />}
        /> */}
            {/* <Route path="/terms" element={<PrivateRoute element={Terms} />} /> */}

            <Route
              path="/uploadimage"
              element={<PrivateRoute element={Uploadimage} />}
            />

            <Route
              path="/loginwithpin"
              element={<PrivateRoute element={Loginwithpin} />}
            />

            <Route
              path="/*"
              element={<PrivateRoute element={MainDashboard} />}
            />

            <Route
              path="*"
              element={<Navigate to="/dashboard" replace />}
            />
          </Routes>
          {/* </Box> */}
        </Box>
      </Box>
    </Provider>
    // </Box>
  );
}

export default App;
