import React, { Component, useEffect, useState } from 'react';
import { Container, Typography, Box, Card, CardContent, IconButton, Divider, Button } from "@mui/material";
import { NavLink, useNavigate } from "react-router-dom";
import AddressPopup from '../Popups/AddressPopup';
import { getUserFromLocalStorage } from '../../../Utils/utils';
import cardimg from '../../../style/images/moreoptioncard.png'
import { Padding, Widgets } from '@mui/icons-material';
import CreateTradingAccount from '../Popups/CreateTradingAccount';
import { UrlConstant } from '../../../commom/UrlConstant';
import Axios from '../../../config/axios';
import { Constant } from '../../../commom/Constants';

// SVG components remain the same
const CustomSVG1 = () => (
  <svg className='custom-svg ' width="64" height="64" viewBox="0 0 64 64" fill="none" xmlns="http://www.w3.org/2000/svg">
    <circle cx="32" cy="32" r="32" fill="white" fillOpacity="0.1" />
    <path d="M39.04 29.9337L44 24.9736L39.04 20.0137" stroke="#F2674A" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
    <path d="M20 24.9766H44" stroke="#F2674A" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
    <path d="M24.96 34.0645L20 39.0245L24.96 43.9845" stroke="#F2674A" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
    <path d="M44 39.0273H20" stroke="#F2674A" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
  </svg>
);
const CustomSVG2 = () => (
  <svg className='custom-svg ' width="64" height="64" viewBox="0 0 64 64" fill="none" xmlns="http://www.w3.org/2000/svg">
    <circle cx="32" cy="32" r="32" fill="white" fill-opacity="0.1" />
    <path d="M32.4933 18.8652L44.4933 23.6651C44.96 23.8518 45.3333 24.4118 45.3333 24.9051V29.3318C45.3333 30.0651 44.7333 30.6651 44 30.6651H20C19.2666 30.6651 18.6666 30.0651 18.6666 29.3318V24.9051C18.6666 24.4118 19.04 23.8518 19.5066 23.6651L31.5066 18.8652C31.7733 18.7585 32.2266 18.7585 32.4933 18.8652Z" stroke="#F2674A" stroke-width="2" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round" />
    <path d="M45.3333 45.3333H18.6667V41.3333C18.6667 40.6 19.2667 40 20 40H44C44.7333 40 45.3333 40.6 45.3333 41.3333V45.3333Z" stroke="#F2674A" stroke-width="2" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round" />
    <path d="M21.3334 39.9993V30.666" stroke="#F2674A" stroke-width="2" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round" />
    <path d="M26.6666 39.9993V30.666" stroke="#F2674A" stroke-width="2" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round" />
    <path d="M32 39.9993V30.666" stroke="#F2674A" stroke-width="2" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round" />
    <path d="M37.3334 39.9993V30.666" stroke="#F2674A" stroke-width="2" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round" />
    <path d="M42.6666 39.9993V30.666" stroke="#F2674A" stroke-width="2" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round" />
    <path d="M17.3334 45.334H46.6667" stroke="#F2674A" stroke-width="2" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round" />
    <path d="M32 27.334C33.1046 27.334 34 26.4386 34 25.334C34 24.2294 33.1046 23.334 32 23.334C30.8954 23.334 30 24.2294 30 25.334C30 26.4386 30.8954 27.334 32 27.334Z" stroke="#F2674A" stroke-width="2" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round" />
  </svg>

);

const CustomSVG3 = () => (
  <svg className='custom-svg ' width="64" height="64" viewBox="0 0 64 64" fill="none" xmlns="http://www.w3.org/2000/svg">
    <circle cx="32" cy="32" r="32" fill="white" fill-opacity="0.1" />
    <path d="M26.6667 32.2656H36" stroke="#F2674A" stroke-width="2" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round" />
    <path d="M26.6667 37.5996H32.5067" stroke="#F2674A" stroke-width="2" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round" />
    <path d="M29.3333 23.9993H34.6667C37.3333 23.9993 37.3333 22.666 37.3333 21.3327C37.3333 18.666 36 18.666 34.6667 18.666H29.3333C28 18.666 26.6667 18.666 26.6667 21.3327C26.6667 23.9993 28 23.9993 29.3333 23.9993Z" stroke="#F2674A" stroke-width="2" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round" />
    <path d="M37.3333 21.3594C41.7733 21.5994 44 23.2394 44 29.3327V37.3327C44 42.666 42.6667 45.3327 36 45.3327H28C21.3333 45.3327 20 42.666 20 37.3327V29.3327C20 23.2527 22.2267 21.5994 26.6667 21.3594" stroke="#F2674A" stroke-width="2" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round" />
  </svg>

);

const CustomSVG4 = () => (
  <svg className='custom-svg ' width="64" height="64" viewBox="0 0 64 64" fill="none" xmlns="http://www.w3.org/2000/svg">
    <circle cx="32" cy="32" r="32" fill="white" fill-opacity="0.1" />
    <path d="M27.2 24.666H36.8C41.3333 24.666 41.7867 26.786 42.0933 29.3727L43.2933 39.3727C43.68 42.6527 42.6666 45.3327 38 45.3327H26.0133C21.3333 45.3327 20.32 42.6527 20.72 39.3727L21.92 29.3727C22.2133 26.786 22.6666 24.666 27.2 24.666Z" stroke="#F2674A" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
    <path d="M26.6667 26.666V21.9993C26.6667 19.9993 28 18.666 30 18.666H34C36 18.666 37.3333 19.9993 37.3333 21.9993V26.666" stroke="#F2674A" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
    <path d="M43.2133 38.707H26.6667" stroke="#F2674A" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
  </svg>

);

const CustomSVG5 = () => (
  <svg className='custom-svg ' width="64" height="64" viewBox="0 0 64 64" fill="none" xmlns="http://www.w3.org/2000/svg">
    <circle cx="32" cy="32" r="32" fill="white" fill-opacity="0.1" />
    <path d="M41.3333 45.3327V30.666" stroke="#F2674A" stroke-width="2" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round" />
    <path d="M41.3333 25.3327V18.666" stroke="#F2674A" stroke-width="2" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round" />
    <path d="M32 45.3327V38.666" stroke="#F2674A" stroke-width="2" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round" />
    <path d="M32 33.3327V18.666" stroke="#F2674A" stroke-width="2" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round" />
    <path d="M22.6667 45.3327V30.666" stroke="#F2674A" stroke-width="2" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round" />
    <path d="M22.6667 25.3327V18.666" stroke="#F2674A" stroke-width="2" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round" />
    <path d="M20 30.666H25.3333" stroke="#F2674A" stroke-width="2" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round" />
    <path d="M38.6667 30.666H44" stroke="#F2674A" stroke-width="2" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round" />
    <path d="M29.3333 33.334H34.6667" stroke="#F2674A" stroke-width="2" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round" />
  </svg>
);

const CustomSVG6 = () => (
  <svg className='custom-svg ' width="64" height="64" viewBox="0 0 64 64" fill="none" xmlns="http://www.w3.org/2000/svg">
    <circle cx="32" cy="32" r="32" fill="white" fill-opacity="0.1" />
    <path d="M36.4 18.998C40.8 18.998 43 21.1394 43 25.4221V40.3692C43 44.5883 39.898 46.2208 36.092 44.0159L33.188 42.3198C32.528 41.9381 31.472 41.9381 30.812 42.3198L27.908 44.0159C24.102 46.2208 21 44.5883 21 40.3692V25.4221C21 21.1394 23.2 18.998 27.6 18.998H36.4Z" stroke="#F2674A" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
  </svg>

);

const CustomSVG7 = () => (
  <svg className='custom-svg ' width="64" height="64" viewBox="0 0 64 64" fill="none" xmlns="http://www.w3.org/2000/svg">
    <circle cx="32" cy="32" r="32" fill="white" fill-opacity="0.1" />
    <path d="M40 25.548C39.92 25.5346 39.8267 25.5346 39.7467 25.548C37.9067 25.4813 36.44 23.9746 36.44 22.108C36.44 20.2013 37.9734 18.668 39.88 18.668C41.7867 18.668 43.32 20.2146 43.32 22.108C43.3067 23.9746 41.84 25.4813 40 25.548Z" stroke="#F2674A" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
    <path d="M38.6267 35.2549C40.4533 35.5615 42.4667 35.2415 43.88 34.2948C45.76 33.0415 45.76 30.9882 43.88 29.7349C42.4533 28.7882 40.4133 28.4682 38.5867 28.7882" stroke="#F2674A" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
    <path d="M23.96 25.548C24.04 25.5346 24.1333 25.5346 24.2133 25.548C26.0533 25.4813 27.52 23.9746 27.52 22.108C27.52 20.2013 25.9867 18.668 24.08 18.668C22.1733 18.668 20.64 20.2146 20.64 22.108C20.6533 23.9746 22.12 25.4813 23.96 25.548Z" stroke="#F2674A" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
    <path d="M25.3333 35.2549C23.5067 35.5615 21.4933 35.2415 20.08 34.2948C18.2 33.0415 18.2 30.9882 20.08 29.7349C21.5067 28.7882 23.5467 28.4682 25.3733 28.7882" stroke="#F2674A" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
    <path d="M31.9999 35.5089C31.9199 35.4956 31.8266 35.4956 31.7466 35.5089C29.9066 35.4422 28.4399 33.9356 28.4399 32.0689C28.4399 30.1622 29.9733 28.6289 31.8799 28.6289C33.7866 28.6289 35.3199 30.1756 35.3199 32.0689C35.3066 33.9356 33.8399 35.4556 31.9999 35.5089Z" stroke="#F2674A" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
    <path d="M28.12 39.7104C26.24 40.9637 26.24 43.0171 28.12 44.2704C30.2533 45.6971 33.7466 45.6971 35.88 44.2704C37.76 43.0171 37.76 40.9637 35.88 39.7104C33.76 38.2971 30.2533 38.2971 28.12 39.7104Z" stroke="#F2674A" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
  </svg>
);

const CustomSVG8 = () => (
  <svg className='custom-svg ' width="64" height="64" viewBox="0 0 64 64" fill="none" xmlns="http://www.w3.org/2000/svg">
    <circle cx="32" cy="32" r="32" fill="white" fill-opacity="0.1" />
    <path d="M35.0156 37.3301H28.349" stroke="#F2674A" stroke-width="2" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round" />
    <path d="M32.88 19.3569L32.84 19.4502L28.9733 28.4235H25.1733C24.2666 28.4235 23.4 28.6102 22.6133 28.9435L24.9466 23.3702L25 23.2369L25.0933 23.0235C25.12 22.9435 25.1466 22.8635 25.1866 22.7969C26.9333 18.7569 28.9066 17.8369 32.88 19.3569Z" stroke="#F2674A" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
    <path d="M40.0667 28.6908C39.4667 28.5041 38.8267 28.4241 38.1867 28.4241H28.9734L32.84 19.4508L32.88 19.3574C33.08 19.4241 33.2667 19.5174 33.4667 19.5974L36.4134 20.8374C38.0534 21.5174 39.2 22.2241 39.8934 23.0774C40.0267 23.2374 40.1334 23.3841 40.2267 23.5574C40.3467 23.7441 40.44 23.9308 40.4934 24.1308C40.5467 24.2508 40.5867 24.3708 40.6134 24.4774C40.9734 25.5974 40.76 26.9708 40.0667 28.6908Z" stroke="#F2674A" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
    <path d="M44.6956 34.9305V37.5305C44.6956 37.7972 44.6823 38.0638 44.6689 38.3305C44.4156 42.9838 41.8156 45.3305 36.8823 45.3305H26.4823C26.1623 45.3305 25.8423 45.3038 25.5356 45.2638C21.2956 44.9838 19.0289 42.7172 18.7489 38.4772C18.7089 38.1705 18.6823 37.8505 18.6823 37.5305V34.9305C18.6823 32.2505 20.3089 29.9438 22.6289 28.9438C23.4289 28.6105 24.2823 28.4238 25.1889 28.4238H38.2023C38.8556 28.4238 39.4956 28.5172 40.0823 28.6905C42.7356 29.5038 44.6956 31.9838 44.6956 34.9305Z" stroke="#F2674A" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
    <path d="M24.9467 23.3691L22.6133 28.9425C20.2933 29.9425 18.6667 32.2491 18.6667 34.9291V31.0225C18.6667 27.2358 21.36 24.0758 24.9467 23.3691Z" stroke="#F2674A" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
    <path d="M44.6915 31.0242V34.9309C44.6915 31.9975 42.7448 29.5042 40.0781 28.7042C40.7715 26.9709 40.9715 25.6109 40.6381 24.4775C40.6115 24.3575 40.5715 24.2375 40.5181 24.1309C42.9981 25.4109 44.6915 28.0375 44.6915 31.0242Z" stroke="#F2674A" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
  </svg>

);

const CustomSVG9 = () => (
  <svg className='custom-svg ' width="64" height="64" viewBox="0 0 64 64" fill="none" xmlns="http://www.w3.org/2000/svg">
    <circle cx="32" cy="32" r="32" fill="white" fill-opacity="0.1" />
    <path d="M38.5333 38.534C42.1333 34.934 42.1333 28.934 38.5333 25.334" stroke="#F2674A" stroke-width="2" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round" />
    <path d="M25.4667 25.4668C21.8667 29.0668 21.8667 35.0668 25.4667 38.6668" stroke="#F2674A" stroke-width="2" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round" />
    <path d="M28.9333 34.1363C28.9333 35.3363 29.8667 36.2697 31.0667 36.2697H33.3333C34.2667 36.2697 35.2 35.4697 35.2 34.403C35.2 33.203 34.6667 32.803 34 32.5363L30.2667 31.203C29.3333 31.0697 28.9333 30.6697 28.9333 29.603C28.9333 28.5363 29.7333 27.7363 30.8 27.7363H33.2C34.4 27.7363 35.3333 28.6697 35.3333 29.8697" stroke="#F2674A" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
    <path d="M32 26.4023V37.6023" stroke="#F2674A" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
    <path d="M32 45.3327C39.3638 45.3327 45.3333 39.3631 45.3333 31.9993C45.3333 24.6356 39.3638 18.666 32 18.666C24.6362 18.666 18.6667 24.6356 18.6667 31.9993C18.6667 39.3631 24.6362 45.3327 32 45.3327Z" stroke="#F2674A" stroke-width="2" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round" />
  </svg>

);

const CustomSVG10 = () => (
  <svg className='custom-svg ' width="64" height="64" viewBox="0 0 64 64" fill="none" xmlns="http://www.w3.org/2000/svg">
    <circle cx="32" cy="32" r="32" fill="white" fill-opacity="0.1" />
    <path d="M38.2668 42.3073H25.7334C24.7468 42.3073 23.7468 41.6006 23.4134 40.6806L17.8934 25.2273C17.2134 23.2806 17.7068 22.3873 18.2401 21.9873C18.7734 21.5873 19.7734 21.3473 21.4534 22.5473L26.6534 26.2673C26.8134 26.3606 26.9601 26.4006 27.0668 26.374C27.1868 26.334 27.2801 26.2273 27.3468 26.0406L29.6934 19.7873C30.4001 17.9206 31.4401 17.6406 32.0001 17.6406C32.5601 17.6406 33.6001 17.9206 34.3068 19.7873L36.6534 26.0406C36.7201 26.214 36.8134 26.334 36.9334 26.374C37.0534 26.414 37.2001 26.374 37.3468 26.254L42.2268 22.774C44.0134 21.494 45.0534 21.7473 45.6268 22.1606C46.1868 22.5873 46.7068 23.534 45.9734 25.6006L40.5868 40.6806C40.2534 41.6006 39.2534 42.3073 38.2668 42.3073ZM19.5734 23.7473C19.6001 23.934 19.6534 24.2006 19.7868 24.5473L25.3068 40.0006C25.3601 40.134 25.6001 40.3073 25.7334 40.3073H38.2668C38.4134 40.3073 38.6534 40.134 38.6934 40.0006L44.0801 24.934C44.2668 24.4273 44.3201 24.0806 44.3334 23.8806C44.1334 23.9473 43.8268 24.094 43.3868 24.414L38.5068 27.894C37.8401 28.3606 37.0534 28.5073 36.3468 28.294C35.6401 28.0806 35.0668 27.5206 34.7734 26.7606L32.4268 20.5073C32.2534 20.0406 32.0934 19.814 32.0001 19.7073C31.9068 19.814 31.7468 20.0406 31.5734 20.494L29.2268 26.7473C28.9468 27.5073 28.3734 28.0673 27.6534 28.2806C26.9468 28.494 26.1468 28.3473 25.4934 27.8806L20.2934 24.1606C19.9868 23.9473 19.7468 23.814 19.5734 23.7473Z" fill="#F2674A" />
    <path d="M39.3333 46.334H24.6667C24.12 46.334 23.6667 45.8807 23.6667 45.334C23.6667 44.7873 24.12 44.334 24.6667 44.334H39.3333C39.88 44.334 40.3333 44.7873 40.3333 45.334C40.3333 45.8807 39.88 46.334 39.3333 46.334Z" fill="#F2674A" />
    <path d="M35.3333 35.666H28.6667C28.12 35.666 27.6667 35.2127 27.6667 34.666C27.6667 34.1193 28.12 33.666 28.6667 33.666H35.3333C35.88 33.666 36.3333 34.1193 36.3333 34.666C36.3333 35.2127 35.88 35.666 35.3333 35.666Z" fill="#F2674A" />
  </svg>

);

const CustomSVG11 = () => (
  <svg className='custom-svg ' width="64" height="64" viewBox="0 0 64 64" fill="none" xmlns="http://www.w3.org/2000/svg">
    <circle cx="32" cy="32" r="32" fill="white" fill-opacity="0.1" />
    <path d="M32 46.3327C24.0933 46.3327 17.6667 39.906 17.6667 31.9993C17.6667 24.0927 24.0933 17.666 32 17.666C39.9067 17.666 46.3333 24.0927 46.3333 31.9993C46.3333 39.906 39.9067 46.3327 32 46.3327ZM32 19.666C25.2 19.666 19.6667 25.1993 19.6667 31.9993C19.6667 38.7993 25.2 44.3327 32 44.3327C38.8 44.3327 44.3333 38.7993 44.3333 31.9993C44.3333 25.1993 38.8 19.666 32 19.666Z" fill="#F2674A" />
    <path d="M32 34.3327C31.4533 34.3327 31 33.8793 31 33.3327V26.666C31 26.1193 31.4533 25.666 32 25.666C32.5467 25.666 33 26.1193 33 26.666V33.3327C33 33.8793 32.5467 34.3327 32 34.3327Z" fill="#F2674A" />
    <path d="M32 38.6678C31.8267 38.6678 31.6533 38.6278 31.4933 38.5611C31.3333 38.4945 31.1867 38.4011 31.0533 38.2811C30.9333 38.1478 30.84 38.0145 30.7733 37.8411C30.7067 37.6811 30.6667 37.5078 30.6667 37.3345C30.6667 37.1611 30.7067 36.9878 30.7733 36.8278C30.84 36.6678 30.9333 36.5211 31.0533 36.3878C31.1867 36.2678 31.3333 36.1745 31.4933 36.1078C31.8133 35.9745 32.1867 35.9745 32.5067 36.1078C32.6667 36.1745 32.8133 36.2678 32.9467 36.3878C33.0667 36.5211 33.16 36.6678 33.2267 36.8278C33.2933 36.9878 33.3333 37.1611 33.3333 37.3345C33.3333 37.5078 33.2933 37.6811 33.2267 37.8411C33.16 38.0145 33.0667 38.1478 32.9467 38.2811C32.8133 38.4011 32.6667 38.4945 32.5067 38.5611C32.3467 38.6278 32.1733 38.6678 32 38.6678Z" fill="#F2674A" />
  </svg>
);

const CustomSVG12 = () => (
  <svg className='custom-svg ' width="64" height="64" viewBox="0 0 64 64" fill="none" xmlns="http://www.w3.org/2000/svg">
    <circle cx="32" cy="32" r="32" fill="white" fill-opacity="0.1" />
    <path d="M32 36C34.2091 36 36 34.2091 36 32C36 29.7909 34.2091 28 32 28C29.7909 28 28 29.7909 28 32C28 34.2091 29.7909 36 32 36Z" stroke="#F2674A" stroke-width="2" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round" />
    <path d="M18.6667 33.1726V30.8259C18.6667 29.4393 19.8 28.2926 21.2 28.2926C23.6133 28.2926 24.6 26.5859 23.3867 24.4926C22.6933 23.2926 23.1067 21.7326 24.32 21.0393L26.6267 19.7193C27.68 19.0926 29.04 19.4659 29.6667 20.5193L29.8133 20.7726C31.0133 22.8659 32.9867 22.8659 34.2 20.7726L34.3467 20.5193C34.9733 19.4659 36.3333 19.0926 37.3867 19.7193L39.6933 21.0393C40.9067 21.7326 41.32 23.2926 40.6267 24.4926C39.4133 26.5859 40.4 28.2926 42.8133 28.2926C44.2 28.2926 45.3467 29.4259 45.3467 30.8259V33.1726C45.3467 34.5593 44.2133 35.7059 42.8133 35.7059C40.4 35.7059 39.4133 37.4126 40.6267 39.5059C41.32 40.7193 40.9067 42.2659 39.6933 42.9593L37.3867 44.2793C36.3333 44.9059 34.9733 44.5326 34.3467 43.4793L34.2 43.2259C33 41.1326 31.0267 41.1326 29.8133 43.2259L29.6667 43.4793C29.04 44.5326 27.68 44.9059 26.6267 44.2793L24.32 42.9593C23.1067 42.2659 22.6933 40.7059 23.3867 39.5059C24.6 37.4126 23.6133 35.7059 21.2 35.7059C19.8 35.7059 18.6667 34.5593 18.6667 33.1726Z" stroke="#F2674A" stroke-width="2" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round" />
  </svg>

);

const CustomSVG13 = () => (
  <svg className='custom-svg ' width="64" height="64" viewBox="0 0 64 64" fill="none" xmlns="http://www.w3.org/2000/svg">
    <circle cx="32" cy="32" r="32" fill="white" fill-opacity="0.1" />
    <path d="M32 49.5C41.625 49.5 49.5 41.625 49.5 32C49.5 22.375 41.625 14.5 32 14.5C22.375 14.5 14.5 22.375 14.5 32C14.5 41.625 22.375 49.5 32 49.5Z" stroke="#F2674A" stroke-width="2" stroke-miterlimit="10" />
    <path d="M34.625 25C29.3225 25 25 29.34 25 34.625C25 37.0225 26.96 39 29.375 39C34.66 39 39 34.66 39 29.375C39 26.9775 37.0225 25 34.625 25Z" stroke="#F2674A" stroke-width="2" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round" />
  </svg>


);

const CustomSVG14 = () => (
  <svg className='custom-svg ' width="64" height="64" viewBox="0 0 64 64" fill="none" xmlns="http://www.w3.org/2000/svg">
    <circle cx="32" cy="32" r="32" fill="white" fill-opacity="0.1" />
    <path d="M24.125 24.4217V22.7242C24.125 18.7867 27.2925 14.9192 31.23 14.5517C35.92 14.0967 39.875 17.7892 39.875 22.3917V24.8067" stroke="#F2674A" stroke-width="2" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round" />
    <path d="M26.7499 49.5H37.2499C44.2849 49.5 45.5449 46.6825 45.9124 43.2525L47.2249 32.7525C47.6974 28.4825 46.4724 25 38.9999 25H24.9999C17.5274 25 16.3024 28.4825 16.7749 32.7525L18.0874 43.2525C18.4549 46.6825 19.7149 49.5 26.7499 49.5Z" stroke="#F2674A" stroke-width="2" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round" />
    <path d="M38.1171 32H38.1328" stroke="#F2674A" stroke-width="3.5" stroke-linecap="round" stroke-linejoin="round" />
    <path d="M25.8654 32H25.8811" stroke="#F2674A" stroke-width="3.5" stroke-linecap="round" stroke-linejoin="round" />
  </svg>



);
const MoreOptionsCards: React.FC = () => {
  const navigate = useNavigate();

  const [isPopupOpen, setIsPopupOpen] = useState(false);
  const [isTradingAcExist, setIsTradingAcExist] = useState(false);
  const [isDialogOpen, setDialogOpen] = useState(false);
  const [applicationStatus, setApplicationStatus] = useState("Create Now");
  const [isDocumentRequired, setDocumentRequired] = useState<boolean>(false);
  const [applicationId, setApplicationId] = useState("");

  const checkAccountStatus = async () => {
    try {
      const response = await Axios.get(
        UrlConstant.TradingAccount.CheckAccountStatus
      );
      const resData = response.data;
      console.log("AC Status : ", resData);
      if (resData.status === true) {
        setApplicationStatus(resData.data.status);
        setDocumentRequired(resData.data.isDocumentUploadRequired);
        setApplicationId(resData.data.applicationId);

      }
    } catch (error: any) {
      console.error("ERRR : ", error);
    }
    finally {
    }
  };

  const handleOpenPopup = () => {
    setIsPopupOpen(true);
  };

  const handleClosePopup = () => {
    setIsPopupOpen(false);
  };

  const createTradingAc = () => {
    setDialogOpen(true);
  };

  const user = getUserFromLocalStorage();

  useEffect(() => {
    if (user.tradingblock_status?.toLocaleUpperCase() === "COMPLETE" && user.tradingblock_application_id !== null) {
      setIsTradingAcExist(true);
    }

  }, [user])

  useEffect(() => {
    checkAccountStatus()
  }, [])

  return (
    <>
      <Container maxWidth={false} className="bg-color" style={{ padding: '30px', width: '100%', minHeight: 'calc(100vh - 71px - 143px)' }}>
        <Typography variant="body1" fontSize="24px" fontWeight={400} className="text-white">More Options</Typography>
        {/* <Box display="flex" my="20px" gap="10px" alignItems="center"> */}
        {/* <Box flexGrow={1}> */}
        {isTradingAcExist &&
          isDocumentRequired === false &&
          applicationStatus.toLocaleUpperCase() === "COMPLETE"
          ?
          <Card className="card" style={{ margin: '20px 0px', padding: '20px', width: '100%' }}>
            <Typography className="text-white" sx={{ marginBottom: '10px', fontSize: '18px' }}>Manage TradingBlock Brokerage Account</Typography>
            {/* <CardContent className="text-white" style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}> */}
            <Box display='flex' alignItems='center' justifyContent="space-between" height='117px' width='100%'>
              <NavLink to='/transfer' className='menuehover'>
                <Box display='flex' flexDirection='column' justifyContent="center" sx={{ width: '242px', mr: '17px' }} alignItems="center">
                  <IconButton ><CustomSVG1 /></IconButton>
                  <Typography variant="h5" className="text-white" align="center">Transfers</Typography>
                </Box>
              </NavLink>
              <Divider orientation="vertical" flexItem sx={{ bgcolor: 'gray !important', height: '117px' }} />
              <NavLink to='/linkbankaccount' className='menuehover'>
                <Box display='flex' flexDirection='column' justifyContent="center" sx={{ width: '230px', mx: '17px' }} alignItems="center">
                  <IconButton><CustomSVG2 /></IconButton>
                  <Typography variant="h5" className="text-white" align="center" sx={{ textWrap: 'nowrap' }}>Link Bank Account</Typography>
                </Box>
              </NavLink>
              <Divider orientation="vertical" flexItem sx={{ bgcolor: 'gray !important', height: '117px' }} />
              <NavLink to='/documents' className='menuehover'>
                <Box display='flex' flexDirection='column' justifyContent="center" sx={{ width: '242px', mx: '17px' }} alignItems="center">
                  <IconButton ><CustomSVG3 /></IconButton>
                  <Typography variant="h5" className="text-white" align="center" sx={{ textWrap: 'nowrap' }}>Brokerage Documents</Typography>
                </Box>
              </NavLink>
              <Divider orientation="vertical" flexItem sx={{ bgcolor: 'gray !important', height: '117px' }} />

              <NavLink to='/order' className='menuehover'>
                <Box display='flex' flexDirection='column' justifyContent="center" sx={{ width: '225px', mx: '17px' }} alignItems="center">
                  <IconButton ><CustomSVG4 /></IconButton>
                  <Typography variant="h5" className="text-white" align="center">Orders</Typography>
                </Box>
              </NavLink>
              <Divider orientation="vertical" flexItem sx={{ bgcolor: 'gray !important', height: '117px' }} />

              <Box component='button' className='menuehover' onClick={handleOpenPopup} bgcolor='transparent' border='none' display='flex' flexDirection='column' justifyContent="center" sx={{ width: '242px', ml: '17px' }} alignItems="center">
                <IconButton><CustomSVG5 /></IconButton>
                <Typography variant="h5" className="text-white" align="center">Manage</Typography>
              </Box>
            </Box >
            {/* </CardContent> */}
          </Card >
          : isDocumentRequired === false &&
          (applicationStatus === "" ||
            applicationStatus === Constant.CONTINUE) &&
          <Box mb='20px' sx={{ bgcolor: '#ffffff1a', borderRadius: '20px', width: '100%', padding: '30px 50px', display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
            <Box display='flex' gap='30px' width='fit-content' alignItems='center'>
              <img src={cardimg} height='142px' width='204px' alt="" />
              <Box>
                <Typography fontSize='24px' fontWeight='bold' className='text-white'>Don’t have Trading Account?</Typography>
                <Typography fontSize='18px' className='text-secondary'>Let’s Simplify your Trading Experience.</Typography>
              </Box>
            </Box>
            <Button
              onClick={() => {
                if (applicationStatus === Constant.CONTINUE) {
                  navigate("/createApplication");
                } else {
                  createTradingAc();
                }
              }}
              className='btn btn-primary'
              sx={{
                padding: '13px 50px',
                width: 'fit-content',
                height: 'fit-content'
              }}
            >
              {applicationStatus === ""
                ? "Create Account"
                : applicationStatus}

              <svg style={{ marginLeft: '10px' }} width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M18.04 13.55C17.62 13.96 17.38 14.55 17.44 15.18C17.53 16.26 18.52 17.05 19.6 17.05H21.5V18.24C21.5 20.31 19.81 22 17.74 22H7.63C7.94 21.74 8.21 21.42 8.42 21.06C8.79 20.46 9 19.75 9 19C9 16.79 7.21 15 5 15C4.06 15 3.19 15.33 2.5 15.88V11.51C2.5 9.44001 4.19 7.75 6.26 7.75H17.74C19.81 7.75 21.5 9.44001 21.5 11.51V12.95H19.48C18.92 12.95 18.41 13.17 18.04 13.55Z" stroke="white" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                <path d="M2.5 12.4093V7.83937C2.5 6.64937 3.23 5.58933 4.34 5.16933L12.28 2.16933C13.52 1.69933 14.85 2.61936 14.85 3.94936V7.74934" stroke="white" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                <path d="M22.5588 13.9692V16.0292C22.5588 16.5792 22.1188 17.0292 21.5588 17.0492H19.5988C18.5188 17.0492 17.5288 16.2592 17.4388 15.1792C17.3788 14.5492 17.6188 13.9592 18.0388 13.5492C18.4088 13.1692 18.9188 12.9492 19.4788 12.9492H21.5588C22.1188 12.9692 22.5588 13.4192 22.5588 13.9692Z" stroke="white" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                <path d="M7 12H14" stroke="white" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                <path d="M9 19C9 19.75 8.79 20.46 8.42 21.06C8.21 21.42 7.94 21.74 7.63 22C6.93 22.63 6.01 23 5 23C3.54 23 2.27 22.22 1.58 21.06C1.21 20.46 1 19.75 1 19C1 17.74 1.58 16.61 2.5 15.88C3.19 15.33 4.06 15 5 15C7.21 15 9 16.79 9 19Z" stroke="white" stroke-width="1.5" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round" />
                <path d="M6.49171 18.9805H3.51172" stroke="white" stroke-width="1.5" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round" />
                <path d="M5 17.5195V20.5095" stroke="white" stroke-width="1.5" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round" />
              </svg>
            </Button>
          </Box>

        }

        {/* Upload Document */}

        <Box
          className={
            isDocumentRequired === true && applicationStatus !== ""
              ? ""
              : "d-none"
          }
          mb='20px'
          sx={{
            bgcolor: '#ffffff1a',
            borderRadius: '20px',
            width: '100%',
            padding: '30px 50px',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between'
          }}

        >
          <Box display='flex' gap='30px' width='fit-content' alignItems='center'>
            <img src={cardimg} height='142px' width='204px' alt="" />
            <Box>
              <Typography fontSize='24px' fontWeight='bold' className='text-white'>Application is Under Review!</Typography>
              <Typography fontSize='18px' className='text-secondary'>Application ID : #{applicationId}</Typography>
            </Box>
          </Box>
          <Button
            onClick={() => {
              if (isDocumentRequired === true) {
                navigate("/documentupload");
              } else if (applicationStatus === Constant.CONTINUE) {
                navigate("/createApplication");
              } else {
                createTradingAc();
              }
            }}
            className='btn btn-primary'
            sx={{
              padding: '13px 50px',
              width: 'fit-content',
              height: 'fit-content'
            }}
          >
            {isDocumentRequired === true
              ? "Upload Document"
              : applicationStatus}

            <svg style={{ marginLeft: '10px' }} width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M18.04 13.55C17.62 13.96 17.38 14.55 17.44 15.18C17.53 16.26 18.52 17.05 19.6 17.05H21.5V18.24C21.5 20.31 19.81 22 17.74 22H7.63C7.94 21.74 8.21 21.42 8.42 21.06C8.79 20.46 9 19.75 9 19C9 16.79 7.21 15 5 15C4.06 15 3.19 15.33 2.5 15.88V11.51C2.5 9.44001 4.19 7.75 6.26 7.75H17.74C19.81 7.75 21.5 9.44001 21.5 11.51V12.95H19.48C18.92 12.95 18.41 13.17 18.04 13.55Z" stroke="white" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
              <path d="M2.5 12.4093V7.83937C2.5 6.64937 3.23 5.58933 4.34 5.16933L12.28 2.16933C13.52 1.69933 14.85 2.61936 14.85 3.94936V7.74934" stroke="white" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
              <path d="M22.5588 13.9692V16.0292C22.5588 16.5792 22.1188 17.0292 21.5588 17.0492H19.5988C18.5188 17.0492 17.5288 16.2592 17.4388 15.1792C17.3788 14.5492 17.6188 13.9592 18.0388 13.5492C18.4088 13.1692 18.9188 12.9492 19.4788 12.9492H21.5588C22.1188 12.9692 22.5588 13.4192 22.5588 13.9692Z" stroke="white" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
              <path d="M7 12H14" stroke="white" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
              <path d="M9 19C9 19.75 8.79 20.46 8.42 21.06C8.21 21.42 7.94 21.74 7.63 22C6.93 22.63 6.01 23 5 23C3.54 23 2.27 22.22 1.58 21.06C1.21 20.46 1 19.75 1 19C1 17.74 1.58 16.61 2.5 15.88C3.19 15.33 4.06 15 5 15C7.21 15 9 16.79 9 19Z" stroke="white" stroke-width="1.5" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round" />
              <path d="M6.49171 18.9805H3.51172" stroke="white" stroke-width="1.5" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round" />
              <path d="M5 17.5195V20.5095" stroke="white" stroke-width="1.5" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round" />
            </svg>
          </Button>
        </Box>

        {/* Document Submitted */}

        <Box
          className={
            isDocumentRequired === false &&
              applicationId &&
              applicationStatus.toLocaleUpperCase() !== "COMPLETE"
              ? ""
              : "d-none"
          }
          mb='20px'
          sx={{
            bgcolor: '#ffffff1a',
            borderRadius: '20px',
            width: '100%',
            padding: '30px 50px',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between'
          }}

        >
          <Box display='flex' gap='30px' width='fit-content' alignItems='center'>
            <img src={cardimg} height='142px' width='204px' alt="" />
            <Box>
              <Typography fontSize='24px' fontWeight='bold' className='text-white'>Document Submitted!</Typography>
              <Typography
                my="10px"
                className="text-secondary"
                fontSize="18px"
              >
                We're carefully reviewing your details. You’ll receive an
                update via email once the process is complete. Thank you for
                your patience!
              </Typography>
              <Typography fontSize='18px' className='text-secondary'>Application ID : #{applicationId}</Typography>
            </Box>
          </Box>

        </Box>
        {/* </Box> */}

        {/* </Box> */}
        <Box display="flex" gap="20px" flexWrap='wrap' width='100%'>
          <NavLink to='/wachlist' state={{ source: "moreOptions" }} className='menuehover menuehover2'>
            <Box display='flex' alignItems='center' justifyContent="center" height='190px' padding='20px' width='248px' borderRadius='20px' bgcolor='#FFFFFF1A' border='#FFFFFF2A'>
              <Box display='flex' mx='auto' flexDirection='column' justifyContent="space-between" alignItems="center">
                <IconButton ><CustomSVG6 /></IconButton>
                <Typography variant="h5" className="text-white" align="center">Watchlist</Typography>
              </Box>
            </Box>
          </NavLink >
          <NavLink to='/discovery' className='menuehover menuehover2'>
            <Box display='flex' alignItems='center' justifyContent="center" height='190px' width='248px' padding='20px' borderRadius='20px' bgcolor='#FFFFFF1A' border='#FFFFFF2A'>
              <Box display='flex' mx='auto' flexDirection='column' justifyContent="space-between" alignItems="center">
                <IconButton ><CustomSVG13 /></IconButton>
                <Typography variant="h5" className="text-white" align="center">Discovery</Typography>
              </Box>
            </Box>
          </NavLink>
          <NavLink to='/referfriend' state={{ source: "moreOptions" }} className='menuehover menuehover2'>
            <Box display='flex' alignItems='center' justifyContent="center" height='190px' padding='20px' width='248px' borderRadius='20px' bgcolor='#FFFFFF1A' border='#FFFFFF2A'>
              <Box display='flex' mx='auto' flexDirection='column' justifyContent="space-between" alignItems="center">
                <IconButton ><CustomSVG7 /></IconButton>
                <Typography variant="h5" className="text-white" align="center">Refer a Friend</Typography>
              </Box>
            </Box>
          </NavLink>
          {
            isTradingAcExist ?
              <>
                <NavLink to='/portfolio' className='menuehover menuehover2'>
                  <Box display='flex' alignItems='center' justifyContent="center" height='190px' padding='20px' width='248px' borderRadius='20px' bgcolor='#FFFFFF1A' border='#FFFFFF2A'>
                    <Box display='flex' mx='auto' flexDirection='column' justifyContent="space-between" alignItems="center">
                      <IconButton ><CustomSVG8 /></IconButton>
                      <Typography variant="h5" className="text-white" align="center">Portfolio</Typography>
                    </Box>
                  </Box>
                </NavLink >
                <NavLink to='/autotopup' className='menuehover menuehover2'>
                  <Box display='flex' alignItems='center' justifyContent="center" height='190px' padding='20px' width='248px' borderRadius='20px' bgcolor='#FFFFFF1A' border='#FFFFFF2A'>
                    <Box display='flex' mx='auto' flexDirection='column' justifyContent="space-between" alignItems="center">
                      <IconButton ><CustomSVG9 /></IconButton>
                      <Typography variant="h5" className="text-white" align="center">Auto Topup</Typography>
                    </Box>
                  </Box>
                </NavLink>
              </>
              : null
          }

          <NavLink to='/premiumSubscription' className='menuehover menuehover2' style={{ width: 'fit-content' }}>
            <Box display='flex' alignItems='center' justifyContent="center" height='190px' padding='20px' width='248px' borderRadius='20px' bgcolor='#FFFFFF1A' border='#FFFFFF2A'>
              <Box display='flex' mx='auto' flexDirection='column' justifyContent="space-between" alignItems="center">
                <IconButton ><CustomSVG10 /></IconButton>
                <Typography variant="h5" className="text-white" sx={{ textWrap: 'wrap' }} margin='0px auto' width='100%' align="center">Active Premium Subscription</Typography>
              </Box>
            </Box>
          </NavLink >
          <NavLink to='/about' className='menuehover menuehover2'>
            <Box display='flex' alignItems='center' justifyContent="center" height='190px' width='248px' padding='20px' borderRadius='20px' bgcolor='#FFFFFF1A' border='#FFFFFF2A'>
              <Box display='flex' mx='auto' flexDirection='column' justifyContent="space-between" alignItems="center">
                <IconButton ><CustomSVG11 /></IconButton>
                <Typography variant="h5" className="text-white" align="center">About</Typography>
              </Box>
            </Box>
          </NavLink >
          <NavLink to='/settings' className='menuehover menuehover2'>
            <Box display='flex' alignItems='center' justifyContent="center" height='190px' width='248px' padding='20px' borderRadius='20px' bgcolor='#FFFFFF1A' border='#FFFFFF2A'>
              <Box display='flex' mx='auto' flexDirection='column' justifyContent="space-between" alignItems="center">
                <IconButton ><CustomSVG12 /></IconButton>
                <Typography variant="h5" className="text-white" align="center">Setting</Typography>
              </Box>
            </Box>
          </NavLink>
          <NavLink to='https://shop.wolfpack.com/' target='_blank' className='menuehover menuehover2'>
            <Box display='flex' alignItems='center' justifyContent="center" height='190px' width='248px' padding='20px' borderRadius='20px' bgcolor='#FFFFFF1A' border='#FFFFFF2A'>
              <Box display='flex' mx='auto' flexDirection='column' justifyContent="space-between" alignItems="center">
                <IconButton ><CustomSVG14 /></IconButton>
                <Typography variant="h5" className="text-white" align="center">Shop</Typography>
              </Box>
            </Box>
          </NavLink >
        </Box>

      </Container>
      <AddressPopup open={isPopupOpen} onClose={handleClosePopup} />
      <CreateTradingAccount isDialogOpen={isDialogOpen} setDialogOpen={setDialogOpen} />
    </>
  );
};

export default MoreOptionsCards;
